import { faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import {
    faClock,
    faAngleDown,
    faAngleUp,
    faXmark,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Search, VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Button,
    Chip,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    useTheme,
} from "@vapor/react-material";
import dayjs from "dayjs";
import {
    ChangeEvent,
    ReactElement,
    useCallback,
    useMemo,
    useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";
import { ADE_URL } from "../../../../../config";
import { TaxSubmissionStatus } from "../../../../../core/dtos/TaxSubmissionStatus";
import { TaxSubmissionFilterSearch } from "../../../../../core/usecases/use-get-tax-submissions/useGetTaxSubmissions";
import { TaxSubmissionsTableHeaderFilter } from "../tax-submissions-table-header-filter/TaxSubmissionsTableHeaderFilter";
import { TaxSubmissionsTableHeaderKPIs } from "../tax-submissions-table-header-kpis/TaxSubmissionsTableHeaderKPIs";
import { ExpiringIdentitiesWarnings } from "../expiring-identities-warnings/ExpiringIdentitiesWarnings";

interface TaxSubmissionsTableHeaderProps {
    filters: TaxSubmissionFilterSearch;
    uploadDisabled: boolean;
    onUpload: (value: boolean) => void;
    onFilterChange: (filters: TaxSubmissionFilterSearch) => void;
    onClickExpiringChip: () => void;
}

export const TaxSubmissionsTableHeader = ({
    filters,
    uploadDisabled,
    onUpload,
    onFilterChange,
    onClickExpiringChip,
}: TaxSubmissionsTableHeaderProps): ReactElement => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();

    const [expiringFilter, setExpiringFilter] = useState(false);
    // states -> toggles
    const [openFilters, setOpenFilters] = useState(false);

    const isSomeFilterSet = useMemo(() => {
        return filters.intermediary ||
            filters.operator ||
            filters.taxPayer ||
            filters.statuses.length > 0 ||
            filters.type ||
            filters.fulfilmentName
            ? true
            : false;
    }, [
        filters.fulfilmentName,
        filters.intermediary,
        filters.operator,
        filters.taxPayer,
        filters.statuses.length,
        filters.type,
    ]);

    const debouncedOnFilterChange = useDebouncedCallback((cb: () => void) => {
        cb();
    }, 500);

    const handleOnChangeSelectedKPIs = useCallback(
        (selectedKPIs: TaxSubmissionStatus[]) => {
            onFilterChange({
                ...filters,
                statuses: selectedKPIs,
            });
        },
        [filters, onFilterChange],
    );

    const handleFulfilmentSearchChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            debouncedOnFilterChange(() => {
                onFilterChange({
                    ...filters,
                    fulfilmentName: event.target.value,
                });
            });
        },
        [debouncedOnFilterChange, filters, onFilterChange],
    );

    const handleResetFilters = useCallback(() => {
        onFilterChange({
            deadlineFrom: dayjs().startOf("month").startOf("day").toDate(),
            deadlineTo: dayjs().endOf("month").endOf("day").toDate(),
            statuses: [],
            fulfilmentName: undefined,
            intermediary: undefined,
            operator: undefined,
            taxPayer: undefined,
            type: undefined,
        });
    }, [onFilterChange]);

    const handleToggleFilters = useCallback(() => {
        setOpenFilters((previous) => !previous);
    }, []);

    return (
        <Stack spacing={2}>
            <ExpiringIdentitiesWarnings />
            <TaxSubmissionsTableHeaderKPIs
                accordionRight={
                    <Button
                        onClick={() => {
                            window.open(ADE_URL, "_blank", "noreferrer");
                        }}
                        startIcon={
                            <VaporIcon
                                color="primary"
                                icon={faUpRightFromSquare}
                            ></VaporIcon>
                        }
                    >
                        <Typography
                            color={theme.palette.primary.interactiveDefault}
                            variant="bodyLarge500"
                        >
                            {t(
                                "views.home.components.tax_submissions_table_header.filters.access_ade_url",
                                {
                                    defaultValue: "Area Entratel",
                                },
                            )}
                        </Typography>
                    </Button>
                }
                deadlineFrom={filters.deadlineFrom}
                deadlineTo={filters.deadlineTo}
                kpis={filters.statuses}
                onChangeSelectedKPIs={handleOnChangeSelectedKPIs}
            />
            <TaxSubmissionsTableHeaderFilter
                accordionLeft={
                    <Box>
                        <TextField
                            placeholder={t(
                                "views.home.components.tax_submissions_table_header.filters.fulfilment",
                                { defaultValue: "Cerca per adempimento" },
                            )}
                            sx={{ width: 350 }}
                            onChange={handleFulfilmentSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            sx={{
                                                pointerEvents: "none",
                                                background:
                                                    "linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%)",
                                            }}
                                        >
                                            <Search color="white" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Chip
                            label={t(
                                "views.home.components.tax_submissions_table_header.expiring_tax_submissions",
                                { defaultValue: "In Scadenza" },
                            )}
                            icon={
                                <VaporIcon
                                    icon={faClock}
                                    color={theme.palette.primary.cadet}
                                />
                            }
                            onClick={() => {
                                setExpiringFilter((prevValue) => !prevValue);
                                onClickExpiringChip();
                            }}
                            sx={{
                                marginTop: 0.5,
                                marginLeft: 2,
                            }}
                            variant={expiringFilter ? "outlined" : "filled"}
                            clickable
                        ></Chip>
                        <Button
                            startIcon={
                                <VaporIcon
                                    color="primary"
                                    icon={openFilters ? faAngleUp : faAngleDown}
                                />
                            }
                            onClick={handleToggleFilters}
                        >
                            <Typography
                                color={theme.palette.primary.richElectricBlue}
                                variant="bodyLarge500"
                            >
                                {t(
                                    "views.home.components.tax_submissions_table_header_filters.accordion.others_filters",
                                    {
                                        defaultValue: "Altri filtri",
                                    },
                                )}
                            </Typography>
                        </Button>
                        <Button
                            disabled={!isSomeFilterSet}
                            onClick={handleResetFilters}
                            startIcon={
                                <VaporIcon
                                    color={
                                        isSomeFilterSet ? "primary" : "disabled"
                                    }
                                    icon={faXmark}
                                />
                            }
                        >
                            <Typography
                                color={
                                    isSomeFilterSet
                                        ? theme.palette.primary.richElectricBlue
                                        : theme.palette.primary.textHintColor
                                }
                                variant="bodyLarge500"
                            >
                                {t(
                                    "views.home.components.tax_submissions_table_header.filters.reset",
                                    {
                                        defaultValue: "Resetta filtri",
                                    },
                                )}
                            </Typography>
                        </Button>
                    </Box>
                }
                accordionRight={
                    <Button
                        disabled={uploadDisabled}
                        onClick={() => onUpload(true)}
                        startIcon={<VaporIcon icon={faPlus} color="primary" />}
                    >
                        <Typography
                            color={theme.palette.primary.richElectricBlue}
                            variant="bodyLarge500"
                        >
                            {t(
                                "views.home.components.tax_submissions_table_header.accordion.upload_taxSubmission",
                                {
                                    defaultValue: "Carica fornitura telematica",
                                },
                            )}
                        </Typography>
                    </Button>
                }
                filters={filters}
                onFilterChange={onFilterChange}
                openFilters={openFilters}
            />
        </Stack>
    );
};
