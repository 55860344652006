import {
    faDownload,
    faEye,
    faMagnifyingGlassArrowRight,
} from "@fortawesome/pro-light-svg-icons";
import { Theme } from "@mui/system";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
    GridRowParams,
} from "@mui/x-data-grid";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import { useTheme } from "@vapor/react-material";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { JSX } from "react/jsx-runtime";
import { useTaxSubmissionContext } from "../../../../../core/contexts/TaxSubmissionContext";
import {
    OutcomeTypePrefix,
    TaxPayer,
} from "../../../../../core/dtos/FulfilmentDTO";
import { FulfilmentStatus } from "../../../../../core/dtos/FulfilmentStatus";
import {
    GovernmentAgencyType,
    TaxSubmissionDetailDTO,
} from "../../../../../core/dtos/TaxSubmissionDetailDTO";
import { useDownloadTaxSubmissionOutcome } from "../../../../../core/usecases/use-download-tax-submission-outcome/useDownloadTaxSubmissionOutcome";
import { useDownloadTaxSubmissionPdfOutcome } from "../../../../../core/usecases/use-download-tax-submission-pdf-outcome/useDownloadTaxSubmissionPdfOutcome";
import { downloadFile } from "../../../../../core/utils/downloadFile";
import { FulfilmentStatusTypography } from "../../../../components/fulfilment-status-typography/FulfilmentStatusTypography";
import { getTranslation } from "../../../../utils/outcomesTypes";
import { createNavigationUrl } from "../../../../utils/resolveUrn";
import { EllipsisVertical } from "@vapor/react-icons";

export interface RowModel {
    id: string;
    taxSubmissionId: string;
    taxpayer: TaxPayer;
    taxPayerPath?: string;
    protocol?: string;
    status: FulfilmentStatus;
    amount?: string;
    outcomeTypes?: string[];
    revoked?: boolean | undefined;
}

interface TaxSubmissionDetailTaxPayersTableProps {
    taxSubmissionDetail: TaxSubmissionDetailDTO;
    loading: boolean;
    onClickPreviewOutcome: (
        availablesOutcomeTypes: string[],
        governmentAgencyType: GovernmentAgencyType,
        taxSubmissionId: string,
        selectedOutcomeType: OutcomeTypePrefix,
        taxPayer?: string,
    ) => unknown;
    onRowSelectionModelChange: (fulfilmentIds: string[]) => unknown;
    revokeTaxSubmissionsEnabled: boolean;
}

export const TaxSubmissionDetailTaxPayersTable: React.FC<
    TaxSubmissionDetailTaxPayersTableProps
> = ({
    taxSubmissionDetail,
    loading,
    onRowSelectionModelChange,
    onClickPreviewOutcome,
    revokeTaxSubmissionsEnabled,
}): JSX.Element => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();
    const { isUrnValid } = useTaxSubmissionContext();
    const navigate = useNavigate();

    // states -> rows
    const rows = useMemo<RowModel[]>(
        () =>
            taxSubmissionDetail
                ? taxSubmissionDetail.fulfilments.map((fulfilment) => {
                      return {
                          id: fulfilment.id,
                          taxSubmissionId: taxSubmissionDetail.id,
                          taxpayer: fulfilment.taxpayer,
                          taxPayerPath: fulfilment.taxPayerPath,
                          protocol: fulfilment.protocol,
                          status: fulfilment.status,
                          amount: fulfilment.amount,
                          outcomeTypes: fulfilment.outcomeTypes,
                          revoked: fulfilment.revoked,
                      };
                  })
                : [],
        [taxSubmissionDetail],
    );

    // states -> use cases
    const { download: downloadPdfOutcome } =
        useDownloadTaxSubmissionPdfOutcome();
    const { download: downloadOutcome } = useDownloadTaxSubmissionOutcome();

    // functions
    const onClickDownloadPdfOutcome = useCallback(
        async (
            event: React.MouseEvent<HTMLElement>,
            taxSubmissionId: string,
            outcomeType: string,
            taxPayer?: string,
        ) => {
            let resourceData;

            if (
                taxSubmissionDetail.governmentAgency.type ===
                GovernmentAgencyType.ade
            )
                resourceData = await downloadPdfOutcome(
                    taxSubmissionId,
                    [outcomeType],
                    taxPayer,
                );
            else
                resourceData = await downloadOutcome(
                    taxSubmissionId,
                    outcomeType,
                    taxPayer,
                );

            if (!resourceData) {
                console.error("could not download outcome document");
                return;
            }
            const filename: string = outcomeType + "_" + taxPayer;
            downloadFile(resourceData.content, filename, resourceData.type);
        },
        [
            downloadOutcome,
            downloadPdfOutcome,
            taxSubmissionDetail.governmentAgency.type,
        ],
    );

    const getDownloadActions = useCallback(
        (
            outcomeTypes: string[],
            taxSubmissionId: string,
            taxpayer: string,
        ): JSX.Element[] => {
            return outcomeTypes.map((outcomeType) => {
                const onClick: React.MouseEventHandler<HTMLButtonElement> = (
                    event,
                ) =>
                    onClickDownloadPdfOutcome(
                        event,
                        taxSubmissionId,
                        outcomeType,
                        taxpayer,
                    );

                return (
                    <GridActionsCellItem
                        icon={
                            <VaporIcon icon={faDownload} color="interactive" />
                        }
                        label={getTranslation(outcomeType, "download", t)}
                        style={{
                            color: theme.palette.primary.interactiveDefault,
                        }}
                        onClick={onClick as React.MouseEventHandler}
                        showInMenu
                    />
                );
            });
        },
        [
            onClickDownloadPdfOutcome,
            t,
            theme.palette.primary.interactiveDefault,
        ],
    );

    const getGoToProcedureButton = (
        ownerAppUrn: string,
        path: string,
    ): JSX.Element[] => {
        const label: string = t(
            "views.home.components.tax_submissions_table.actions.go_to_procedure",
            {
                defaultValue: "Vai alla procedura",
            },
        );
        const onClick = (_: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            navigate(createNavigationUrl(path));
        };
        return [
            <GridActionsCellItem
                icon={
                    <VaporIcon
                        icon={faMagnifyingGlassArrowRight}
                        color="interactive"
                    />
                }
                label={label}
                style={{
                    color: theme.palette.primary.interactiveDefault,
                }}
                onClick={onClick}
                showInMenu
            />,
        ];
    };

    const getPreviewActions = useCallback(
        (
            outcomeTypes: string[],
            taxSubmissionId: string,
            taxpayer: string,
        ): JSX.Element[] => {
            return outcomeTypes.map((outcomeType) => (
                <GridActionsCellItem
                    icon={<VaporIcon icon={faEye} color="interactive" />}
                    label={getTranslation(outcomeType, "preview", t)}
                    style={{
                        color: theme.palette.primary.interactiveDefault,
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        onClickPreviewOutcome(
                            outcomeTypes,
                            taxSubmissionDetail.governmentAgency.type,
                            taxSubmissionId,
                            outcomeType as OutcomeTypePrefix,
                            taxpayer,
                        );
                    }}
                    showInMenu
                />
            ));
        },
        [
            taxSubmissionDetail.governmentAgency.type,
            onClickPreviewOutcome,
            t,
            theme.palette.primary.interactiveDefault,
        ],
    );

    const renderHeader = useCallback(
        (theme: Theme, params: GridColumnHeaderParams) => (
            <Typography
                color={theme.palette.primary.textTitleColor}
                variant="body500"
                noWrap
            >
                {params.colDef.headerName}
            </Typography>
        ),
        [],
    );

    const renderCell = useCallback(
        (_: Theme, params: GridRenderCellParams) => (
            <Typography variant="body" noWrap>
                {params.value}
            </Typography>
        ),
        [],
    );

    const renderAmountCell = useCallback(
        (_: Theme, params: GridRenderCellParams) => (
            <Typography variant="body" noWrap>
                € {params.value}
            </Typography>
        ),
        [],
    );

    const renderStatus = useCallback(
        (_: Theme, params: GridRenderCellParams<RowModel>) => {
            return (
                <FulfilmentStatusTypography
                    status={params.value}
                    revoked={params.row.revoked}
                />
            );
        },
        [],
    );

    const getColumns = useCallback(
        (
            theme: Theme,
            t: (key: string, options?: any) => string,
        ): GridColDef[] => {
            return [
                {
                    field: "taxpayer",
                    flex: 1,
                    headerName: t(
                        "views.home.components.tax_submission_detail_tax_payers_table.header.taxpayer",
                        { defaultValue: "Contribuente" },
                    ),
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params: GridRenderCellParams<RowModel>) => (
                        <Typography variant="body" noWrap>
                            {params.row.taxpayer.name}
                        </Typography>
                    ),
                },
                {
                    field: "protocol",
                    flex: 0.4,
                    headerName: t(
                        "views.home.components.tax_submission_detail_tax_payers_table.header.protocol",
                        { defaultValue: "Protocollo singolo" },
                    ),
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) => renderCell(theme, params),
                },
                {
                    field: "status",
                    flex: 0.4,
                    headerName: t(
                        "views.home.components.tax_submission_detail_tax_payers_table.header.status",
                        { defaultValue: "Stato" },
                    ),
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) => renderStatus(theme, params),
                },
                {
                    field: "amount",
                    flex: 0.3,
                    headerName: t(
                        "views.home.components.tax_submission_detail_tax_payers_table.header.amount",
                        { defaultValue: "Saldo" },
                    ),
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) => renderAmountCell(theme, params),
                },
                {
                    field: "menu",
                    type: "actions",
                    flex: 0.1,
                    getActions: (params: GridRowParams<RowModel>) => {
                        let actions: JSX.Element[] = [];
                        const fulfilment = params.row;
                        if (
                            !!fulfilment &&
                            !!fulfilment.outcomeTypes &&
                            !!fulfilment.taxpayer
                        ) {
                            actions = [
                                ...getPreviewActions(
                                    fulfilment.outcomeTypes,
                                    fulfilment.taxSubmissionId,
                                    fulfilment.taxpayer.taxId,
                                ),
                                ...getDownloadActions(
                                    fulfilment.outcomeTypes,
                                    fulfilment.taxSubmissionId,
                                    fulfilment.taxpayer.taxId,
                                ),
                                ...(isUrnValid(
                                    taxSubmissionDetail.ownerApp.urn,
                                ) && !!fulfilment.taxPayerPath
                                    ? getGoToProcedureButton(
                                          taxSubmissionDetail.ownerApp.urn,
                                          fulfilment.taxPayerPath,
                                      )
                                    : []),
                            ];
                        }

                        return actions;
                    },
                },
            ];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            getDownloadActions,
            getPreviewActions,
            renderAmountCell,
            renderCell,
            renderHeader,
            renderStatus,
        ],
    );

    return (
        <>
            <DataGrid
                autoHeight
                checkboxSelection={revokeTaxSubmissionsEnabled}
                columns={getColumns(theme, t)}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                hideFooter
                hideFooterPagination
                isRowSelectable={(params: GridRowParams<RowModel>) =>
                    params.row.status === FulfilmentStatus.Done
                }
                onRowSelectionModelChange={(fulfilmentIds) => {
                    onRowSelectionModelChange(fulfilmentIds as string[]);
                }}
                loading={loading}
                rows={rows}
                sx={{ border: "none", width: `calc(100% - 10px)` }} // width is a workaround to use the fluid column width of mui-x-datagrid
                slots={{
                    moreActionsIcon: (props) => (
                        <EllipsisVertical {...props} color="interactive" />
                    ),
                }}
            />
        </>
    );
};
