import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Button,
    Card,
    Divider,
    Stack,
    styled,
    useTheme,
} from "@vapor/react-material";
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { ReactElement, ReactNode, useCallback, useState } from "react";
import { TaxSubmissionDetailDTO } from "../../../../../core/dtos/TaxSubmissionDetailDTO";
import { DelegatedUpdater } from "../../../../components/delegated-updater/DelegatedUpdater";
import { TaxSubmissionStatusChip } from "../../../../components/tax-submission-status-chip/TaxSubmissionStatusChip";
import { VaporIcon } from "@vapor/react-icons";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import { TaxSubmissionAction } from "./tax-submission-action/TaxSubmissionAction";
import { EditAgencyIdModal } from "./edit-agency-id-modal/EditAgencyIdModal";
import { useUpdateTaxSubmissionAgencyId } from "../../../../../core/usecases/use-update-tax-submission-agency-id/useUpdateTaxSubmissionAgencyId";

export const DetailRow: React.FC<{
    divider?: boolean;
    label: string;
    value: ReactNode | string;
}> = ({ divider = true, label, value }): ReactElement => {
    const theme = useTheme();
    return (
        <>
            <Stack justifyContent="space-between" direction="row" padding={0.5}>
                <Box>
                    <Typography
                        color={theme.palette.primary.textTitleColor}
                        variant="body500"
                    >
                        {label}
                    </Typography>
                </Box>
                <Box>
                    {typeof value === "string" ? (
                        <Typography variant="body">{value}</Typography>
                    ) : (
                        value
                    )}
                </Box>
            </Stack>
            {divider && <Divider light />}
        </>
    );
};
const DetailCard = styled(Card)(({ theme }) => ({
    alignItems: "center",
    backgroundColor: `${theme.palette.primary.background} !important`,
    textAlign: "center",
}));

interface TaxSubmissionDetailSummaryProps {
    fetchTaxSubmissions: () => unknown;
    taxSubmissionDetail: TaxSubmissionDetailDTO;
}
export const TaxSubmissionDetailSummary: React.FC<
    TaxSubmissionDetailSummaryProps
> = ({ fetchTaxSubmissions, taxSubmissionDetail }): ReactElement => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();

    const { loading: loadingUpdateAgencyId, updateAgencyId } =
        useUpdateTaxSubmissionAgencyId();

    const [actionsOpen, setActionsOpen] = useState(false);
    const [editAgencyIdOpen, setEditAgencyIdOpen] = useState(false);

    const handleToggleActions = useCallback(() => {
        setActionsOpen((prevState) => !prevState);
    }, []);

    const handleEditAgencyIdModalOpen = () => {
        setEditAgencyIdOpen(true);
    };

    const handleEditAgencyIdModalClose = () => {
        setEditAgencyIdOpen(false);
    };

    const handleOnUpdateAgencyIdClick = async (
        taxSubmissionId: string,
        agencyId: string,
    ) => {
        const response = await updateAgencyId(taxSubmissionId, agencyId);
        fetchTaxSubmissions();
        return response;
    };

    return (
        <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
                <Stack spacing={2}>
                    <DetailCard
                        elevation={0}
                        sx={{ height: "100%", padding: 4 }}
                    >
                        <Stack>
                            <Typography
                                color={theme.palette.primary.textTitleColor}
                                variant="body500"
                            >
                                {t(
                                    "views.home.components.tax_submission_detail_summary.tax_submission_status",
                                    { defaultValue: "Stato fornitura" },
                                )}
                            </Typography>
                            <Box>
                                <TaxSubmissionStatusChip
                                    status={taxSubmissionDetail.status}
                                />
                            </Box>
                        </Stack>
                    </DetailCard>
                    <DetailCard
                        elevation={0}
                        sx={{ height: "100%", padding: 4 }}
                    >
                        <Stack>
                            <Typography
                                color={theme.palette.primary.textTitleColor}
                                variant="bodyLarge700"
                            >
                                {taxSubmissionDetail.fulfilmentsErrors}
                            </Typography>
                            <Typography
                                color={theme.palette.primary.textTitleColor}
                                variant="body500"
                            >
                                {t(
                                    "views.home.components.tax_submission_detail_summary.warnings",
                                    { defaultValue: "Avvertimento" },
                                )}
                            </Typography>
                        </Stack>
                    </DetailCard>
                </Stack>
                <Stack flexGrow={4}>
                    <DetailCard elevation={0} sx={{ padding: 2 }}>
                        <DetailRow
                            label={t(
                                "views.home.components.tax_submission_detail_summary.upload_date_label",
                                { defaultValue: "Data e ora caricamento" },
                            )}
                            value={dayjs(
                                taxSubmissionDetail.creationDate,
                            ).format("DD/MM/YYYY - HH:mm")}
                        />
                        <DetailRow
                            label={t(
                                "views.home.components.tax_submission_detail_summary.send_date_label",
                                { defaultValue: "Data e ora invio" },
                            )}
                            value={dayjs(
                                taxSubmissionDetail.governmentAgency.sendDate,
                            ).format("DD/MM/YYYY - HH:mm")}
                        />
                        <DetailRow
                            label={t(
                                "views.home.components.tax_submission_detail_summary.government_agency.id_label",
                                { defaultValue: "N. protocollo generale:" },
                            )}
                            value={
                                <Stack
                                    flexDirection="row"
                                    gap={2}
                                    alignItems="flex-start"
                                >
                                    {taxSubmissionDetail.governmentAgency.id
                                        ? `${taxSubmissionDetail.governmentAgency.id}`
                                        : t(
                                              "views.home.components.tax_submission_detail_summary.government_agency.id.no_data",
                                              {
                                                  defaultValue:
                                                      "Informazione non disponibile",
                                              },
                                          )}
                                    {taxSubmissionDetail.isAgencyIdEditable && (
                                        <>
                                            <EditAgencyIdModal
                                                taxSubmissionId={
                                                    taxSubmissionDetail.id
                                                }
                                                open={editAgencyIdOpen}
                                                onClose={
                                                    handleEditAgencyIdModalClose
                                                }
                                                loading={loadingUpdateAgencyId}
                                                onEdit={
                                                    handleOnUpdateAgencyIdClick
                                                }
                                            />
                                            <FontAwesomeIcon
                                                icon={faPenToSquare}
                                                size="lg"
                                                color={
                                                    theme.palette.primary
                                                        .interactiveDefault
                                                }
                                                style={{ cursor: "pointer" }}
                                                onClick={
                                                    handleEditAgencyIdModalOpen
                                                }
                                            />
                                        </>
                                    )}
                                </Stack>
                            }
                        />
                        <DetailRow
                            label={t(
                                "views.home.components.tax_submission_detail_summary.operator_name_label",
                                { defaultValue: "Operatore:" },
                            )}
                            value={
                                taxSubmissionDetail.operatorFamilyName ||
                                taxSubmissionDetail.operatorGivenName
                                    ? `${taxSubmissionDetail.operatorGivenName} ${taxSubmissionDetail.operatorFamilyName}`
                                    : t(
                                          "views.home.components.tax_submission_detail_summary.operator_name.no_data",
                                          {
                                              defaultValue:
                                                  "Informazione non disponibile",
                                          },
                                      )
                            }
                        />
                        <DetailRow
                            label={t(
                                "views.home.components.tax_submission_detail_summary.intermediary_tax_id",
                                { defaultValue: "Intermediario:" },
                            )}
                            value={taxSubmissionDetail.intermediary.taxId}
                        />
                        <DetailRow
                            label={t(
                                "views.home.components.tax_submission_detail_summary.intermediary_site_code",
                                { defaultValue: "Sede:" },
                            )}
                            value={taxSubmissionDetail.intermediary.siteCode}
                        />
                        <DetailRow
                            divider={false}
                            label={t(
                                "views.home.components.tax_submission_detail_summary.delegated",
                                { defaultValue: "Incaricato:" },
                            )}
                            value={
                                taxSubmissionDetail.isDelegatedEditable ? (
                                    <DelegatedUpdater
                                        taxSubmissionId={taxSubmissionDetail.id}
                                        intermediaryTaxId={
                                            taxSubmissionDetail.intermediary
                                                .taxId
                                        }
                                        delegatedTaxId={
                                            taxSubmissionDetail.delegatedTaxId
                                        }
                                        onDelegatedUpdate={fetchTaxSubmissions}
                                    />
                                ) : (
                                    taxSubmissionDetail.delegatedTaxId ||
                                    t(
                                        "views.home.components.tax_submission_detail_summary.delegated.no_data",
                                        {
                                            defaultValue:
                                                "Incaricato non presente",
                                        },
                                    )
                                )
                            }
                        />
                    </DetailCard>
                </Stack>
            </Stack>
            {taxSubmissionDetail.actions &&
                taxSubmissionDetail.actions.length > 0 && (
                    <Stack gap={2}>
                        <Stack direction="row">
                            <Button
                                startIcon={
                                    <VaporIcon
                                        color="info"
                                        icon={
                                            actionsOpen
                                                ? faAngleUp
                                                : faAngleDown
                                        }
                                    />
                                }
                                onClick={handleToggleActions}
                            >
                                <Typography
                                    color={theme.palette.primary.textTitleColor}
                                    variant="bodyLarge"
                                >
                                    {t(
                                        "views.home.components.tax_submission_detail_summary.actions_accordion",
                                        {
                                            defaultValue:
                                                "Cronologia delle modifiche",
                                        },
                                    )}
                                </Typography>
                            </Button>
                        </Stack>
                        {actionsOpen && (
                            <Stack gap={1} sx={{ px: 6 }}>
                                <Typography variant="bodySmall">
                                    {t(
                                        "views.home.components.tax_submission_detail_summary.subtitle",
                                        {
                                            defaultValue:
                                                "Modifiche precedenti",
                                        },
                                    )}
                                </Typography>
                                {taxSubmissionDetail.actions.map(
                                    (action, index) => (
                                        <TaxSubmissionAction
                                            key={index}
                                            action={action}
                                        />
                                    ),
                                )}
                            </Stack>
                        )}
                    </Stack>
                )}
        </Stack>
    );
};
