import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDTO } from "../../dtos/BaseUseCaseResponseDTO";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";
import { ExpiringIdentitiesDTO } from "../../dtos/ExpiringIdentitiesDTO";

export function useGetExpiringIdentities(): BaseUseCaseResponseDTO<ExpiringIdentitiesDTO> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } =
        useGetWithErrorHandler<ExpiringIdentitiesDTO>(
            `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/expiring-identities`,
            {
                ...useGetAxiosRequestConfigParams(
                    "use-get-expiring-identities",
                ),
            },
        );

    return {
        data: data,
        error: error,
        fetch: fetch,
        loading: loading,
    };
}
