import { FulfilmentStatus } from "./FulfilmentStatus";

export interface TaxPayer {
    name: string;
    taxId: string;
}

export interface FulfilmentDTO {
    id: string;
    taxpayer: TaxPayer;
    taxPayerPath?: string;
    protocol?: string;
    status: FulfilmentStatus;
    amount?: string;
    outcomeTypes?: string[];
    revoked?: boolean | undefined;
}

export enum OutcomeTypePrefix {
    F24 = "F24",
    I24 = "I24",
    G24 = "G24",
    IVP = "IVP",
    CIR = "CIR",
    CLR = "CLR",
    CMR = "CMR",
}
