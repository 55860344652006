import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    TextField,
    useTheme,
} from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTranslation } from "@onefront/react-sdk";
import { ChangeEvent, useState } from "react";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { useSuccessNotification } from "../../../../../../core/hooks/useSuccessNotification";
import { useErrorNotification } from "../../../../../../core/hooks/useErrorNotification";

interface EditAgencyIdModalProps {
    taxSubmissionId?: string;
    loading: boolean;
    open: boolean;
    onClose: () => void;
    onEdit: (taxSubmissionId: string, agencyId: string) => Promise<boolean>;
}

export const EditAgencyIdModal: React.FC<EditAgencyIdModalProps> = ({
    taxSubmissionId,
    loading,
    open,
    onClose,
    onEdit,
}) => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();
    const { showTextMessage: showSuccessNotification } =
        useSuccessNotification();
    const { showTextMessage: showErrorNotification } = useErrorNotification();

    const [agencyId, setAgencyId] = useState("");
    const [agencyIdError, setAgencyIdError] = useState("");

    const handleOnClose = () => {
        onClose();
        setAgencyId("");
        setAgencyIdError("");
    };

    const handleOnUpdate = async () => {
        if (!taxSubmissionId) return;

        const success = await onEdit(taxSubmissionId, agencyId);
        if (success) {
            showSuccessNotification(
                t(
                    "views.home.components.tax_submission_detail_summary.edit_government_agency_id_modal.success",
                    {
                        defaultValue: "Richiesta presa in carico correttamente",
                    },
                ),
            );
            handleOnClose();
        } else {
            showErrorNotification(
                t(
                    "views.home.components.tax_submission_detail_summary.edit_government_agency_id_modal.error",
                    {
                        defaultValue: "Errore nella richiesta",
                    },
                ),
            );
        }
    };

    const handleAgencyIdOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setAgencyId(value);
        setAgencyIdError(
            !value.match(/^\d{17}$/)
                ? t(
                      "views.home.components.tax_submission_detail_summary.edit_government_agency_id_modal.input_error",
                      {
                          defaultValue:
                              "Il protocollo generale deve essere una stringa numerica di 17 caratteri",
                      },
                  )
                : "",
        );
    };

    return (
        <Dialog open={open} onClose={handleOnClose}>
            <DialogTitle>
                <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        width: "100%",
                    }}
                >
                    <Typography
                        color={theme.palette.primary.textTitleColor}
                        variant="bodyLarge"
                    >
                        {t(
                            "views.home.components.tax_submission_detail_summary.edit_government_agency_id_modal.title",
                            { defaultValue: "Protocollo generale" },
                        )}
                    </Typography>
                    <FontAwesomeIcon
                        icon={faXmark}
                        onClick={handleOnClose}
                        style={{ cursor: "pointer" }}
                    />
                </Stack>
            </DialogTitle>
            <Divider variant="fullWidth" />
            <DialogContent>
                <Stack
                    justifyContent="space-between"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography fontWeight={700}>
                        {t(
                            "views.home.components.tax_submission_detail_summary.edit_government_agency_id_modal.subtitle",
                            {
                                defaultValue: "Attenzione:",
                            },
                        )}
                    </Typography>
                    <Typography>
                        {t(
                            "views.home.components.tax_submission_detail_summary.edit_government_agency_id_modal.description",
                            {
                                defaultValue:
                                    "Inserendo manualmente il numero di protocollo generale, partirá la procedura di scaricamento degli esiti. Assicurati che sia lo stesso di quello presente nell'area riservata dell'agenzia delle entrate.",
                            },
                        )}
                    </Typography>
                    <TextField
                        sx={{ width: 300 }}
                        label={t(
                            "views.home.components.tax_submission_detail_summary.edit_government_agency_id_modal.input_label",
                            {
                                defaultValue: "N. protocollo generale",
                            },
                        )}
                        error={!!agencyIdError}
                        helperText={agencyIdError}
                        value={agencyId}
                        onChange={handleAgencyIdOnChange}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleOnClose}>
                    {t(
                        "views.home.components.tax_submission_detail_summary.edit_government_agency_id_modal.back",
                        { defaultValue: "Annulla" },
                    )}
                </Button>
                <Button
                    variant="contained"
                    disabled={
                        !agencyId ||
                        !!agencyIdError ||
                        loading ||
                        !taxSubmissionId
                    }
                    onClick={handleOnUpdate}
                >
                    {t(
                        "views.home.components.tax_submission_detail_summary.edit_government_agency_id_modal.confirm",
                        { defaultValue: "Conferma" },
                    )}
                    {loading && (
                        <CircularProgress
                            color="inherit"
                            sx={{ ml: 1 }}
                            size={16}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
