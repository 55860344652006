import { useCurrentTenant } from "@drift/oneplatfront";
import { useCallback } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDTO } from "../../dtos/BaseUseCaseResponseDTO";
import { KpiDTO } from "../../dtos/KpiDTO";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

interface ApiRequestParams {
    deadlineFrom: number;
    deadlineTo: number;
}

interface UseGetKPIsProps {
    deadlineFrom: Date;
    deadlineTo: Date;
}

export function useGetKPIs(): BaseUseCaseResponseDTO<
    KpiDTO[],
    UseGetKPIsProps
> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } = useGetWithErrorHandler<
        KpiDTO[],
        ApiRequestParams
    >(`rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/kpis`, {
        ...useGetAxiosRequestConfigParams("use-get-kpis"),
    });

    const fetchData = useCallback(
        async (args?: { params?: UseGetKPIsProps }) => {
            return await fetch({
                params: {
                    deadlineFrom: args?.params?.deadlineFrom.getTime() || 0,
                    deadlineTo: args?.params?.deadlineTo.getTime() || 0,
                },
            });
        },
        [fetch],
    );

    return {
        data: data,
        error: error,
        fetch: fetchData,
        loading: loading,
    };
}
