import React from "react";
import { useTranslation } from "@onefront/react-sdk";
import { FulfilmentStatus } from "../../../core/dtos/FulfilmentStatus";
import { Typography, Box } from "@mui/material";

const renderStatus = (
    t: (key: string, params?: any) => string,
    status: FulfilmentStatus,
) => {
    return (
        <Box>
            <Typography variant="body2">
                {t(
                    `components.fulfilment_status_chip.status.${
                        status?.toLowerCase() ?? "not_available"
                    }`,
                    { defaultValue: "Stato non disponibile" },
                )}
            </Typography>
        </Box>
    );
};

interface FulfilmentStatusTypographyProps {
    status: FulfilmentStatus;
    revoked?: boolean;
}

export const FulfilmentStatusTypography: React.FC<
    FulfilmentStatusTypographyProps
> = ({ status, revoked = false }): JSX.Element => {
    const { t } = useTranslation("government-agency-hub-app");

    if (revoked) {
        return (
            <Box>
                <Typography variant="body2">
                    {t("components.fulfilment_status_chip.status.revoked", {
                        defaultValue: "Annullato",
                    })}
                </Typography>
            </Box>
        );
    }

    return renderStatus(t, status);
};
