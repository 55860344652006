import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { useCallback, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";

interface useUpdateDelegatedProps {
    taxSubmissionId: string;
}

export function useUpdateDelegated(input: useUpdateDelegatedProps): {
    updateDelegated: (delegatedTaxId: string) => Promise<boolean>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);
    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const config = useGetAxiosRequestConfigParams("use-update-delegated");

    const [loading, setLoading] = useState(false);

    const updateDelegated = useCallback(
        async (delegatedTaxId: string) => {
            setLoading(true);
            try {
                await useRequestRef.current.fetch({
                    ...config,
                    url: `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/tax-submissions/${input.taxSubmissionId}/update-delegated?delegatedTaxId=${delegatedTaxId}`,
                    lazy: true,
                    method: "patch",
                    stateless: true,
                });
            } catch (error) {
                return false;
            } finally {
                setLoading(false);
            }
            return true;
        },
        [config, input.taxSubmissionId, tenant?.id],
    );

    return {
        updateDelegated,
        loading: loading,
    };
}
