import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { ParsedTaxSubmissionDTO } from "../../dtos/ParsedTaxSubmissionDTO";
import { useErrorNotification } from "../../hooks/useErrorNotification";

export function useParseTaxSubmission() {
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState(false);
    const { showAxiosErrorMessage } = useErrorNotification();
    const { tenant } = useCurrentTenant(true);

    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const config = useGetAxiosRequestConfigParams("use-parse-tax-submissions");

    const parse = useCallback(
        async (fileContent: string) => {
            setLoading(true);
            try {
                return (
                    await useRequestRef.current.fetch({
                        ...config,
                        data: {
                            content: fileContent,
                        },
                        lazy: true,
                        method: "post",
                        stateless: true,
                        url: `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/tax-submissions/parse`,
                    })
                ).data as Promise<ParsedTaxSubmissionDTO>;
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        },
        [config, tenant?.id],
    );

    useEffect(() => {
        if (error) showAxiosErrorMessage(error);
    }, [error, showAxiosErrorMessage]);

    return {
        error: error,
        loading: loading,
        parse: parse,
    };
}
