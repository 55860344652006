import { useTranslation } from "@onefront/react-sdk";
import { Box, useTheme } from "@vapor/react-material";
import {
    Action,
    ActionType,
} from "../../../../../../core/dtos/TaxSubmissionDetailDTO";
import { DetailRow } from "../TaxSubmissionDetailSummary";
import dayjs from "dayjs";

interface TaxSubmissionActionProps {
    action: Action;
}

const ACTION_TYPE_TRANSLATIONS: Record<
    ActionType,
    { key: string; fallback: string }
> = {
    SEND_AWAITING: {
        key: "views.home.components.tax_submission_detail_summary.action.send_awaiting",
        fallback: "Invio fornitura in attesa di elaborazione",
    },
    SEND_REJECTED: {
        key: "views.home.components.tax_submission_detail_summary.action.send_rejected",
        fallback: "Reinvio fornitura rifiutata",
    },
    UPDATE_AGENCY_ID: {
        key: "views.home.components.tax_submission_detail_summary.action.update_agency_id",
        fallback: "Modifica protocollo generale",
    },
    UPDATE_DELEGATED: {
        key: "views.home.components.tax_submission_detail_summary.action.update_delegated",
        fallback: "Modifica soggetto incaricato",
    },
};

export const TaxSubmissionAction: React.FC<TaxSubmissionActionProps> = ({
    action,
}) => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();

    const operator =
        action.operator.familyName || action.operator.givenName
            ? `${action.operator.givenName} ${action.operator.familyName}`
            : null;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                border: `1px solid ${theme.palette.primary.gainsboro}`,
                p: 0.5,
                backgroundColor: theme.palette.primary.background,
            }}
        >
            <DetailRow
                label={t(
                    "views.home.components.tax_submission_detail_summary.action.type",
                    { defaultValue: "Tipo di operazione" },
                )}
                value={t(ACTION_TYPE_TRANSLATIONS[action.type].key, {
                    defaultValue:
                        ACTION_TYPE_TRANSLATIONS[action.type].fallback,
                })}
                divider={false}
            />
            <DetailRow
                label={t(
                    "views.home.components.tax_submission_detail_summary.action.operator",
                    { defaultValue: "Operatore" },
                )}
                value={
                    operator
                        ? operator
                        : t(
                              "views.home.components.tax_submission_detail_summary.action.operator.no_data",
                              { defaultValue: "Informazione non disponibile" },
                          )
                }
                divider={false}
            />
            <DetailRow
                label={t(
                    "views.home.components.tax_submission_detail_summary.action.date",
                    { defaultValue: "Data e ora" },
                )}
                value={dayjs(action.date).format("DD/MM/YYYY - HH:mm")}
                divider={false}
            />
        </Box>
    );
};
