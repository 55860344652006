import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { useCallback, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";

export function useSendRejectedTaxSubmission(): {
    sendRejected: (taxSubmissionId: string) => Promise<boolean>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);
    const [loading, setLoading] = useState(false);
    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const config = useGetAxiosRequestConfigParams(
        "use-send-rejected-tax-submission",
    );

    const sendRejectedTaxSubmission = useCallback(
        async (taxSubmissionId: string) => {
            setLoading(true);
            try {
                await useRequestRef.current.fetch({
                    ...config,
                    url: `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/send-rejected-tax-submission/${taxSubmissionId}`,
                    lazy: true,
                    method: "patch",
                    stateless: true,
                });
            } catch (error) {
                console.error(
                    `Couldn't send rejected tax submission ${taxSubmissionId}`,
                    error,
                );
                return false;
            } finally {
                setLoading(false);
            }
            return true;
        },
        [config, tenant?.id],
    );

    return {
        loading: loading,
        sendRejected: sendRejectedTaxSubmission,
    };
}
