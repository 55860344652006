import { Box } from "@mui/system";
import { useTranslation } from "@onefront/react-sdk";
import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Search } from "@vapor/react-icons";
import {
    Button,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
} from "@vapor/react-material";
import { useMemo, useState } from "react";
import { JSX } from "react/jsx-runtime";
import {
    TaxSubmissionDetailDTO,
    GovernmentAgencyType,
} from "../../../../../core/dtos/TaxSubmissionDetailDTO";
import { TaxSubmissionDetailActions } from "../tax-submission-detail-actions/TaxSubmissionDetailActions";
import { TaxSubmissionDetailOutcomeActions } from "../tax-submission-detail-outcome-actions/TaxSubmissionDetailOutcomeActions";
import { TaxSubmissionDetailTaxPayersTable } from "../tax-submission-detail-tax-payers-table/TaxSubmissionDetailTaxPayersTable";
import { TaxSubmissionStatus } from "../../../../../core/dtos/TaxSubmissionStatus";

interface TaxSubmissionDetailProps {
    taxSubmissionDetail: TaxSubmissionDetailDTO;
    loading: boolean;
    onClickPreviewOutcome: (
        availablesOutcomeTypes: string[],
        governmentAgencyType: GovernmentAgencyType,
        taxSubmissionId: string,
        selectedOutcomeType: string,
        taxPayer?: string,
    ) => unknown;
    onTaxSubmissionUpdate: () => unknown;
    revokeTaxPayers?: boolean;
}

export const TaxSubmissionDetail: React.FC<TaxSubmissionDetailProps> = ({
    taxSubmissionDetail,
    loading,
    onClickPreviewOutcome,
    onTaxSubmissionUpdate,
    revokeTaxPayers = false,
}): JSX.Element => {
    const { t } = useTranslation("government-agency-hub-app");

    const [revokeTaxSubmissionsEnabled, setRevokeTaxSubmissionsEnabled] =
        useState(revokeTaxPayers);

    const [taxPayersToRevoke, setTaxPayersToRevoke] = useState<
        { protocol: string; taxId: string }[]
    >([]);

    const detailsActionsEnabled = useMemo(() => {
        return (
            (taxSubmissionDetail.isRevocable && revokeTaxSubmissionsEnabled) ||
            taxSubmissionDetail.status === TaxSubmissionStatus.REJECTED
        );
    }, [
        revokeTaxSubmissionsEnabled,
        taxSubmissionDetail.isRevocable,
        taxSubmissionDetail.status,
    ]);

    return (
        <Stack spacing={2}>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ placeItems: "center" }}
            >
                <Box>
                    <TextField
                        disabled
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        sx={{
                                            background:
                                                "linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%)",
                                        }}
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label="Cerca per contribuente"
                        placeholder="Nome del contribuente"
                    />
                </Box>
                {taxSubmissionDetail.isRevocable && (
                    <Box>
                        <Button
                            onClick={() =>
                                setRevokeTaxSubmissionsEnabled(
                                    (oldValue) => !oldValue,
                                )
                            }
                            variant="text"
                        >
                            {revokeTaxSubmissionsEnabled
                                ? t(
                                      "views.home.components.tax_submission_detail.revoke_taxSubmission.btn.cancel",
                                      { defaultValue: "Esci" },
                                  )
                                : t(
                                      "views.home.components.tax_submission_detail.revoke_taxSubmission.btn.revoke",
                                      { defaultValue: "Annulla fornitura" },
                                  )}
                        </Button>
                    </Box>
                )}
            </Stack>
            {taxSubmissionDetail.isRevocable && revokeTaxSubmissionsEnabled && (
                <Box p={2} sx={{ background: "#FEEDC9", borderRadius: "8px" }}>
                    <Box>
                        <Typography variant="body700">
                            {t(
                                "views.home.components.tax_submission_detail.revoke_taxSubmission.btn.revoke",
                                { defaultValue: "Annulla fornitura" },
                            )}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body">
                            {t(
                                "views.home.components.tax_submission_detail.revoke_taxSubmission.alert.message",
                                {
                                    defaultValue:
                                        "Seleziona i contribuenti per i quali vuoi creare una fornitura di annullamento",
                                },
                            )}
                        </Typography>
                    </Box>
                </Box>
            )}
            <TaxSubmissionDetailTaxPayersTable
                taxSubmissionDetail={taxSubmissionDetail}
                loading={loading}
                onClickPreviewOutcome={onClickPreviewOutcome}
                onRowSelectionModelChange={function (fulfilmentIds: string[]) {
                    const fulfilments = taxSubmissionDetail.fulfilments.filter(
                        (fulfilment) =>
                            fulfilmentIds.includes(fulfilment.id) &&
                            !!fulfilment.protocol,
                    );

                    setTaxPayersToRevoke(
                        fulfilments.map((fulfilment) => {
                            return {
                                protocol: fulfilment.protocol as string, // it's safe because i'm filtering out all the fulfilments with protocol not defined
                                taxId: fulfilment.taxpayer.taxId,
                            };
                        }),
                    );
                }}
                revokeTaxSubmissionsEnabled={revokeTaxSubmissionsEnabled}
            />
            <Box
                sx={{
                    ml: "-32px !important",
                    // "div:nth-of-type(2)": { marginRight: 1 },
                }}
            >
                <VaporToolbar
                    contentRight={[
                        <Stack direction="row" spacing={1}>
                            {detailsActionsEnabled ? (
                                <TaxSubmissionDetailActions
                                    taxSubmissionDetail={taxSubmissionDetail}
                                    revokeTaxSubmissionEnabled={
                                        revokeTaxSubmissionsEnabled
                                    }
                                    onTaxSubmissionUpdate={
                                        onTaxSubmissionUpdate
                                    }
                                    taxPayersToRevoke={taxPayersToRevoke}
                                />
                            ) : (taxSubmissionDetail?.outcomeTypes?.length ??
                                  0) > 0 ? (
                                <TaxSubmissionDetailOutcomeActions
                                    taxSubmissionDetail={taxSubmissionDetail}
                                    onClickPreviewOutcome={
                                        onClickPreviewOutcome
                                    }
                                />
                            ) : (
                                <></>
                            )}
                        </Stack>,
                    ]}
                    size="large"
                    variant="regular"
                />
            </Box>
        </Stack>
    );
};
