import { useTranslation } from "@onefront/react-sdk";
import { VaporPageStickable } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack, useTheme } from "@vapor/react-material";

export const ServiceNotActive = () => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();

    return (
        <VaporPageStickable>
            <Stack justifyContent="center" alignItems="center">
                <Typography
                    variant="h4"
                    color={theme.palette.primary.textTitleColor}
                >
                    {t("components.service_not_active.not_active", {
                        defaultValue: "Sottoscrizione al servizio non attiva",
                    })}
                </Typography>
            </Stack>
        </VaporPageStickable>
    );
};
