import { useCurrentTenant } from "@drift/oneplatfront";
import { useCallback } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDTO } from "../../dtos/BaseUseCaseResponseDTO";
import { PaginatedTaxSubmissionsDTO } from "../../dtos/PaginatedTaxSubmissionsDTO";
import { TaxSubmissionStatus } from "../../dtos/TaxSubmissionStatus";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

interface ApiRequestParams {
    deadlineFrom: number;
    deadlineTo: number;
    intermediary?: string;
    operator?: string;
    taxPayer?: string;
    statuses: TaxSubmissionStatus[];
    type?: string;
    fulfilmentName?: string;
    limit: number;
    skip: number;
}
export interface TaxSubmissionFilterSearch {
    deadlineFrom: Date;
    deadlineTo: Date;
    intermediary?: string;
    operator?: string;
    taxPayer?: string;
    statuses: TaxSubmissionStatus[];
    type?: string;
    fulfilmentName?: string;
}
interface UseGetTaxSubmissionsProps {
    filter: TaxSubmissionFilterSearch;
    page: number;
    pageSize: number;
}

export function useGetTaxSubmissions(): BaseUseCaseResponseDTO<
    PaginatedTaxSubmissionsDTO,
    UseGetTaxSubmissionsProps
> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } = useGetWithErrorHandler<
        PaginatedTaxSubmissionsDTO,
        ApiRequestParams
    >(
        `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/tax-submissions`,
        {
            ...useGetAxiosRequestConfigParams("use-get-tax-submissions"),
        },
    );

    const fetchData = useCallback(
        async (args?: { params?: UseGetTaxSubmissionsProps }) => {
            const fulfilmentName = args?.params?.filter.fulfilmentName
                ?.trim()
                .replaceAll(/\s{2,}/g, " ");

            return await fetch({
                params: {
                    deadlineFrom:
                        args?.params?.filter.deadlineFrom.getTime() || 0,
                    deadlineTo: args?.params?.filter.deadlineTo.getTime() || 0,
                    operator: args?.params?.filter.operator,
                    intermediary: args?.params?.filter.intermediary,
                    taxPayer: args?.params?.filter.taxPayer,
                    ...(!!fulfilmentName
                        ? {
                              fulfilmentName,
                          }
                        : {}),
                    limit: args?.params?.pageSize || 0,
                    skip:
                        (args?.params?.page || 0) *
                        (args?.params?.pageSize || 0),
                    statuses: args?.params?.filter.statuses || [],
                },
            });
        },
        [fetch],
    );

    return {
        data: data,
        error: error,
        fetch: fetchData,
        loading: loading,
    };
}
