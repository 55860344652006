import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { useCallback, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";

interface useRevokeTaxSubmissionsProps {
    taxSubmissionId: string;
}

export function useRevokeTaxSubmissions(input: useRevokeTaxSubmissionsProps): {
    revoke: (
        taxPayerDocuments: {
            protocol: string;
            taxId: string;
        }[],
    ) => Promise<boolean>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);
    const [loading, setLoading] = useState(false);
    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const config = useGetAxiosRequestConfigParams("use-revoke-tax-submissions");

    const revokeTaxSubmissions = useCallback(
        async (
            taxPayerDocuments: {
                protocol: string;
                taxId: string;
            }[],
        ) => {
            setLoading(true);
            try {
                await useRequestRef.current.fetch({
                    ...config,
                    data: {
                        taxPayerDocuments: taxPayerDocuments,
                    },
                    lazy: true,
                    method: "post",
                    stateless: true,
                    url: `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/tax-submissions/${input.taxSubmissionId}/revoke`,
                });
                return true;
            } catch (error) {
                return false;
            } finally {
                setLoading(false);
            }
        },
        [config, input.taxSubmissionId, tenant?.id],
    );

    return {
        loading: loading,
        revoke: revokeTaxSubmissions,
    };
}
