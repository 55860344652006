import { Theme } from "@mui/system";
import { TaxSubmissionStatus } from "../dtos/TaxSubmissionStatus";

export const getColorByTaxSubmissionStatus = (
    status: TaxSubmissionStatus,
    theme: Theme,
) => {
    let themeColor: string;
    let vaporColor:
        | "blueSapphire"
        | "islamicGreen"
        | "russianViolet"
        | "maximumPurple"
        | "mediumSlateBlue"
        | "prune"
        | "royalFuchsia"
        | "rustyRed"
        | "russianViolet";

    switch (status) {
        case TaxSubmissionStatus.GENERIC_ERROR:
            themeColor = theme.palette.primary.accentRussianViolet;
            vaporColor = "russianViolet";
            break;
        case TaxSubmissionStatus.NOT_SENT_WITH_ERRORS:
        case TaxSubmissionStatus.NOT_SENT:
            themeColor = theme.palette.primary.accentRustyRed;
            vaporColor = "rustyRed";
            break;
        case TaxSubmissionStatus.PENDING:
            themeColor = theme.palette.primary.accentBlueSapphire;
            vaporColor = "blueSapphire";
            break;
        case TaxSubmissionStatus.REJECTED:
            themeColor = theme.palette.primary.accentPrune;
            vaporColor = "prune";
            break;
        case TaxSubmissionStatus.SENT_WITH_WARNINGS:
        case TaxSubmissionStatus.SENT:
            themeColor = theme.palette.primary.accentIslamicGreen;
            vaporColor = "islamicGreen";
            break;
        default:
            themeColor = theme.palette.primary.accentBlueSapphire;
            vaporColor = "blueSapphire";
            break;
    }

    return {
        themeColor,
        vaporColor,
    };
};
