import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { AxiosError } from "axios";
import { useCallback, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { DocumentDownloadDTO } from "../../dtos/DocumentDownloadDTO";
import { useErrorNotification } from "../../hooks/useErrorNotification";

interface ApiResponse<T> {
    data: T;
    headers: Headers;
}

export function useDownloadTaxSubmissionOutcome() {
    const { tenant } = useCurrentTenant(true);
    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const { showAxiosErrorMessage } = useErrorNotification();
    const [loading, setLoading] = useState(false);

    const config = useGetAxiosRequestConfigParams(
        "use-download-tax-submission-outcome",
    );

    const download = useCallback(
        async (
            taxSubmissionId: string,
            outcomeType: string,
            taxId?: string,
        ): Promise<DocumentDownloadDTO | undefined> => {
            setLoading(true);

            try {
                const result: ApiResponse<Uint8Array> =
                    await useRequestRef.current.fetch({
                        ...config,
                        responseType: "arraybuffer",
                        url: `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/tax-submissions/${taxSubmissionId}/outcome`,
                        params: {
                            outcomeType: outcomeType,
                            taxId: taxId,
                        },
                        lazy: true,
                        method: "get",
                        stateless: true,
                    });

                return {
                    content: result.data,
                    type: result.headers.get("content-type")!,
                };
            } catch (error: unknown) {
                showAxiosErrorMessage(error as AxiosError);
            } finally {
                setLoading(false);
            }
        },
        [config, showAxiosErrorMessage, tenant?.id],
    );

    return {
        download: download,
        loading: loading,
    };
}
