import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "@onefront/react-sdk";
import { Stack, TextField } from "@vapor/react-material";
import {
    ReactElement,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";
import { TaxPayer } from "../../../../../core/dtos/FulfilmentDTO";
import { Intermediary, Operator } from "../../../../../core/dtos/SubjectsDto";
import { useGetSubjects } from "../../../../../core/usecases/use-get-subjects/useGetSubjects";
import { useGetTaxPayers } from "../../../../../core/usecases/use-get-tax-payers/useGetTaxPayers";
import { TaxSubmissionFilterSearch } from "../../../../../core/usecases/use-get-tax-submissions/useGetTaxSubmissions";

interface TaxSubmissionsTableHeaderFilterProps {
    accordionLeft?: JSX.Element;
    accordionRight?: JSX.Element;
    filters: TaxSubmissionFilterSearch;
    onFilterChange: (filters: TaxSubmissionFilterSearch) => void;
    openFilters: boolean;
}

export const TaxSubmissionsTableHeaderFilter = ({
    accordionLeft,
    accordionRight,
    filters,
    onFilterChange,
    openFilters,
}: TaxSubmissionsTableHeaderFilterProps): ReactElement => {
    const { t } = useTranslation("government-agency-hub-app");

    const [selectedTaxPayer, setSelectedTaxPayer] = useState<TaxPayer | null>(
        null,
    );
    const [taxPayerOptions, setTaxPayerOptions] = useState<TaxPayer[]>([]);

    const [selectedOperator, setSelectedOperator] = useState<Operator | null>(
        null,
    );
    const [operatorOptions, setOperatorOptions] = useState<Operator[]>([]);

    const [selectedIntermediary, setSelectedIntermediary] =
        useState<Intermediary | null>(null);
    const [intermediaryOptions, setIntermediaryOptions] = useState<
        Intermediary[]
    >([]);

    const {
        data: subjects,
        fetch: fetchSubjects,
        loading: loadingSubjects,
    } = useGetSubjects();

    const {
        data: taxPayers,
        fetch: fetchTaxPayers,
        loading: loadingTaxPayers,
    } = useGetTaxPayers();

    const debouncedOnFilterChange = useDebouncedCallback((cb: () => void) => {
        cb();
    }, 500);

    useEffect(() => {
        debouncedOnFilterChange(() => {
            fetchSubjects({
                params: {
                    deadlineFrom: filters.deadlineFrom,
                    deadlineTo: filters.deadlineTo,
                },
            });
            fetchTaxPayers({
                params: {
                    deadlineFrom: filters.deadlineFrom,
                    deadlineTo: filters.deadlineTo,
                },
            });
        });
    }, [
        debouncedOnFilterChange,
        fetchSubjects,
        fetchTaxPayers,
        filters.deadlineFrom,
        filters.deadlineTo,
        filters.statuses,
    ]);

    // update taxpayers
    useEffect(() => {
        if (taxPayers) {
            setTaxPayerOptions(
                !selectedTaxPayer ||
                    taxPayers.find(
                        (taxPayer) => taxPayer.taxId === selectedTaxPayer.taxId,
                    )
                    ? taxPayers
                    : [selectedTaxPayer, ...taxPayers],
            );
        }
    }, [selectedTaxPayer, taxPayers]);

    useEffect(() => {
        setSelectedTaxPayer(
            taxPayerOptions?.find(
                (taxPayer) => taxPayer.taxId === filters.taxPayer,
            ) ?? null,
        );
    }, [filters, onFilterChange, taxPayerOptions]);

    // update operators
    useEffect(() => {
        if (subjects?.operators) {
            setOperatorOptions(
                !selectedOperator ||
                    subjects?.operators.find(
                        (operator) => operator.id === selectedOperator.id,
                    )
                    ? subjects?.operators
                    : [selectedOperator, ...subjects?.operators],
            );
        }
    }, [selectedOperator, subjects?.operators]);

    useEffect(() => {
        setSelectedOperator(
            operatorOptions?.find(
                (operator) => operator.id === filters.operator,
            ) ?? null,
        );
    }, [filters.operator, operatorOptions]);

    // update intermediaries
    useEffect(() => {
        if (subjects?.intermediaries) {
            setIntermediaryOptions(
                !selectedIntermediary ||
                    subjects?.intermediaries.find(
                        (intermediary) =>
                            intermediary.taxId === selectedIntermediary.taxId,
                    )
                    ? subjects?.intermediaries
                    : [selectedIntermediary, ...subjects?.intermediaries],
            );
        }
    }, [selectedIntermediary, subjects?.intermediaries]);

    useEffect(() => {
        setSelectedIntermediary(
            intermediaryOptions?.find(
                (intermediary) => intermediary.taxId === filters.intermediary,
            ) ?? null,
        );
    }, [filters.intermediary, intermediaryOptions]);

    const handleTaxPayerChange = useCallback(
        (_: SyntheticEvent<Element, Event>, value: TaxPayer | null) => {
            onFilterChange({
                ...filters,
                taxPayer: value?.taxId || undefined,
            });
        },
        [filters, onFilterChange],
    );

    const handleOperatorChange = useCallback(
        (_: SyntheticEvent<Element, Event>, value: Operator | null) => {
            onFilterChange({
                ...filters,
                operator: value?.id || undefined,
            });
        },
        [filters, onFilterChange],
    );

    const handleIntermediaryChange = useCallback(
        (_: SyntheticEvent<Element, Event>, value: Intermediary | null) => {
            onFilterChange({
                ...filters,
                intermediary: value?.taxId || undefined,
            });
        },
        [filters, onFilterChange],
    );

    return (
        <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    {accordionLeft}
                </Stack>
                {accordionRight}
            </Stack>
            {openFilters && (
                <Stack direction="row" spacing={2}>
                    {/* FIXME: use autocomplete from vapor once clear button gets implemented on single input */}
                    <Autocomplete
                        disabled={
                            loadingTaxPayers || taxPayerOptions.length === 0
                        }
                        loading={loadingTaxPayers}
                        getOptionLabel={(option: TaxPayer) => option.name}
                        isOptionEqualToValue={(option, value) => {
                            return option.taxId === value.taxId;
                        }}
                        noOptionsText={t(
                            "views.home.components.tax_submissions_table_header_filters.taxPayer.no_options_text",
                            {
                                defaultValue: "Nessun contribuente trovato",
                            },
                        )}
                        onChange={handleTaxPayerChange}
                        options={taxPayerOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t(
                                    "views.home.components.tax_submissions_table_header_filters.taxPayer.label",
                                    {
                                        defaultValue: "Contribuente",
                                    },
                                )}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: { width: "12vw" },
                                }}
                                placeholder={t(
                                    "views.home.components.tax_submissions_table_header_filters.taxPayer.placeholder",
                                    {
                                        defaultValue: "Cerca un contribuente",
                                    },
                                )}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: 0,
                                        paddingLeft: "4px",
                                    },
                                }}
                            />
                        )}
                        value={selectedTaxPayer}
                    />
                    <Autocomplete
                        disabled={
                            loadingSubjects || operatorOptions.length === 0
                        }
                        loading={loadingSubjects}
                        getOptionLabel={(option: Operator) =>
                            `${option.familyName} ${option.givenName}`
                        }
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                        }}
                        noOptionsText={t(
                            "views.home.components.tax_submissions_table_header_filters.operator.no_options_text",
                            {
                                defaultValue: "Nessun operatore trovato",
                            },
                        )}
                        onChange={handleOperatorChange}
                        options={operatorOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t(
                                    "views.home.components.tax_submissions_table_header_filters.operator.label",
                                    {
                                        defaultValue: "Operatore",
                                    },
                                )}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: { width: "12vw" },
                                }}
                                placeholder={t(
                                    "views.home.components.tax_submissions_table_header_filters.operator.placeholder",
                                    {
                                        defaultValue: "Cerca un operatore",
                                    },
                                )}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: 0,
                                        paddingLeft: "4px",
                                    },
                                }}
                            />
                        )}
                        value={selectedOperator}
                    />
                    <Autocomplete
                        disabled={
                            loadingSubjects || intermediaryOptions.length === 0
                        }
                        loading={loadingSubjects}
                        getOptionLabel={(option: Intermediary) =>
                            `${option.taxId} ${option.siteCode}`
                        }
                        isOptionEqualToValue={(option, value) => {
                            return option.taxId === value.taxId;
                        }}
                        noOptionsText={t(
                            "views.home.components.tax_submissions_table_header_filters.intermediary.no_options_text",
                            {
                                defaultValue: "Nessun intermediario trovato",
                            },
                        )}
                        onChange={handleIntermediaryChange}
                        options={intermediaryOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t(
                                    "views.home.components.tax_submissions_table_header_filters.intermediary.label",
                                    {
                                        defaultValue: "Intermediario",
                                    },
                                )}
                                placeholder={t(
                                    "views.home.components.tax_submissions_table_header_filters.intermediary.placeholder",
                                    {
                                        defaultValue: "Cerca un intermediario",
                                    },
                                )}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: { width: "12vw" },
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: 0,
                                        paddingLeft: "4px",
                                    },
                                }}
                            />
                        )}
                        value={selectedIntermediary}
                    />
                </Stack>
            )}
        </Stack>
    );
};
