import {
    IconDefinition,
    faEye,
    faMagnifyingGlassArrowRight,
    faShareAll,
    faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import {
    faCircleExclamation,
    faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "@mui/system";
import {
    DataGrid,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridActionsCellItem,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
    GridRowParams,
} from "@mui/x-data-grid";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { ArrowRight, VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Checkbox,
    IconButton,
    Stack,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@vapor/react-material";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTaxSubmissionContext } from "../../../../../core/contexts/TaxSubmissionContext";
import { OwnerApp } from "../../../../../core/dtos/OwnerApp";
import {
    TaxSubmissionDTO,
    TaxSubmissionErrorType,
} from "../../../../../core/dtos/TaxSubmissionDTO";
import {
    GovernmentAgency,
    GovernmentAgencyType,
} from "../../../../../core/dtos/TaxSubmissionDetailDTO";
import { TaxSubmissionStatus } from "../../../../../core/dtos/TaxSubmissionStatus";
import { ValidationDocument } from "../../../../../core/dtos/ValidationDocumentDto";
import { useDownloadTaxSubmissionSogei } from "../../../../../core/usecases/use-download-tax-submission-sogei/useDownloadTaxSubmissionSogei";
import { useSendRejectedTaxSubmission } from "../../../../../core/usecases/use-send-rejected-tax-submission/useSendRejectedTaxSubmission";
import { downloadFile } from "../../../../../core/utils/downloadFile";
import { TaxSubmissionStatusChip } from "../../../../components/tax-submission-status-chip/TaxSubmissionStatusChip";
import { createNavigationUrl, processUrn } from "../../../../utils/resolveUrn";
import { SendRejectedTaxSubmissionModal } from "../send-rejected-tax-submission-modal/SendRejectedTaxSubmissionModal";
import { EllipsisVertical } from "@vapor/react-icons";

const renderHeader = (theme: Theme, params: GridColumnHeaderParams) => (
    <Typography
        color={theme.palette.primary.textTitleColor}
        variant="body500"
        noWrap
    >
        {params.colDef.headerName}
    </Typography>
);

const renderCell = (_: Theme, params: GridRenderCellParams) => (
    <Typography variant="body" noWrap>
        {params.value}
    </Typography>
);

const renderDateCell = (theme: Theme, params: GridRenderCellParams) => (
    <Typography variant="body" noWrap>
        {dayjs(params.value).format("DD/MM/YYYY")}
    </Typography>
);

const ERROR_WARNING_CELL: Record<
    TaxSubmissionErrorType,
    { icon: IconDefinition; label: string; color: string }
> = {
    MISSING_AGENCY_PROTOCOL: {
        icon: faCircleExclamation,
        label: "views.home.components.tax_submissions_table.cell.warnings.missing_agency_protocol.tooltip",
        color: "accentRustyRed",
    },
    VALIDATION: {
        icon: faCircleExclamation,
        label: "views.home.components.tax_submissions_table.cell.warnings.sogei_error.tooltip",
        color: "accentRustyRed",
    },
    TAX_PAYERS: {
        icon: faTriangleExclamation,
        label: "views.home.components.tax_submissions_table.cell.warnings.tax_payers_error.tooltip",
        color: "baseWarningColor",
    },
};

const renderWarningsCell = (
    theme: Theme,
    params: GridRenderCellParams<RowModel>,
    t: (key: string, options?: any) => string,
) => {
    const warning = ERROR_WARNING_CELL[params.value as TaxSubmissionErrorType];

    if (!warning) return;

    return (
        <Tooltip title={t(warning.label)} placement="top">
            <FontAwesomeIcon
                size="2x"
                icon={warning.icon}
                color={theme.palette.primary[warning.color]}
            />
        </Tooltip>
    );
};

const renderActionsCell = (
    params: GridRenderCellParams<RowModel>,
    onArrowClick: (taxSubmission: string) => unknown,
) => (
    <>
        <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton onClick={() => onArrowClick(params.row.id)}>
                <ArrowRight color="interactive" />
            </IconButton>
        </Stack>
    </>
);

const renderStatus = (_: Theme, params: GridRenderCellParams) => {
    return <TaxSubmissionStatusChip status={params.value} variant="ghost" />;
};

interface RowModel {
    deadline: string;
    documents: number;
    error?: TaxSubmissionErrorType;
    fulfilmentName: string;
    governmentAgency: GovernmentAgency;
    id: string;
    isRevocable: boolean;
    isSendable: boolean;
    name: string;
    status: TaxSubmissionStatus;
    type: string;
    uploadDate: string;
    validationDocument?: ValidationDocument;
    ownerApp?: OwnerApp;
}
interface TaxSubmissionsTableProps {
    taxSubmissions: TaxSubmissionDTO[];
    loading: boolean;
    page: number;
    pageSize: number;
    rowCount: number;
    onTaxSubmissionsFetch: () => unknown;
    onChangePaginationModel: (page: number, pageSize: number) => unknown;
    onCheckNotSentTaxSubmissions: (
        taxSubmissions: TaxSubmissionDTO[],
    ) => unknown;
    onClickTaxSubmission: (taxSubmission: string) => unknown;
    onClickPreviewSogeiOutcome: (content: string, loading: boolean) => unknown;
    onClickRevokeTaxSubmission: (taxSubmissionId: string) => unknown;
}

export const TaxSubmissionsTable: React.FC<TaxSubmissionsTableProps> = ({
    taxSubmissions,
    loading,
    page = 0,
    pageSize = 10,
    rowCount,
    onTaxSubmissionsFetch,
    onChangePaginationModel,
    onCheckNotSentTaxSubmissions,
    onClickTaxSubmission,
    onClickPreviewSogeiOutcome,
    onClickRevokeTaxSubmission,
}): JSX.Element => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();
    const isXlScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up(theme.breakpoints.values.xl),
    );

    const { loading: loadingSendRejectedTaxSubmission, sendRejected } =
        useSendRejectedTaxSubmission();

    const [rejectedTaxSubmissionId, setRejectedTaxSubmissionId] = useState<
        string | undefined
    >();

    const { isUrnValid } = useTaxSubmissionContext();

    const navigate = useNavigate();

    //states
    const { download } = useDownloadTaxSubmissionSogei();

    //functions
    const handleSendRejectedTaxSubmissionModalClose = useCallback(() => {
        setRejectedTaxSubmissionId(undefined);
    }, []);

    const onClickDownloadSogei = useCallback(
        async (
            taxSubmissionId: string,
            type: ValidationDocument,
            event: React.MouseEvent<HTMLElement>,
        ) => {
            const resourceData = await download(taxSubmissionId, type);

            if (!resourceData) {
                console.error("could not download sogei document");
                return;
            }
            downloadFile(
                resourceData.content,
                "esito_sogei",
                resourceData.type,
            );
        },
        [download],
    );

    const onClickPreviewSogeiValidation = useCallback(
        async (
            taxSubmissionId: string,
            type: ValidationDocument,
            event: React.MouseEvent<HTMLElement>,
        ) => {
            onClickPreviewSogeiOutcome("", true);
            const resourceData = await download(taxSubmissionId, type);

            if (!resourceData) {
                console.error("could not download sogei document");
                return;
            }
            if (resourceData) {
                onClickPreviewSogeiOutcome(
                    String.fromCharCode(
                        ...new Uint8Array(resourceData.content),
                    ),
                    false,
                );
            }
        },
        [download, onClickPreviewSogeiOutcome],
    );

    const onClickSendRejected = async () => {
        if (!rejectedTaxSubmissionId) return false;
        const response = await sendRejected(rejectedTaxSubmissionId);
        onTaxSubmissionsFetch();
        return response;
    };

    const getColumns = useCallback(
        (
            onClickTaxSubmission: (taxSubmission: string) => unknown,
            isXlScreen: boolean,
            theme: Theme,
            t: (key: string, options?: any) => string,
        ): GridColDef[] => {
            const columns: GridColDef[] = [
                {
                    ...GRID_CHECKBOX_SELECTION_COL_DEF,
                    renderCell: (params) => {
                        const isRowSelected = params.api.isRowSelected(
                            params.row.id,
                        );
                        const isRowSelectable = params.api.isRowSelectable(
                            params.row.id,
                        );

                        return !isRowSelectable ? (
                            <Tooltip
                                placement="top"
                                title={t(
                                    "views.home.components.tax_submissions_table.cell.check.tooltip",
                                    {
                                        defaultValue:
                                            "Nessuna azione disponibile",
                                    },
                                )}
                            >
                                <Box>
                                    <Checkbox disabled />
                                </Box>
                            </Tooltip>
                        ) : (
                            <Checkbox
                                checked={isRowSelected}
                                onChange={() =>
                                    params.api.selectRow(
                                        params.row.id,
                                        !isRowSelected,
                                    )
                                }
                            />
                        );
                    },
                },
                {
                    align: "left",
                    field: "error",
                    flex: 0.1,
                    headerAlign: "left",
                    hideSortIcons: true,
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) =>
                        renderWarningsCell(theme, params, t),
                    sortable: false,
                },
                {
                    align: "left",
                    field: "fulfilmentName",
                    flex: 1,
                    headerAlign: "left",
                    headerName: t(
                        "views.home.components.tax_submissions_table.header.fulfilment",
                        { defaultValue: "Adempimento" },
                    ),
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) => renderCell(theme, params),
                },
                {
                    align: "left",
                    field: "name",
                    flex: isXlScreen ? 0.5 : 0.75,
                    headerAlign: "left",
                    headerName: t(
                        "views.home.components.tax_submissions_table.header.name",
                        { defaultValue: "Fornitura" },
                    ),
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) => renderCell(theme, params),
                },
                {
                    align: "right",
                    field: "documents",
                    flex: isXlScreen ? 0.2 : 0.4,
                    headerAlign: "right",
                    headerName: t(
                        "views.home.components.tax_submissions_table.header.documents",
                        { defaultValue: "Contribuenti" },
                    ),
                    type: "number",
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) => renderCell(theme, params),
                },
                {
                    align: "left",
                    field: "deadline",
                    flex: isXlScreen ? 0.2 : 0.5,
                    headerAlign: "left",
                    headerName: t(
                        "views.home.components.tax_submissions_table.header.deadline",
                        { defaultValue: "Data scadenza" },
                    ),
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) => renderDateCell(theme, params),
                },
                {
                    align: "left",
                    field: "uploadDate",
                    flex: isXlScreen ? 0.2 : 0.5,
                    headerAlign: "left",
                    headerName: t(
                        "views.home.components.tax_submissions_table.header.upload_date",
                        { defaultValue: "Data invio" },
                    ),
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) => renderDateCell(theme, params),
                },
                {
                    align: "left",
                    field: "status",
                    flex: isXlScreen ? 0.25 : 0.55,
                    headerAlign: "left",
                    headerName: t(
                        "views.home.components.tax_submissions_table.header.status",
                        { defaultValue: "Stato" },
                    ),
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) => renderStatus(theme, params),
                },
                {
                    field: "menu",
                    type: "actions",
                    flex: 0.1,
                    getActions: (params: GridRowParams<RowModel>) => {
                        const taxSubmission = params.row;
                        const actions = [];

                        if (taxSubmission) {
                            if (taxSubmission.isRevocable) {
                                actions.push(
                                    <GridActionsCellItem
                                        icon={
                                            <VaporIcon
                                                icon={faXmark}
                                                color="interactive"
                                            />
                                        }
                                        label={t(
                                            "views.home.components.tax_submissions_table.actions.revoke_tax_submissions",
                                            {
                                                defaultValue:
                                                    "Annulla fornitura",
                                            },
                                        )}
                                        style={{
                                            color: theme.palette.primary
                                                .interactiveDefault,
                                        }}
                                        onClick={(_) =>
                                            onClickRevokeTaxSubmission(
                                                params.row.id,
                                            )
                                        }
                                        showInMenu
                                    />,
                                );
                            }

                            if (!!taxSubmission.validationDocument) {
                                if (
                                    taxSubmission.governmentAgency.type !==
                                    GovernmentAgencyType.sdi
                                ) {
                                    actions.push(
                                        <GridActionsCellItem
                                            icon={
                                                <VaporIcon
                                                    icon={faEye}
                                                    color="interactive"
                                                />
                                            }
                                            label={t(
                                                "views.home.components.tax_submissions_table.actions.preview_sogei_file",
                                                {
                                                    defaultValue:
                                                        "Visualizza File Sogei",
                                                },
                                            )}
                                            style={{
                                                color: theme.palette.primary
                                                    .interactiveDefault,
                                            }}
                                            onClick={(event) =>
                                                onClickPreviewSogeiValidation(
                                                    params.row.id,
                                                    taxSubmission.validationDocument!,
                                                    event,
                                                )
                                            }
                                            showInMenu
                                        />,
                                    );
                                }

                                actions.push(
                                    <GridActionsCellItem
                                        icon={
                                            <VaporIcon
                                                icon={faDownload}
                                                color="interactive"
                                            />
                                        }
                                        label={t(
                                            "views.home.components.tax_submissions_table.actions.download_sogei_file",
                                            {
                                                defaultValue:
                                                    "Scarica File Sogei",
                                            },
                                        )}
                                        style={{
                                            color: theme.palette.primary
                                                .interactiveDefault,
                                        }}
                                        onClick={(event) =>
                                            onClickDownloadSogei(
                                                params.row.id,
                                                taxSubmission.validationDocument!,
                                                event,
                                            )
                                        }
                                        showInMenu
                                    />,
                                );
                            }

                            if (
                                taxSubmission.status ===
                                TaxSubmissionStatus.REJECTED
                            ) {
                                actions.push(
                                    <GridActionsCellItem
                                        icon={
                                            <VaporIcon
                                                icon={faShareAll}
                                                color="interactive"
                                            />
                                        }
                                        label={t(
                                            "views.home.components.tax_submissions_table.actions.send_rejected_tax_submission",
                                            {
                                                defaultValue:
                                                    "Reinvia fornitura",
                                            },
                                        )}
                                        style={{
                                            color: theme.palette.primary
                                                .interactiveDefault,
                                        }}
                                        onClick={(_) =>
                                            // TODO: implement
                                            // console.log(
                                            //     "send rejected",
                                            //     params.row.id,
                                            // )
                                            setRejectedTaxSubmissionId(
                                                params.row.id,
                                            )
                                        }
                                        showInMenu
                                    />,
                                );
                            }

                            if (params.row.ownerApp) {
                                if (isUrnValid(params.row.ownerApp.urn)) {
                                    actions.push(
                                        <GridActionsCellItem
                                            icon={
                                                <VaporIcon
                                                    icon={
                                                        faMagnifyingGlassArrowRight
                                                    }
                                                    color="interactive"
                                                    iconWeight="solid"
                                                />
                                            }
                                            label={t(
                                                "views.home.components.tax_submissions_table.actions.go_to_procedure",
                                                {
                                                    defaultValue:
                                                        "Vai alla procedura",
                                                },
                                            )}
                                            style={{
                                                color: theme.palette.primary
                                                    .interactiveDefault,
                                            }}
                                            onClick={(
                                                _: React.MouseEvent<
                                                    HTMLLIElement,
                                                    MouseEvent
                                                >,
                                            ) => {
                                                //if present redirect to the taxSubmissionPath, else redirect just to the urn
                                                if (
                                                    params.row.ownerApp
                                                        ?.taxSubmissionPath
                                                ) {
                                                    navigate(
                                                        createNavigationUrl(
                                                            params.row.ownerApp
                                                                .taxSubmissionPath,
                                                        ),
                                                    );
                                                } else {
                                                    navigate(
                                                        createNavigationUrl(
                                                            processUrn(
                                                                params.row
                                                                    .ownerApp!
                                                                    .urn,
                                                            ),
                                                        ),
                                                    );
                                                }
                                            }}
                                            showInMenu
                                        />,
                                    );
                                }
                            }
                        }

                        return actions;
                    },
                },
                {
                    align: "left",
                    field: "actions",
                    flex: isXlScreen ? 0.1 : 0.25,
                    headerAlign: "left",
                    hideSortIcons: true,
                    renderHeader: (params: GridColumnHeaderParams) =>
                        renderHeader(theme, params),
                    renderCell: (params) =>
                        renderActionsCell(params, onClickTaxSubmission),
                    sortable: false,
                    type: "actions",
                },
            ];

            return columns;
        },
        [
            isUrnValid,
            navigate,
            onClickDownloadSogei,
            onClickPreviewSogeiValidation,
            onClickRevokeTaxSubmission,
        ],
    );

    return (
        <Box>
            <SendRejectedTaxSubmissionModal
                loading={loadingSendRejectedTaxSubmission}
                open={!!rejectedTaxSubmissionId}
                onClose={handleSendRejectedTaxSubmissionModalClose}
                onSend={onClickSendRejected}
            />
            <DataGrid
                slots={{
                    moreActionsIcon: (props) => (
                        <EllipsisVertical {...props} color="interactive" />
                    ),
                }}
                autoHeight
                checkboxSelection
                columns={getColumns(onClickTaxSubmission, isXlScreen, theme, t)}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: pageSize,
                            page: page,
                        },
                    },
                }}
                isRowSelectable={(params: GridRowParams<RowModel>) =>
                    params.row.isSendable
                }
                loading={loading}
                onPaginationModelChange={(model) =>
                    onChangePaginationModel(model.page, model.pageSize)
                }
                onRowSelectionModelChange={(taxSubmissionIds) => {
                    onCheckNotSentTaxSubmissions(
                        taxSubmissions.filter((taxSubmission) =>
                            taxSubmissionIds.includes(taxSubmission.id),
                        ),
                    );
                }}
                pageSizeOptions={[pageSize]}
                paginationMode="server"
                pagination
                rows={taxSubmissions.map((taxSubmission) => {
                    return {
                        deadline: taxSubmission.fulfilmentSchema.deadline,
                        documents: taxSubmission.documentCounters.total,
                        error: taxSubmission.error,
                        fulfilmentName: taxSubmission.fulfilmentSchema.name,
                        governmentAgency: taxSubmission.governmentAgency,
                        id: taxSubmission.id,
                        isRevocable: taxSubmission.isRevocable,
                        isSendable: taxSubmission.isSendable,
                        name: taxSubmission.fileName,
                        status: taxSubmission.status,
                        type: taxSubmission.type,
                        uploadDate: taxSubmission.uploadDate,
                        validationDocument: taxSubmission.validationDocument,
                        warnings: taxSubmission.documentCounters.error,
                        ownerApp: taxSubmission.ownerApp,
                    } as RowModel;
                })}
                rowCount={rowCount}
                sx={{
                    border: "none",
                    width: `calc(100% - 10px)`, // width is a workaround to use the fluid column width of mui-x-datagrid
                }}
            />
        </Box>
    );
};
