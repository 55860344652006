import { Previewer } from "@onefront/react-documentale-components";
import { useTranslation } from "@onefront/react-sdk";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box } from "@vapor/react-material";
import React, { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { DocumentDownloadDTO } from "../../../../../core/dtos/DocumentDownloadDTO";
import { GovernmentAgencyType } from "../../../../../core/dtos/TaxSubmissionDetailDTO";
import { useDownloadTaxSubmissionOutcome } from "../../../../../core/usecases/use-download-tax-submission-outcome/useDownloadTaxSubmissionOutcome";
import { useDownloadTaxSubmissionPdfOutcome } from "../../../../../core/usecases/use-download-tax-submission-pdf-outcome/useDownloadTaxSubmissionPdfOutcome";

interface TaxSubmissionOutcomePreviewerProps {
    taxSubmissionId: string;
    governmentAgencyType: GovernmentAgencyType;
    outcomeType: string;
    taxPayer?: string;
}

export const TaxSubmissionOutcomePreviewer: React.FC<
    TaxSubmissionOutcomePreviewerProps
> = ({ taxSubmissionId, governmentAgencyType, outcomeType, taxPayer }) => {
    const { t } = useTranslation("government-agency-hub-app");

    const [resourceData, setResourceData] = useState<{
        content: string;
        type: string;
    }>();

    const { download: downloadPdfOutcome } =
        useDownloadTaxSubmissionPdfOutcome();
    const { download: downloadOutcome } = useDownloadTaxSubmissionOutcome();

    const isPdfPreview = governmentAgencyType === GovernmentAgencyType.ade;

    useEffect(() => {
        const downloadResource = async () => {
            let data: DocumentDownloadDTO | undefined;

            if (isPdfPreview)
                data = await downloadPdfOutcome(
                    taxSubmissionId,
                    [outcomeType],
                    taxPayer,
                );
            else
                data = await downloadOutcome(
                    taxSubmissionId,
                    outcomeType,
                    taxPayer,
                );

            if (!data) {
                console.error("could not download outcome document");
                return;
            }
            setResourceData({
                content: isPdfPreview
                    ? btoa(String.fromCharCode(...new Uint8Array(data.content)))
                    : String.fromCharCode(...new Uint8Array(data.content)),
                type: data.type,
            });
        };

        downloadResource();
    }, [
        taxSubmissionId,
        outcomeType,
        taxPayer,
        isPdfPreview,
        downloadPdfOutcome,
        downloadOutcome,
    ]);

    return (
        <LoadingWrapper
            loading={!resourceData}
            spinnerProps={{
                loadingText: t("components.loading_wrapper.loading_message", {
                    defaultValue: "Attendere prego",
                }),
            }}
        >
            {resourceData &&
                (resourceData.type === "application/zip" ? (
                    <Box marginTop="24px">
                        <Typography>
                            {t("components.outcome_previewer.no_preview", {
                                defaultValue:
                                    "Anteprima non disponibile per questo tipo di file",
                            })}
                        </Typography>
                    </Box>
                ) : isPdfPreview ? (
                    <Previewer
                        file={resourceData.content}
                        mimeType="application/pdf"
                        fileName={outcomeType}
                    />
                ) : (
                    <SyntaxHighlighter
                        language={resourceData.type}
                        style={docco}
                    >
                        {resourceData.content}
                    </SyntaxHighlighter>
                ))}
        </LoadingWrapper>
    );
};
