import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Card, CardContent, Stack, useTheme } from "@vapor/react-material";
import { ReactElement, useMemo } from "react";
import { TaxSubmissionStatus } from "../../../../../core/dtos/TaxSubmissionStatus";
import { getColorByTaxSubmissionStatus } from "../../../../../core/utils/getColorByTaxSubmissionStatus";
import { TaxSubmissionStatusAlert } from "../../../../components/tax-submission-status-alert/TaxSubmissionStatusAlert";

interface TaxSubmissionGenericErrorKPICardProps {
    isSelected: boolean;
    loading: boolean;
    numberOfTaxSubmissions: number;
    onClickKPI: (taxSubmissionStatus: TaxSubmissionStatus) => unknown;
}

export const TaxSubmissionGenericErrorKPICard = ({
    isSelected = false,
    loading,
    numberOfTaxSubmissions,
    onClickKPI,
}: TaxSubmissionGenericErrorKPICardProps): ReactElement => {
    const theme = useTheme();

    const borderColor = useMemo(
        () =>
            getColorByTaxSubmissionStatus(
                TaxSubmissionStatus.GENERIC_ERROR,
                theme,
            ).themeColor,
        [theme],
    );

    return (
        <Card
            elevation={isSelected ? 4 : 0}
            onClick={() =>
                !loading && onClickKPI(TaxSubmissionStatus.GENERIC_ERROR)
            }
            sx={{
                ":hover": {
                    boxShadow: 4,
                },
                border: isSelected
                    ? `1px solid ${theme.palette.primary.interactiveDefault}`
                    : `1px solid ${theme.palette.primary.gainsboro}`,
                borderLeft: `4px solid ${borderColor}`,
                cursor: "pointer",
                width: "100%",
                minHeight: "82px",
            }}
            style={{
                backgroundColor: isSelected
                    ? theme.palette.primary.whiteSmoke
                    : undefined,
            }}
        >
            <CardContent
                sx={{
                    paddingTop: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingBottom: 1,
                    ":last-child": {
                        paddingBottom: 1,
                    },
                }}
            >
                <LoadingWrapper
                    loading={loading}
                    variant="backdrop"
                    skeletonProps={{ rowsNumber: 1 }}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Typography
                            color={theme.palette.primary.textTitleColor}
                            variant="titleMedium"
                        >
                            {numberOfTaxSubmissions}
                        </Typography>
                        <TaxSubmissionStatusAlert
                            status={TaxSubmissionStatus.GENERIC_ERROR}
                        />
                    </Stack>
                </LoadingWrapper>
            </CardContent>
        </Card>
    );
};
