import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { useCallback, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";

export function useUpdateTaxSubmissionAgencyId(): {
    updateAgencyId: (
        taxSubmissionId: string,
        agencyId: string,
    ) => Promise<boolean>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);
    const [loading, setLoading] = useState(false);
    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const config = useGetAxiosRequestConfigParams(
        "use-update-tax-submission-agency-id",
    );

    const updateAgencyId = useCallback(
        async (taxSubmissionId: string, agencyId: string) => {
            setLoading(true);
            try {
                await useRequestRef.current.fetch({
                    ...config,
                    url: `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/tax-submissions/${taxSubmissionId}/update-agency-id?agencyId=${agencyId}`,
                    lazy: true,
                    method: "patch",
                    stateless: true,
                });
            } catch (error) {
                console.error(
                    `Couldn't update tax submission ${taxSubmissionId} agency id`,
                    error,
                );
                return false;
            } finally {
                setLoading(false);
            }
            return true;
        },
        [config, tenant?.id],
    );

    return {
        loading: loading,
        updateAgencyId,
    };
}
