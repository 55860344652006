import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { AxiosError } from "axios";
import { useRef, useState } from "react";
import { v4 } from "uuid";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { TaxSubmissionDTO } from "../../dtos/TaxSubmissionDTO";

export function useSendTaxSubmission(): {
    send: (taxSubmissions: TaxSubmissionDTO[]) => Promise<{
        succeded: { id: string }[];
        failed: { id: string; error: string }[];
    }>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);
    const [loading, setLoading] = useState(false);
    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const config = useGetAxiosRequestConfigParams("use-send-tax-submission");

    const sendTaxSubmissions = async (
        taxSubmissions: TaxSubmissionDTO[] = [],
    ) => {
        setLoading(true);

        const succeded: { id: string }[] = [];
        const failed: { id: string; error: string }[] = [];

        await Promise.allSettled(
            taxSubmissions.map((taxSubmission) => {
                config.headers["x-correlation-id"] = v4();
                config.headers["x-request-id"] = v4();

                return useRequestRef.current
                    .fetch({
                        ...config,
                        url: `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/send-awaiting-tax-submission/${taxSubmission.id}`,
                        lazy: true,
                        method: "patch", // TODO maybe create a usePatchWithErrorHandler hook
                        stateless: true,
                    })
                    .then(() => {
                        succeded.push({ id: taxSubmission.id });
                    })
                    .catch((e: AxiosError) => {
                        failed.push({ id: taxSubmission.id, error: e.message });
                    });
            }),
        );

        setLoading(false);

        return { succeded, failed };
    };

    return {
        loading: loading,
        send: sendTaxSubmissions,
    };
}
