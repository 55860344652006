import { useGet } from "@onefront/react-sdk";
import { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import {
    BaseUseCaseResponseDTO,
    FetchArgs,
} from "../dtos/BaseUseCaseResponseDTO";
import { useErrorNotification } from "./useErrorNotification";

export const useGetWithErrorHandler = <TData, TFetchParams = undefined>(
    url: string,
    config: any = {},
    enableErrorHandler: boolean = true,
): BaseUseCaseResponseDTO<TData, TFetchParams> => {
    const useGetRef = useRef(
        useGet(url, { lazy: true, stateless: true, ...config }),
    );
    useGetRef.current = useGet(url, {
        lazy: true,
        stateless: true,
        ...config,
    });

    const { showAxiosErrorMessage } = useErrorNotification();
    const [data, setData] = useState<TData>();
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (enableErrorHandler && error) {
            showAxiosErrorMessage(error);
            setError(undefined);
        }
    }, [enableErrorHandler, error, showAxiosErrorMessage]);

    useEffect(() => {
        setError(useGetRef.current.error);
    }, [useGetRef.current.error]);

    const fetch = useCallback(
        async (args?: FetchArgs<TFetchParams>) => {
            setLoading(true);
            setData(undefined);
            try {
                const response = await useGetRef.current.fetch({
                    params: args?.params,
                });

                setData(response.data);
                return response;
            } catch (error: any) {
                console.error(error);
                setError(error);
                if (!enableErrorHandler) return Promise.reject(error);
            } finally {
                setLoading(false);
            }
        },
        [enableErrorHandler],
    );

    return {
        data: data,
        error: error,
        fetch: fetch,
        loading: loading,
    };
};
