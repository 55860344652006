import React, {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useRef,
} from "react";
import { DEFAULT_APP_REGISTRY_ID } from "../../config";
import { useGetRegistryApiServices } from "../usecases/use-get-registry-api-services/useGetRegistryApiServices";

const EXCLUDED_URNS = [DEFAULT_APP_REGISTRY_ID];
export interface TaxSubmissionContextType {
    isUrnValid: (ownerAppUrn: string) => boolean;
    updateMap: (ownerAppUrn: string) => Promise<void>;
}

export const TaxSubmissionContext = createContext<TaxSubmissionContextType>({
    isUrnValid: () => false,
    updateMap: () => Promise.resolve(),
});

export const TaxSubmissionProviderFactory: React.FC<{
    children: ReactNode;
}> = ({ children }) => {
    const { checkUrnValidity } = useGetRegistryApiServices();

    const urnValidityMap = useRef(new Map<string, boolean | null>());

    const isUrnValid = (ownerAppUrn: string) => {
        return urnValidityMap.current.get(ownerAppUrn) || false;
    };

    const updateMap = useCallback(
        () => async (ownerAppUrn: string) => {
            const isUrnValid = urnValidityMap.current.get(ownerAppUrn);
            if (isUrnValid === undefined) {
                urnValidityMap.current.set(ownerAppUrn, null); // set to null to avoid concurrency issues
                urnValidityMap.current.set(
                    ownerAppUrn,
                    EXCLUDED_URNS.includes(ownerAppUrn)
                        ? false
                        : await checkUrnValidity(ownerAppUrn),
                );
            }
        },
        [checkUrnValidity],
    );

    return (
        <TaxSubmissionContext.Provider
            value={{ isUrnValid, updateMap: updateMap() }}
        >
            {children}
        </TaxSubmissionContext.Provider>
    );
};

export const useTaxSubmissionContext = () => {
    const context = useContext(TaxSubmissionContext);
    if (!context) {
        throw new Error(
            "useTaxSubmissionContext must be used within a TaxSubmissionProvider",
        );
    }
    return context;
};
