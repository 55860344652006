import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import { Button, Stack, useTheme } from "@vapor/react-material";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { TaxSubmissionStatus } from "../../../../../core/dtos/TaxSubmissionStatus";
import { useGetKPIs } from "../../../../../core/usecases/use-get-kpis/useGetKPIs";
import { TaxSubmissionGenericErrorKPICard } from "../tax-submission-generic-error-kpi-card/TaxSubmissionGenericErrorKPICard";
import { TaxSubmissionsTableHeaderKPICard } from "../tax-submissions-table-header-kpi-card/TaxSubmissionsTableHeaderKPICard";

interface TaxSubmissionsTableHeaderKPIsProps {
    accordionRight?: JSX.Element;
    deadlineFrom: Date;
    deadlineTo: Date;
    kpis: TaxSubmissionStatus[];
    onChangeSelectedKPIs: (selectedKPIs: TaxSubmissionStatus[]) => unknown;
}

export const TaxSubmissionsTableHeaderKPIs = ({
    accordionRight,
    deadlineFrom,
    deadlineTo,
    kpis,
    onChangeSelectedKPIs,
}: TaxSubmissionsTableHeaderKPIsProps): ReactElement => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();

    const [openKPIs, setOpenKPIs] = useState(true);

    const { fetch, loading, data } = useGetKPIs();

    useEffect(() => {
        fetch({
            params: { deadlineFrom: deadlineFrom, deadlineTo: deadlineTo },
        });
    }, [deadlineFrom, deadlineTo, fetch]);

    const handleToggleKPIs = useCallback(() => {
        setOpenKPIs((previous) => !previous);
    }, []);

    const handleClickKPI = useCallback(
        (status: TaxSubmissionStatus) => {
            const newSelectedKPIs = [...kpis];
            const statusIndex = newSelectedKPIs.indexOf(status);

            if (statusIndex === -1) {
                newSelectedKPIs.push(status);
            } else {
                newSelectedKPIs.splice(statusIndex, 1);
            }

            onChangeSelectedKPIs(newSelectedKPIs);
        },
        [kpis, onChangeSelectedKPIs],
    );

    const genericErrorsCount = useMemo(
        () =>
            data?.find(
                (kpi) => kpi.status === TaxSubmissionStatus.GENERIC_ERROR,
            )?.numberOfTaxSubmissions || 0,
        [data],
    );

    return (
        <>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
            >
                <Stack direction="row" spacing={2}>
                    <Button
                        startIcon={
                            <VaporIcon
                                color="info"
                                icon={openKPIs ? faAngleUp : faAngleDown}
                            />
                        }
                        onClick={handleToggleKPIs}
                    >
                        <LoadingWrapper
                            loading={loading}
                            spinnerProps={{ loadingText: "" }}
                            variant="spinner"
                        >
                            <Typography
                                color={theme.palette.primary.textTitleColor}
                                variant="bodyLarge500"
                            >
                                {data
                                    ?.map((kpi) => kpi.numberOfTaxSubmissions)
                                    .reduce((acc, curr) => acc + curr, 0) ?? 0}
                            </Typography>
                        </LoadingWrapper>
                        <Typography
                            color={theme.palette.primary.textTitleColor}
                            ml={1}
                            variant="bodyLarge"
                        >
                            {t(
                                "views.home.components.tax_submissions_table_header.kpis.accordion",
                                {
                                    defaultValue:
                                        "Forniture telematiche generate",
                                },
                            )}
                        </Typography>
                    </Button>
                </Stack>
                {accordionRight}
            </Stack>
            {openKPIs && (
                <Stack direction="row" spacing={2} alignItems="end">
                    {genericErrorsCount > 0 && (
                        <TaxSubmissionGenericErrorKPICard
                            isSelected={
                                kpis.indexOf(
                                    TaxSubmissionStatus.GENERIC_ERROR,
                                ) > -1
                            }
                            loading={loading}
                            numberOfTaxSubmissions={genericErrorsCount}
                            onClickKPI={(status) => handleClickKPI(status)}
                        />
                    )}
                    {[
                        TaxSubmissionStatus.REJECTED,
                        TaxSubmissionStatus.NOT_SENT_WITH_ERRORS,
                        TaxSubmissionStatus.SENT_WITH_WARNINGS,
                        TaxSubmissionStatus.NOT_SENT,
                        TaxSubmissionStatus.PENDING,
                        TaxSubmissionStatus.SENT,
                    ].map((status) => {
                        const numberOfTaxSubmissions =
                            data?.find((kpi) => kpi.status === status)
                                ?.numberOfTaxSubmissions || 0;

                        return (
                            <TaxSubmissionsTableHeaderKPICard
                                taxSubmissionStatus={status}
                                isSelected={kpis.indexOf(status) > -1}
                                loading={loading}
                                key={status}
                                numberOfTaxSubmissions={numberOfTaxSubmissions}
                                onClickKPI={(status) => handleClickKPI(status)}
                            />
                        );
                    })}
                </Stack>
            )}
        </>
    );
};
