import {
    faMagnifyingGlassArrowRight,
    faShareAll,
} from "@fortawesome/pro-light-svg-icons";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { EllipsisVertical, VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Button,
    CircularProgress,
    Menu,
    MenuItem,
    useTheme,
} from "@vapor/react-material";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTaxSubmissionContext } from "../../../../../core/contexts/TaxSubmissionContext";
import { TaxSubmissionDetailDTO } from "../../../../../core/dtos/TaxSubmissionDetailDTO";
import { TaxSubmissionStatus } from "../../../../../core/dtos/TaxSubmissionStatus";
import { useErrorNotification } from "../../../../../core/hooks/useErrorNotification";
import { useSuccessNotification } from "../../../../../core/hooks/useSuccessNotification";
import { useRevokeTaxSubmissions } from "../../../../../core/usecases/use-revoke-tax-submissions/useRevokeTaxSubmissions";
import { useSendRejectedTaxSubmission } from "../../../../../core/usecases/use-send-rejected-tax-submission/useSendRejectedTaxSubmission";
import { SendRejectedTaxSubmissionModal } from "../send-rejected-tax-submission-modal/SendRejectedTaxSubmissionModal";
import { createNavigationUrl, processUrn } from "../../../../utils/resolveUrn";

export interface ButtonOption {
    icon: IconDefinition;
    label: string;
    onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => unknown;
}

interface TaxSubmissionDetailActionsProps {
    taxSubmissionDetail: TaxSubmissionDetailDTO;
    revokeTaxSubmissionEnabled?: boolean;
    onTaxSubmissionUpdate?: () => unknown;
    taxPayersToRevoke: { protocol: string; taxId: string }[];
}

export const TaxSubmissionDetailActions: React.FC<
    TaxSubmissionDetailActionsProps
> = ({
    taxSubmissionDetail,
    revokeTaxSubmissionEnabled,
    onTaxSubmissionUpdate = () => {},
    taxPayersToRevoke,
}) => {
    const { showTextMessage: showErrorMessage } = useErrorNotification();
    const { showTextMessage: showSuccessMessage } = useSuccessNotification();
    const { t } = useTranslation("government-agency-hub-app");
    const navigate = useNavigate();
    const theme = useTheme();

    const { isUrnValid } = useTaxSubmissionContext();
    const { loading, revoke } = useRevokeTaxSubmissions({
        taxSubmissionId: taxSubmissionDetail.id,
    });
    const { loading: loadingSendRejectedTaxSubmission, sendRejected } =
        useSendRejectedTaxSubmission();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [sendRejectedModalOpen, setSendRejectedModalOpen] = useState(false);

    const getGoToProcedureButton = useCallback(
        (ownerAppUrn: string, taxSubmissionPath?: string) => {
            const icon: IconDefinition = faMagnifyingGlassArrowRight;
            const label: string = t(
                "views.home.components.tax_submissions_table.actions.go_to_procedure",
                {
                    defaultValue: "Vai alla procedura",
                },
            );
            const onClick = (
                _: React.MouseEvent<HTMLLIElement, MouseEvent>,
            ) => {
                if (taxSubmissionPath) {
                    navigate(createNavigationUrl(taxSubmissionPath));
                } else {
                    navigate(createNavigationUrl(processUrn(ownerAppUrn)));
                }
            };
            return {
                icon: icon,
                label: label,
                onClick: onClick,
            };
        },
        [navigate, t],
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickSendRejected = async () => {
        if (!taxSubmissionDetail.id) return false;
        const response = await sendRejected(taxSubmissionDetail.id);
        onTaxSubmissionUpdate();
        return response;
    };

    const options = useMemo(() => {
        return taxSubmissionDetail &&
            isUrnValid(taxSubmissionDetail.ownerApp.urn)
            ? [
                  getGoToProcedureButton(
                      taxSubmissionDetail?.ownerApp.urn,
                      taxSubmissionDetail?.ownerApp.taxSubmissionPath,
                  ),
              ]
            : [];
    }, [getGoToProcedureButton, isUrnValid, taxSubmissionDetail]);

    return (
        <Box sx={{ display: "flex", gap: 2 }}>
            {taxSubmissionDetail.status === TaxSubmissionStatus.REJECTED && (
                <>
                    {!!options && options.length > 0 && (
                        <>
                            <Button onClick={handleClick} variant="outlined">
                                <EllipsisVertical />
                                <Typography marginLeft={2}>
                                    {t(
                                        "views.home.components.tax_submission_detail_outcome_actions.other_actions",
                                        {
                                            defaultValue: "Altre azioni",
                                        },
                                    )}
                                </Typography>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {options.map((option, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={option?.onClick}
                                    >
                                        <VaporIcon
                                            icon={option?.icon}
                                            color="interactive"
                                        />
                                        <Box sx={{ mr: 1 }}></Box>
                                        <Typography
                                            color={
                                                theme.palette.primary
                                                    .interactiveDefault
                                            }
                                        >
                                            {option?.label}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                    <SendRejectedTaxSubmissionModal
                        loading={loadingSendRejectedTaxSubmission}
                        onSend={onClickSendRejected}
                        open={sendRejectedModalOpen}
                        onClose={() => setSendRejectedModalOpen(false)}
                    />
                    <Button
                        startIcon={
                            <VaporIcon icon={faShareAll} color="white" />
                        }
                        color="primary"
                        variant="contained"
                        onClick={() => setSendRejectedModalOpen(true)}
                    >
                        {t(
                            "views.home.components.tax_submissions_table.send_rejected_tax_submission",
                            { defaultValue: "Reinvia" },
                        )}
                    </Button>
                </>
            )}
            {revokeTaxSubmissionEnabled && (
                <Button
                    color="primary"
                    disabled={taxPayersToRevoke.length === 0 || loading}
                    onClick={async () => {
                        const result = await revoke(taxPayersToRevoke);
                        if (result) {
                            showSuccessMessage(
                                t(
                                    "views.home.components.tax_submission_detail_actions.revoke_taxSubmission.actions.revoke.success",
                                    {
                                        defaultValue:
                                            "L'annullamento della fornitura è stato preso in carico",
                                    },
                                ),
                            );
                            onTaxSubmissionUpdate();
                        } else {
                            showErrorMessage(
                                t(
                                    "views.home.components.tax_submission_detail_actions.revoke_taxSubmission.actions.revoke.failed",
                                    {
                                        defaultValue:
                                            "C'è stato un errore con l'annullamento della fornitura",
                                    },
                                ),
                            );
                        }
                    }}
                    variant="contained"
                >
                    <Typography sx={{ ml: 1 }}>
                        {t(
                            "views.home.components.tax_submission_detail_actions.revoke_taxSubmission.actions.revoke.btn",
                            {
                                defaultValue:
                                    "Genera fornitura di annullamento",
                            },
                        )}
                    </Typography>

                    {loading && (
                        <CircularProgress
                            color="inherit"
                            sx={{ ml: 1 }}
                            size={16}
                        />
                    )}
                </Button>
            )}
        </Box>
    );
};
