import { OutcomeTypePrefix } from "../../core/dtos/FulfilmentDTO";

// TODO: aggiungere mapping per le altre forniture, e gestire nomenclatura comune
const OUTCOME_TYPE_TRANSLATION: Record<
    string,
    { outcomes: OutcomeTypePrefix[]; fallback: string }
> = {
    "views.home.components.tax_submission_detail.outcome.base": {
        outcomes: [
            OutcomeTypePrefix.F24,
            OutcomeTypePrefix.IVP,
            OutcomeTypePrefix.CIR,
        ],
        fallback: "ricevuta",
    },
    "views.home.components.tax_submission_detail.payment.base": {
        outcomes: [OutcomeTypePrefix.I24],
        fallback: "quietanza",
    },
    "views.home.components.tax_submission_detail.suspension.base": {
        outcomes: [OutcomeTypePrefix.G24],
        fallback: "esito di sospensione",
    },
    "views.home.components.tax_submission_detail.first_outcome.base": {
        outcomes: [OutcomeTypePrefix.CLR],
        fallback: "primo esito",
    },
    "views.home.components.tax_submission_detail.second_outcome.base": {
        outcomes: [OutcomeTypePrefix.CMR],
        fallback: "secondo esito",
    },
};

type ActionType = "download" | "preview";

export const getTranslation = (
    outcomeType: string,
    action: ActionType,
    t: any,
) => {
    const translation = getTranslationKey(outcomeType);

    return t(translation, {
        defaultValue:
            action === "download"
                ? `Scarica ${translation.fallback}`
                : `Visualizza ${translation.fallback}`,
        action:
            action === "download"
                ? t(
                      "views.home.components.tax_submission_detail.outcome.download",
                  )
                : t(
                      "views.home.components.tax_submission_detail.outcome.preview",
                  ),
    });
};

export const getSummaryTranslation = (
    outcomeType: string,
    t: (key: any, options?: any) => string,
) => {
    const translation = getTranslationKey(outcomeType);

    return t(translation, {
        defaultValue: `Scarica riepilogo ${translation.fallback}`,
        action: t(
            "views.home.components.tax_submission_detail.outcome_summary.download",
        ),
    });
};

export const groupOutcomeTypes = (outcomeTypes: string[]) => {
    return outcomeTypes.reduce((accumulator: string[], current: string) => {
        const outcomeGroup = current.substring(0, 3);
        if (!accumulator.includes(outcomeGroup)) accumulator.push(outcomeGroup);
        return accumulator;
    }, []);
};

const getTranslationKey = (outcomeType: string) => {
    const outcomeTypePrefix = (
        outcomeType.length === 3 ? outcomeType : outcomeType.substring(0, 3)
    ) as OutcomeTypePrefix;

    let translation = { key: "", fallback: "" };
    Object.keys(OUTCOME_TYPE_TRANSLATION).forEach((key) => {
        if (OUTCOME_TYPE_TRANSLATION[key].outcomes.includes(outcomeTypePrefix))
            translation = {
                key,
                fallback: OUTCOME_TYPE_TRANSLATION[key].fallback,
            };
    });

    return translation;
};
