import { ReactElement, useCallback, useEffect, useState } from "react";
import { NotificationInline, Stack } from "@vapor/react-material";
import { useGetExpiringIdentities } from "../../../../../core/usecases/use-get-expiring-identities/useGetExpiringIdentities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleExclamation,
    faCircleMinus,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import dayjs from "dayjs";
import { WarningNotificationContent } from "./WarningNotificationContent";
import {
    ExpiringDelegated,
    ExpiringIntermediary,
    ExpiringSecurityEnvironment,
} from "../../../../../core/dtos/ExpiringIdentitiesDTO";

export const ExpiringIdentitiesWarnings = (): ReactElement => {
    const { t } = useTranslation("government-agency-hub-app");
    const { data: expiringIdentities, fetch: fetchExpiringIdentities } =
        useGetExpiringIdentities();

    const [expiredSubjects, setExpiredSubjects] = useState<
        ExpiringIntermediary[] | ExpiringDelegated[] | undefined
    >();
    const [expiringSubjects, setExpiringSubjects] = useState<
        ExpiringIntermediary[] | ExpiringDelegated[] | undefined
    >();
    const [expiredSecurityEnvironments, setExpiredSecurityEnvironments] =
        useState<ExpiringSecurityEnvironment[] | undefined>();
    const [expiringSecurityEnvironments, setExpiringSecurityEnvironments] =
        useState<ExpiringSecurityEnvironment[] | undefined>();

    useEffect(() => {
        fetchExpiringIdentities();
    }, [fetchExpiringIdentities]);

    useEffect(() => {
        if (!expiringIdentities) return;
        setExpiredSubjects(
            expiringIdentities.intermediaries
                .filter((intermediary) =>
                    dayjs(intermediary.expirationDate).isBefore(dayjs()),
                )
                .concat(
                    expiringIdentities.delegates.filter((delegated) =>
                        dayjs(delegated.expirationDate).isBefore(dayjs()),
                    ),
                ),
        );
    }, [expiringIdentities]);

    useEffect(() => {
        if (!expiringIdentities) return;
        setExpiringSubjects(
            expiringIdentities.intermediaries
                .filter((intermediary) =>
                    dayjs(intermediary.expirationDate).isAfter(dayjs()),
                )
                .concat(
                    expiringIdentities.delegates.filter((delegated) =>
                        dayjs(delegated.expirationDate).isAfter(dayjs()),
                    ),
                ),
        );
    }, [expiringIdentities]);

    useEffect(() => {
        if (!expiringIdentities) return;
        setExpiredSecurityEnvironments(
            expiringIdentities.securityEnvironments.filter(
                (securityEnvironment) =>
                    dayjs(securityEnvironment.expirationDate).isBefore(dayjs()),
            ),
        );
    }, [expiringIdentities]);

    useEffect(() => {
        if (!expiringIdentities) return;
        setExpiringSecurityEnvironments(
            expiringIdentities.securityEnvironments.filter(
                (securityEnvironment) =>
                    dayjs(securityEnvironment.expirationDate).isAfter(
                        dayjs(),
                    ) ||
                    dayjs(securityEnvironment.expirationDate).isSame(
                        dayjs(),
                        "day",
                    ),
            ),
        );
    }, [expiringIdentities]);

    const handleExpiredSubjectsOnDismiss = useCallback(() => {
        setExpiredSubjects(undefined);
    }, []);

    const handleExpiringSubjectsOnDismiss = useCallback(() => {
        setExpiringSubjects(undefined);
    }, []);

    const handleExpiredSecurityEnvironmentOnDismiss = useCallback(() => {
        setExpiredSecurityEnvironments(undefined);
    }, []);

    const handleExpiringSecurityEnvironmentOnDismiss = useCallback(() => {
        setExpiringSecurityEnvironments(undefined);
    }, []);

    return (
        <Stack gap={1}>
            {expiredSubjects && expiredSubjects.length > 0 && (
                <NotificationInline
                    icon={<FontAwesomeIcon icon={faCircleMinus} />}
                    severity="error"
                    variant="outlined"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <WarningNotificationContent
                        variant="expiredCredentials"
                        identities={expiredSubjects}
                        onDismiss={handleExpiredSubjectsOnDismiss}
                    >
                        <Typography variant="body">
                            {t(
                                "views.home.components.tax_submissions_table_header.expiringIdentities.credentials.expired",
                                {
                                    defaultValue:
                                        "Attenzione: credenziali Entratel scadute!",
                                },
                            )}
                        </Typography>
                    </WarningNotificationContent>
                </NotificationInline>
            )}
            {expiredSecurityEnvironments &&
                expiredSecurityEnvironments.length > 0 && (
                    <NotificationInline
                        icon={<FontAwesomeIcon icon={faCircleMinus} />}
                        severity="error"
                        variant="outlined"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <WarningNotificationContent
                            variant="expiredSecurityEnvironments"
                            identities={expiredSecurityEnvironments}
                            onDismiss={
                                handleExpiredSecurityEnvironmentOnDismiss
                            }
                        >
                            <Typography variant="body">
                                {t(
                                    "views.home.components.tax_submissions_table_header.expiringIdentities.securityEnvironment.expired",
                                    {
                                        defaultValue:
                                            "Attenzione: ambiente di sicurezza scaduto!",
                                    },
                                )}
                            </Typography>
                        </WarningNotificationContent>
                    </NotificationInline>
                )}
            {expiringSubjects && expiringSubjects.length > 0 && (
                <NotificationInline
                    icon={<FontAwesomeIcon icon={faCircleExclamation} />}
                    severity="warning"
                    variant="outlined"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <WarningNotificationContent
                        variant="expiringCredentials"
                        identities={expiringSubjects}
                        onDismiss={handleExpiringSubjectsOnDismiss}
                    >
                        <Typography variant="body">
                            {t(
                                "views.home.components.tax_submissions_table_header.expiringIdentities.credentials.expiring",
                                {
                                    defaultValue:
                                        "Attenzione: credenziali Entratel in scadenza!",
                                },
                            )}
                        </Typography>
                    </WarningNotificationContent>
                </NotificationInline>
            )}
            {expiringSecurityEnvironments &&
                expiringSecurityEnvironments.length > 0 && (
                    <NotificationInline
                        icon={<FontAwesomeIcon icon={faCircleExclamation} />}
                        severity="warning"
                        variant="outlined"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <WarningNotificationContent
                            variant="expiringSecurityEnvironments"
                            identities={expiringSecurityEnvironments}
                            onDismiss={
                                handleExpiringSecurityEnvironmentOnDismiss
                            }
                        >
                            <Typography variant="body">
                                {t(
                                    "views.home.components.tax_submissions_table_header.expiringIdentities.securityEnvironment.expiring",
                                    {
                                        defaultValue:
                                            "Attenzione: ambiente di sicurezza in scadenza!",
                                    },
                                )}
                            </Typography>
                        </WarningNotificationContent>
                    </NotificationInline>
                )}
        </Stack>
    );
};
