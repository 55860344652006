import pkg from "../package.json";

export const BASE_APP_NAME = pkg.name;
export const APP_VERSION = pkg.version;

export const DEFAULT_APP_REGISTRY_ID = "rnd:tax:government-agency-hub:fe";

export const NODE_ENV = process.env.NODE_ENV;

// Use dev configs only in the developer's local environment
export const IS_DEV_ENV = process.env.NODE_ENV !== "production";

export const ADE_URL = process.env.REACT_APP_ADE_URL;

export const REACT_APP_ENV = process.env.REACT_APP_ENV;
