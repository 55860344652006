import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { useErrorNotification } from "../../hooks/useErrorNotification";

interface UploadTaxSubmissionPayload {
    deadline?: string;
    delegatedTaxId?: string;
    fileContent: string;
    fileName: string;
    intermediaryTaxId: string;
    intermediarySiteCode?: string;
    levy?: { levyId: string; accrualYear: number };
    uploadFlow: "ADE" | "SDI" | "TS";
}

export function useUploadTaxSubmission(): {
    error?: AxiosError;
    loading: boolean;
    uploadTaxSubmission: (
        payload: UploadTaxSubmissionPayload,
    ) => Promise<boolean>;
} {
    const { tenant } = useCurrentTenant(true);
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState(false);
    const config = useGetAxiosRequestConfigParams("use-upload-tax-submissions");
    const { showAxiosErrorMessage } = useErrorNotification();

    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const upload = useCallback(
        async (payload: UploadTaxSubmissionPayload) => {
            setLoading(true);
            try {
                await useRequestRef.current.fetch({
                    ...config,
                    data: {
                        deadline: payload.deadline,
                        delegatedTaxId: payload.delegatedTaxId,
                        fileContent: payload.fileContent,
                        fileName: payload.fileName,
                        intermediaryTaxId: payload.intermediaryTaxId,
                        intermediarySiteCode: payload.intermediarySiteCode,
                        levy: payload.levy,
                        uploadFlow: payload.uploadFlow,
                    },
                    lazy: true,
                    method: "post",
                    stateless: true,
                    url: `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/tax-submissions/upload`,
                });
                return true;
            } catch (error: any) {
                setError(error);
                return false;
            } finally {
                setLoading(false);
            }
        },
        [config, tenant?.id],
    );

    useEffect(() => {
        if (error) showAxiosErrorMessage(error);
    }, [error, showAxiosErrorMessage]);

    return {
        error: error,
        loading: loading,
        uploadTaxSubmission: upload,
    };
}
