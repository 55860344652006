import { FulfilmentSchemaDTO } from "./TaxSubmissionDTO";
import { TaxSubmissionStatus } from "./TaxSubmissionStatus";
import { FulfilmentDTO } from "./FulfilmentDTO";
import { Operator } from "./SubjectsDto";
import { OwnerApp } from "./OwnerApp";

export enum IntermediaryType {
    COMPANY = "C",
    PERSON = "P",
}

export enum GovernmentAgencyType {
    ade = "ADE",
    sdi = "SDI",
    inps = "INPS",
    tesseraSanitaria = "TS",
}

export interface Intermediary {
    taxId: string;
    siteCode: string;
    type: IntermediaryType;
}

export interface GovernmentAgency {
    type: GovernmentAgencyType;
    sendDate: string | undefined;
    id: string | undefined;
}

export interface Revocation {
    revokes?: string | undefined;
    revokedBy?: string | undefined;
}

export enum ActionType {
    SEND_AWAITING = "SEND_AWAITING",
    SEND_REJECTED = "SEND_REJECTED",
    UPDATE_AGENCY_ID = "UPDATE_AGENCY_ID",
    UPDATE_DELEGATED = "UPDATE_DELEGATED",
}

export type Action = {
    type: ActionType;
    date: Date;
    operator: Operator;
};

export interface TaxSubmissionDetailDTO {
    id: string;
    type: string;
    creationDate: string;
    operatorGivenName: string | undefined;
    operatorFamilyName: string | undefined;
    intermediary: Intermediary;
    delegatedTaxId: string | undefined;
    fulfilmentsErrors: number;
    fulfilmentSchema: FulfilmentSchemaDTO;
    fulfilments: FulfilmentDTO[];
    isRevocable: boolean;
    isAgencyIdEditable: boolean;
    isDelegatedEditable: boolean;
    governmentAgency: GovernmentAgency;
    status: TaxSubmissionStatus;
    outcomeTypes: string[];
    revocation?: Revocation | undefined;
    actions?: Action[] | undefined;
    ownerApp: OwnerApp;
}
