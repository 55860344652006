export enum PersonType {
    COMPANY = "C",
    PERSON = "P",
}

export interface Intermediary {
    name: string;
    sites?: {
        code: string;
        name: string;
    }[];
    taxId: string;
    type: PersonType;
}

export interface Fulfilment {
    accrualYear: number;
    deadline: string;
    levyId: string;
    name: string;
    period: string;
}

export interface ParsedTaxSubmissionDTO {
    fileType: string;
    flow: "ADE" | "SDI" | "TS";
    fulfilments: Fulfilment[];
    intermediaries: Intermediary[];
}
