import { useTranslation } from "@onefront/react-sdk";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { MenuItem, TextField } from "@vapor/react-material";
import { useEffect, useState } from "react";
import { DelegatedDTO } from "../../../core/dtos/DelegatedDTO";
import { useErrorNotification } from "../../../core/hooks/useErrorNotification";
import { useSuccessNotification } from "../../../core/hooks/useSuccessNotification";
import { useGetDelegated } from "../../../core/usecases/use-get-delegated/useGetDelegated";
import { useUpdateDelegated } from "../../../core/usecases/use-update-delegated/useUpdateDelegated";

interface DelegatedUpdaterProps {
    taxSubmissionId: string;
    intermediaryTaxId: string;
    delegatedTaxId: string | undefined;
    onDelegatedUpdate?: (delegatedTaxId: string) => void;
}

export const DelegatedUpdater: React.FC<DelegatedUpdaterProps> = ({
    taxSubmissionId,
    intermediaryTaxId,
    delegatedTaxId,
    onDelegatedUpdate,
}) => {
    const [delegates, setDelegates] = useState<DelegatedDTO[]>([]);
    const [selectedDelegated, setSelectedDelegated] = useState<string>(
        delegatedTaxId || "placeholder",
    );

    const { t } = useTranslation("government-agency-hub-app");
    const { showTextMessage: showSuccessMessage } = useSuccessNotification();
    const { showTextMessage: showErrorMessage } = useErrorNotification();
    const { loading: loadingDelegates, fetch } = useGetDelegated({
        intermediaryTaxId,
    });
    const { loading: updatingDelegated, updateDelegated } = useUpdateDelegated({
        taxSubmissionId,
    });

    useEffect(() => {
        const fetchDelegates = async () => {
            const { data: delegates } = await fetch();
            setDelegates(delegates);
        };
        fetchDelegates();
    }, [fetch]);

    const handleDelegatedChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const success = await updateDelegated(event.target.value);
        if (success) {
            showSuccessMessage(
                t("components.delegated_updater.success", {
                    defaultValue: "Incaricato aggiornato correttamente",
                }),
            );
            setSelectedDelegated(event.target.value);
            if (!!onDelegatedUpdate) onDelegatedUpdate(event.target.value);
        } else {
            showErrorMessage(
                t("components.delegated_updater.fail", {
                    defaultValue: "Errore aggiornamento incaricato",
                }),
            );
        }
    };

    return (
        <LoadingWrapper
            loading={loadingDelegates || updatingDelegated}
            spinnerProps={{ loadingText: "" }}
            variant="spinner"
        >
            {delegates.length > 0 && (
                <TextField
                    name="selectedDelegated"
                    onChange={handleDelegatedChange}
                    select
                    sx={{ minWidth: 180 }}
                    value={selectedDelegated}
                >
                    {selectedDelegated === "placeholder" && (
                        <MenuItem
                            disabled
                            key="placeholder"
                            value="placeholder"
                        >
                            {t(
                                "components.delegated_updater.select.placeholder",
                                {
                                    defaultValue: "Seleziona incaricato",
                                },
                            )}
                        </MenuItem>
                    )}
                    {delegates.map((delegate) => (
                        <MenuItem key={delegate.taxId} value={delegate.taxId}>
                            {delegate.firstName && delegate.lastName
                                ? `${delegate.firstName} ${delegate.lastName}`
                                : delegate.taxId}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            {delegates.length === 0 && (
                <Typography variant="body">
                    {t(
                        "components.delegated_updater.select.no_available_delegated",
                        {
                            defaultValue: "Nessun incaricato disponibile",
                        },
                    )}
                </Typography>
            )}
        </LoadingWrapper>
    );
};
