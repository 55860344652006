import { createContext, useContext } from "react";
import { SubscriptionDto } from "../dtos/SubscriptionDto";

interface UserContextType {
    subscriptions: SubscriptionDto[];
}

export const UserContext = createContext<UserContextType>({
    subscriptions: [],
});

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
};
