import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Link,
    Stack,
    useTheme,
} from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTranslation } from "@onefront/react-sdk";
import { useState } from "react";
import {
    faArrowUpRightFromSquare,
    faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { useSuccessNotification } from "../../../../../core/hooks/useSuccessNotification";
import { useErrorNotification } from "../../../../../core/hooks/useErrorNotification";

interface SendRejectedTaxSubmissionModalProps {
    loading: boolean;
    open: boolean;
    onClose: () => void;
    onSend: () => Promise<boolean>;
}

export const SendRejectedTaxSubmissionModal: React.FC<
    SendRejectedTaxSubmissionModalProps
> = ({ loading, open, onClose, onSend }) => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();
    const { showTextMessage: showSuccessNotification } =
        useSuccessNotification();
    const { showTextMessage: showErrorNotification } = useErrorNotification();

    const [confirmSendRejected, setConfirmSendRejected] = useState(false);

    const handleOnClose = () => {
        onClose();
        setConfirmSendRejected(false);
    };

    const handleOnSend = async () => {
        const success = await onSend();
        if (success) {
            showSuccessNotification(
                t(
                    "views.home.components.tax_submissions_table.actions.send_rejected_tax_submission.modal.success",
                    {
                        defaultValue:
                            "Invio fornitura: operazione presa in carico correttamente",
                    },
                ),
            );
            handleOnClose();
        } else {
            showErrorNotification(
                t(
                    "views.home.components.tax_submissions_table.actions.send_rejected_tax_submission.modal.error",
                    {
                        defaultValue: "Invio fornitura: operazione fallita",
                    },
                ),
            );
        }
    };

    return (
        <Dialog open={open} onClose={handleOnClose}>
            <DialogTitle>
                <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        width: "100%",
                    }}
                >
                    <Typography
                        color={theme.palette.primary.textTitleColor}
                        variant="bodyLarge"
                    >
                        {t(
                            "views.home.components.tax_submissions_table.actions.send_rejected_tax_submission.modal.title",
                            { defaultValue: "Attenzione" },
                        )}
                    </Typography>
                    <FontAwesomeIcon
                        icon={faXmark}
                        onClick={handleOnClose}
                        style={{ cursor: "pointer" }}
                    />
                </Stack>
            </DialogTitle>
            <Divider variant="fullWidth" />
            <DialogContent>
                <Stack
                    justifyContent="space-between"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography>
                        {t(
                            "views.home.components.tax_submissions_table.actions.send_rejected_tax_submission.modal.description",
                            {
                                defaultValue:
                                    "Stai reinviando una fornitura. Per evitare un doppio invio o un doppio addebito verifica che la fornitura non sia già presente nell'area riservata dell'Agenzia delle Entrate.",
                            },
                        )}
                    </Typography>
                    <Link
                        href="https://iampe.agenziaentrate.gov.it/sam/UI/Login?realm=/agenziaentrate"
                        target="_blank"
                        rel="noopener"
                        underline="none"
                        sx={{
                            width: "fit-content",
                            border: `1px solid ${theme.palette.primary.cyanProgress}`,
                            py: 0.5,
                            px: 1,
                        }}
                    >
                        <Stack flexDirection="row" gap={1} alignItems="center">
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                            <Typography>
                                {t(
                                    "views.home.components.tax_submissions_table.actions.send_rejected_tax_submission.modal.entratel",
                                    { defaultValue: "Vai a entratel" },
                                )}
                            </Typography>
                        </Stack>
                    </Link>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={confirmSendRejected}
                                onChange={(_, checked) =>
                                    setConfirmSendRejected(checked)
                                }
                            />
                        }
                        label={t(
                            "views.home.components.tax_submissions_table.actions.send_rejected_tax_submission.modal.checkbox",
                            {
                                defaultValue: "Ho confermato e proseguo",
                            },
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleOnClose}>
                    {t(
                        "views.home.components.tax_submissions_table.actions.send_rejected_tax_submission.modal.back",
                        { defaultValue: "Esci" },
                    )}
                </Button>
                <Button
                    variant="contained"
                    disabled={!confirmSendRejected || loading}
                    onClick={handleOnSend}
                >
                    {t(
                        "views.home.components.tax_submissions_table.actions.send_rejected_tax_submission.modal.confirm",
                        { defaultValue: "Prosegui" },
                    )}
                    {loading && (
                        <CircularProgress
                            color="inherit"
                            sx={{ ml: 1 }}
                            size={16}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
