import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDTO } from "../../dtos/BaseUseCaseResponseDTO";
import { TaxSubmissionDetailDTO } from "../../dtos/TaxSubmissionDetailDTO";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

interface useGetTaxSubmissionDetailProps {
    id: string;
}

export function useGetTaxSubmissionDetail(
    input: useGetTaxSubmissionDetailProps,
): BaseUseCaseResponseDTO<TaxSubmissionDetailDTO> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } =
        useGetWithErrorHandler<TaxSubmissionDetailDTO>(
            `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/tax-submissions/${input.id}/details`,
            {
                ...useGetAxiosRequestConfigParams(
                    "use-get-tax-submission-detail",
                ),
            },
        );

    return {
        data: data,
        error: error,
        fetch: fetch,
        loading: loading,
    };
}
