import {
    IconDefinition,
    faCircleExclamation,
    faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack, useTheme } from "@vapor/react-material";
import { TaxSubmissionStatus } from "../../../core/dtos/TaxSubmissionStatus";

interface TaxSubmissionStatusAlertProps {
    status: TaxSubmissionStatus;
}
export const TaxSubmissionStatusAlert: React.FC<
    TaxSubmissionStatusAlertProps
> = ({ status }): JSX.Element => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();

    let color: string;
    let icon: IconDefinition | undefined;
    let text: string | undefined;

    switch (status) {
        case TaxSubmissionStatus.GENERIC_ERROR:
            color = theme.palette.primary.accentRustyRed;
            icon = faCircleExclamation;
            text = t("components.tax_submission_status_alert.generic_error", {
                defaultValue: "Errore generico",
            });
            break;
        case TaxSubmissionStatus.NOT_SENT_WITH_ERRORS:
            color = theme.palette.primary.accentRustyRed;
            icon = faCircleExclamation;
            text = t(
                "components.tax_submission_status_alert.not_sent_with_errors",
                {
                    defaultValue: "con errori",
                },
            );
            break;
        case TaxSubmissionStatus.SENT_WITH_WARNINGS:
            color = theme.palette.primary.baseWarningColor;
            icon = faTriangleExclamation;
            text = t(
                "components.tax_submission_status_alert.sent_with_warnings",
                {
                    defaultValue: "con segnalazioni",
                },
            );
            break;
        default:
            color = theme.palette.primary.interactiveDefault;
            icon = undefined;
            text = undefined;
            break;
    }

    return icon && text ? (
        <Stack
            alignItems="center"
            direction="row"
            overflow="hidden"
            spacing={1}
            whiteSpace="nowrap"
        >
            <FontAwesomeIcon color={color} icon={icon} size="lg" />
            <Typography
                color={theme.palette.primary.textTitleColor}
                overflow="hidden"
                textOverflow="ellipsis"
                variant="subtitle"
            >
                {text}
            </Typography>
        </Stack>
    ) : (
        <></>
    );
};
