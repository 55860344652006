import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDTO } from "../../dtos/BaseUseCaseResponseDTO";
import { SubjectsDto } from "../../dtos/SubjectsDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";
import { useCallback } from "react";

interface ApiRequestParams {
    deadlineFrom: number;
    deadlineTo: number;
}

interface UseGetSubjectsProps {
    deadlineFrom: Date;
    deadlineTo: Date;
}

export function useGetSubjects(): BaseUseCaseResponseDTO<
    SubjectsDto,
    UseGetSubjectsProps
> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } = useGetWithErrorHandler<
        SubjectsDto,
        ApiRequestParams
    >(
        `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/subjects`,
        {
            ...useGetAxiosRequestConfigParams("use-get-subjects"),
        },
    );

    const fetchData = useCallback(
        async (args?: { params?: UseGetSubjectsProps }) => {
            return await fetch({
                params: {
                    deadlineFrom: args?.params?.deadlineFrom.getTime() || 0,
                    deadlineTo: args?.params?.deadlineTo.getTime() || 0,
                },
            });
        },
        [fetch],
    );

    return {
        data,
        error,
        loading,
        fetch: fetchData,
    };
}
