import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import { Button, ButtonGroup } from "@vapor/react-material";
import Box from "@vapor/react-material/Box";
import React, { useEffect, useState } from "react";
import { DocumentDownloadDTO } from "../../../../../core/dtos/DocumentDownloadDTO";
import { GovernmentAgencyType } from "../../../../../core/dtos/TaxSubmissionDetailDTO";
import { useDownloadTaxSubmissionOutcome } from "../../../../../core/usecases/use-download-tax-submission-outcome/useDownloadTaxSubmissionOutcome";
import { useDownloadTaxSubmissionPdfOutcome } from "../../../../../core/usecases/use-download-tax-submission-pdf-outcome/useDownloadTaxSubmissionPdfOutcome";
import { downloadFile } from "../../../../../core/utils/downloadFile";
import { TaxSubmissionOutcomePreviewer } from "../tax-submission-outcome-previewer/TaxSubmissionOutcomePreviewer";

interface TaxSubmissionOutcomePreviewProps {
    availableOutcomeTypes: string[];
    governmentAgencyType: GovernmentAgencyType;
    taxSubmissionId: string;
    selectedOutcomeType: string;
    taxPayer?: string;
}

export const TaxSubmissionOutcomePreview: React.FC<
    TaxSubmissionOutcomePreviewProps
> = ({
    availableOutcomeTypes,
    governmentAgencyType,
    taxSubmissionId,
    selectedOutcomeType,
    taxPayer,
}) => {
    const { t } = useTranslation("government-agency-hub-app");

    const [activeOutcomeType, setActiveOutcomeType] =
        useState<string>(selectedOutcomeType);

    const handleOutcomeClick = (outcomeType: string) => {
        setActiveOutcomeType(outcomeType);
    };

    const { download: downloadPdfOutcome } =
        useDownloadTaxSubmissionPdfOutcome();
    const { download: downloadOutcome } = useDownloadTaxSubmissionOutcome();

    const [resourceData, setResourceData] = useState<{
        content: Uint8Array;
        type: string;
    }>();

    useEffect(() => {
        setResourceData(undefined);
        const downloadResource = async () => {
            let data: DocumentDownloadDTO | undefined;
            if (governmentAgencyType === GovernmentAgencyType.ade) {
                data = await downloadPdfOutcome(
                    taxSubmissionId,
                    [activeOutcomeType],
                    taxPayer,
                );
            } else {
                data = await downloadOutcome(
                    taxSubmissionId,
                    activeOutcomeType,
                    taxPayer,
                );
            }

            if (!data) {
                console.error("could not download pdf document");
                return;
            }
            setResourceData({ content: data.content, type: data.type });
        };
        downloadResource();
    }, [
        activeOutcomeType,
        downloadOutcome,
        downloadPdfOutcome,
        governmentAgencyType,
        taxPayer,
        taxSubmissionId,
    ]);

    return (
        <Box>
            {availableOutcomeTypes.length > 1 && (
                <ButtonGroup
                    aria-label="outlined primary button group"
                    variant="contained"
                >
                    {availableOutcomeTypes.map((outcomeType, index) => (
                        <Button
                            key={index}
                            onClick={() => handleOutcomeClick(outcomeType)}
                            variant={
                                activeOutcomeType === outcomeType
                                    ? "outlined"
                                    : "contained"
                            }
                        >
                            {t(
                                `views.home.components.tax_submission_outcome_preview.outcome_types.${outcomeType
                                    .substring(0, 3)
                                    .toLowerCase()}`,
                                { defaultValue: "Ricevuta" },
                            )}
                        </Button>
                    ))}
                </ButtonGroup>
            )}
            {!resourceData ? (
                <LoadingWrapper
                    loading={!resourceData}
                    spinnerProps={{
                        loadingText: t(
                            "components.loading_wrapper.loading_message",
                            { defaultValue: "Attendere prego" },
                        ),
                    }}
                />
            ) : (
                <TaxSubmissionOutcomePreviewer
                    taxSubmissionId={taxSubmissionId}
                    governmentAgencyType={governmentAgencyType}
                    outcomeType={activeOutcomeType}
                    taxPayer={taxPayer}
                />
            )}
            <Box
                sx={{
                    ml: -4,
                }}
            >
                <VaporToolbar
                    contentRight={[
                        resourceData && (
                            <Button
                                size="large"
                                variant="contained"
                                onClick={() => {
                                    downloadFile(
                                        resourceData.content,
                                        `${activeOutcomeType}_${taxPayer}`,
                                        resourceData.type,
                                    );
                                }}
                            >
                                <VaporIcon icon={faDownload} color="inverse" />
                                <Typography style={{ marginLeft: "10px" }}>
                                    {t(
                                        "views.home.components.tax_submission_download_sogei_error.download_sogei_error",
                                        { defaultValue: "Scarica" },
                                    )}
                                </Typography>
                            </Button>
                        ),
                    ]}
                    size="large"
                    variant="regular"
                />
            </Box>
        </Box>
    );
};
