import { useTranslation } from "@onefront/react-sdk";
import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Button,
    CircularProgress,
    MenuItem,
    Stack,
    TextField,
} from "@vapor/react-material";

import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import {
    AdapterDateFns,
    DatePicker,
    LocalizationProvider,
} from "@vapor/react-x-date-pickers";
import it from "date-fns/locale/it";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
    Intermediary,
    PersonType,
} from "../../../../../core/dtos/ParsedTaxSubmissionDTO";
import { useSuccessNotification } from "../../../../../core/hooks/useSuccessNotification";
import { useGetDelegated } from "../../../../../core/usecases/use-get-delegated/useGetDelegated";
import { useUploadTaxSubmission } from "../../../../../core/usecases/use-upload-tax-submission/useUploadTaxSubmission";

enum TaxSubmissionType {
    F24,
    ANNULLAMENTO,
    OTHERS,
}
interface FormModel {
    deadline: Date;
    delegatedTaxId?: string;
    fileName: string;
    fileContent: string;
    intermediaryTaxId: string;
    intermediarySiteCode?: string;
    levy?: { accrualYear: number; levyId: string };
}

const formInitialModel: FormModel = {
    delegatedTaxId: "",
    intermediaryTaxId: "",
    intermediarySiteCode: "",
    fileName: "",
    fileContent: "",
    levy: { accrualYear: dayjs().year(), levyId: "" },
    deadline: dayjs().hour(12).toDate(),
};

function getTaxSubmissionTypeFromFileType(fileType: string) {
    switch (fileType) {
        case "F24A0":
            return TaxSubmissionType.F24;
        case "ANNXX":
            return TaxSubmissionType.ANNULLAMENTO;
        default:
            return TaxSubmissionType.OTHERS;
    }
}

interface TaxSubmissionUploadFormProps {
    fileContent: string;
    fileName: string;
    fileType: string;
    fulfilments: { accrualYear: number; levyId: string; name: string }[];
    intermediaries: Intermediary[];
    isTaxSubmissionUploading: (isUploading: boolean) => unknown;
    onClickCancelButton: () => unknown;
    onUploadTaxSubmission: () => unknown;
    uploadFlow: "ADE" | "SDI" | "TS";
}

export const TaxSubmissionUploadForm: React.FC<
    TaxSubmissionUploadFormProps
> = ({
    fileContent,
    fileName,
    fileType,
    fulfilments,
    intermediaries: intermediariesProp,
    isTaxSubmissionUploading,
    onClickCancelButton,
    onUploadTaxSubmission,
    uploadFlow,
}): JSX.Element => {
    const { t } = useTranslation("government-agency-hub-app");
    const { showTextMessage } = useSuccessNotification();

    // states -> taxSubmission type
    const [taxSubmissionType, setTaxSubmissionType] = useState(
        TaxSubmissionType.OTHERS,
    );

    // states -> form
    const [intermediaries, setIntermediaries] = useState<Intermediary[]>([]);

    const [sites, setSites] = useState<
        {
            siteCode: string;
            siteName: string;
        }[]
    >([]);

    const [delegated, setDelegated] = useState<
        {
            name: string;
            taxId: string;
            recommended?: boolean;
        }[]
    >([]);

    const [isSelectedDelegatedValid, setIsSelectedDelegatedValid] =
        useState(false);

    const [formModel, setFormModel] = useState<FormModel>(formInitialModel);

    // states -> checks
    const [isTaxSubmissionFileParsed, setIsTaxSubmissionFileParsed] =
        useState(false);
    const [canUploadTaxSubmission, setCanUploadTaxSubmission] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);

    // use cases
    const { loading: loadingUploadTaxSubmission, uploadTaxSubmission } =
        useUploadTaxSubmission();

    const { fetch: fetchGetDelegated, loading: loadingGetDelegated } =
        useGetDelegated({
            intermediaryTaxId: formModel.intermediaryTaxId,
        });

    // use effects
    useEffect(() => {
        if (
            fileContent &&
            fileName &&
            intermediariesProp &&
            intermediariesProp.length > 0 &&
            uploadFlow
        ) {
            setIntermediaries(intermediariesProp);
            setTaxSubmissionType(getTaxSubmissionTypeFromFileType(fileType));

            setFormModel((prevValues) => ({
                ...prevValues,
                fileContent: fileContent,
                fileName: fileName,
                levy:
                    fulfilments.length > 0
                        ? {
                              accrualYear: fulfilments[0].accrualYear,
                              levyId: fulfilments[0].levyId,
                          }
                        : undefined,
            }));

            setIsTaxSubmissionFileParsed(true);
        }

        return () => {
            setIsTaxSubmissionFileParsed(false);
            setFormModel(formInitialModel);
        };
    }, [
        fileContent,
        fileName,
        fileType,
        fulfilments,
        intermediariesProp,
        uploadFlow,
    ]);

    useEffect(() => {
        if (intermediaries && intermediaries.length > 0) {
            setFormModel((prevValues) => ({
                ...prevValues,
                intermediaryTaxId: intermediaries[0].taxId,
            }));
        }

        return () => {
            setFormModel((prevValues) => ({
                ...prevValues,
                intermediaryTaxId: "",
            }));
        };
    }, [intermediaries]);

    useEffect(() => {
        if (formModel.intermediaryTaxId) {
            const _setData = async () => {
                const intermediary = intermediaries?.filter(
                    (intermediary) =>
                        intermediary.taxId === formModel.intermediaryTaxId,
                )[0];

                if (intermediary) {
                    const sites =
                        intermediary.sites?.map((site) => {
                            return {
                                siteCode: site.code,
                                siteName: site.name,
                            };
                        }) || [];

                    setSites(sites);
                    setFormModel((prevValues) => ({
                        ...prevValues,
                        intermediarySiteCode: sites[0]?.siteCode || "",
                    }));

                    if (uploadFlow === "ADE") {
                        const delegated =
                            (await fetchGetDelegated())?.data ?? [];

                        const recommendedDelegated = delegated.find(
                            (delegate) => delegate.recommended,
                        );

                        setDelegated(
                            delegated.map((delegate) => {
                                return {
                                    name: `${delegate.firstName} ${delegate.lastName}`,
                                    taxId: delegate.taxId,
                                    recommended: delegate.recommended,
                                };
                            }),
                        );

                        setFormModel((prevValues) => ({
                            ...prevValues,
                            delegatedTaxId:
                                recommendedDelegated?.taxId ||
                                delegated[0]?.taxId ||
                                "",
                        }));
                    }
                }
            };
            _setData();
        }

        return () => {
            setDelegated([]);
            setSites([]);

            setFormModel((prevValues) => ({
                ...prevValues,
                intermediarySiteCode: undefined,
                delegatedTaxId: undefined,
            }));
        };
    }, [
        fetchGetDelegated,
        formModel.intermediaryTaxId,
        intermediaries,
        uploadFlow,
    ]);

    useEffect(() => {
        const selectedIntermediary = intermediaries.find(
            (intermediary) =>
                intermediary.taxId === formModel.intermediaryTaxId,
        );

        if (
            (selectedIntermediary &&
                selectedIntermediary.type === PersonType.PERSON) ||
            formModel.delegatedTaxId ||
            uploadFlow !== "ADE"
        ) {
            setIsSelectedDelegatedValid(true);
        }
        return () => {
            setIsSelectedDelegatedValid(false);
        };
    }, [
        formModel.delegatedTaxId,
        formModel.intermediaryTaxId,
        intermediaries,
        uploadFlow,
    ]);

    useEffect(() => {
        if (
            formModel &&
            formModel.fileContent &&
            (((taxSubmissionType === TaxSubmissionType.ANNULLAMENTO ||
                taxSubmissionType === TaxSubmissionType.F24) &&
                formModel.deadline &&
                dayjs(formModel.deadline).isValid() &&
                !formModel.levy) ||
                taxSubmissionType === TaxSubmissionType.OTHERS) &&
            formModel.fileName &&
            (formModel.intermediarySiteCode || uploadFlow === "SDI") &&
            formModel.intermediaryTaxId &&
            isSelectedDelegatedValid &&
            uploadFlow
        ) {
            setCanUploadTaxSubmission(true);
        }

        return () => {
            setCanUploadTaxSubmission(false);
        };
    }, [formModel, taxSubmissionType, isSelectedDelegatedValid, uploadFlow]);

    useEffect(() => {
        setIsFormLoading(loadingGetDelegated || loadingUploadTaxSubmission);
    }, [loadingGetDelegated, loadingUploadTaxSubmission]);

    // functions
    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "levy") {
            const [levyId, accrualYear] = value.split("_");
            const selectedLevy = fulfilments.find(
                (levy) =>
                    levy.levyId === levyId &&
                    levy.accrualYear === Number(accrualYear),
            );

            if (selectedLevy) {
                setFormModel((prevValues) => ({
                    ...prevValues,
                    levy: {
                        accrualYear: selectedLevy.accrualYear,
                        levyId: selectedLevy.levyId,
                    },
                }));
            }
        } else {
            setFormModel((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        isTaxSubmissionUploading(true);
        const success = await uploadTaxSubmission({
            deadline:
                taxSubmissionType === TaxSubmissionType.ANNULLAMENTO ||
                taxSubmissionType === TaxSubmissionType.F24
                    ? dayjs(formModel.deadline).toISOString()
                    : undefined,
            delegatedTaxId: formModel.delegatedTaxId,
            fileContent: formModel.fileContent,
            fileName: formModel.fileName,
            intermediarySiteCode: formModel.intermediarySiteCode,
            intermediaryTaxId: formModel.intermediaryTaxId,
            levy: formModel.levy,
            uploadFlow: uploadFlow,
        });
        if (success) {
            showTextMessage(
                t(
                    "views.home.components.tax_submission_upload_form.upload_complete",
                    {
                        defaultValue:
                            "Fornitura telematica caricata con successo",
                    },
                ),
            );
            onUploadTaxSubmission();
        }
        isTaxSubmissionUploading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            {isTaxSubmissionFileParsed && (
                <Box>
                    <LoadingWrapper
                        spinnerProps={{
                            loadingText: t(
                                "components.loading_wrapper.loading_message",
                                { defaultValue: "Attendere prego" },
                            ),
                        }}
                        loading={isFormLoading}
                        variant="spinner"
                    >
                        <Stack spacing={1}>
                            <Typography variant="titleMedium">
                                {t(
                                    "views.home.components.tax_submission_upload_form.add_detail",
                                    { defaultValue: "Dettagli" },
                                )}
                            </Typography>

                            {/* ADEMPIMENTO */}
                            {taxSubmissionType === TaxSubmissionType.OTHERS && (
                                <TextField
                                    label={t(
                                        "views.home.components.tax_submission_upload_form.fulfilment.label",
                                        { defaultValue: "Adempimento" },
                                    )}
                                    name="levy"
                                    onChange={handleFormChange}
                                    readOnly={
                                        fulfilments.length > 1 ? false : true
                                    }
                                    select={
                                        fulfilments.length > 1 ? true : false
                                    }
                                    value={
                                        `${formModel.levy?.levyId}_${formModel.levy?.accrualYear}` ||
                                        ""
                                    }
                                >
                                    {fulfilments?.map((fulfilment) => (
                                        <MenuItem
                                            key={`${fulfilment.levyId}-${fulfilment.accrualYear}`}
                                            value={`${fulfilment.levyId}_${fulfilment.accrualYear}`}
                                        >
                                            {`${
                                                fulfilment.name ||
                                                fulfilment.levyId
                                            } (${fulfilment.accrualYear})`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            {(taxSubmissionType ===
                                TaxSubmissionType.ANNULLAMENTO ||
                                taxSubmissionType ===
                                    TaxSubmissionType.F24) && (
                                <TextField
                                    label={t(
                                        "views.home.components.tax_submission_upload_form.fulfilment.label",
                                        { defaultValue: "Adempimento" },
                                    )}
                                    readOnly={true}
                                    value={
                                        taxSubmissionType ===
                                        TaxSubmissionType.F24
                                            ? t(
                                                  "views.home.components.tax_submission_upload_form.fulfilment.f24",
                                                  {
                                                      defaultValue:
                                                          "Delega F24",
                                                  },
                                              )
                                            : t(
                                                  "views.home.components.tax_submission_upload_form.fulfilment.annullamento",
                                                  {
                                                      defaultValue:
                                                          "Delega Annullamento",
                                                  },
                                              )
                                    }
                                />
                            )}

                            {/* Deadline */}
                            {(taxSubmissionType ===
                                TaxSubmissionType.ANNULLAMENTO ||
                                taxSubmissionType ===
                                    TaxSubmissionType.F24) && (
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={it}
                                >
                                    <DatePicker
                                        label={t(
                                            "views.home.components.tax_submission_upload_form.deadline",
                                            {
                                                defaultValue:
                                                    "Data di scadenza",
                                            },
                                        )}
                                        onChange={(newValue) => {
                                            setFormModel((prevValues) => ({
                                                ...prevValues,
                                                deadline: dayjs(
                                                    newValue as Date,
                                                )
                                                    .hour(12)
                                                    .toDate(),
                                            }));
                                        }}
                                        readOnly={
                                            isFormLoading ||
                                            taxSubmissionType ===
                                                TaxSubmissionType.ANNULLAMENTO
                                        }
                                        sx={{ mb: 3 }}
                                        value={formModel.deadline}
                                    />
                                </LocalizationProvider>
                            )}

                            {/* Intermediary */}
                            <TextField
                                label={t(
                                    "views.home.components.tax_submission_upload_form.intermediary",
                                    { defaultValue: "Intermediario" },
                                )}
                                name="intermediaryTaxId"
                                onChange={handleFormChange}
                                readOnly={isFormLoading}
                                select
                                value={formModel.intermediaryTaxId}
                            >
                                {intermediaries?.map((intermediary) => (
                                    <MenuItem
                                        key={intermediary.taxId}
                                        value={intermediary.taxId}
                                    >
                                        {intermediary.name ||
                                            intermediary.taxId}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/* Site */}
                            {sites.length > 0 && (
                                <TextField
                                    label={t(
                                        "views.home.components.tax_submission_upload_form.site",
                                        { defaultValue: "Sede" },
                                    )}
                                    name="intermediarySiteCode"
                                    onChange={handleFormChange}
                                    readOnly={
                                        sites.length === 1 || isFormLoading
                                    }
                                    select
                                    value={formModel.intermediarySiteCode}
                                >
                                    {sites.map((site) => (
                                        <MenuItem
                                            key={site.siteCode}
                                            value={site.siteCode}
                                        >
                                            {site.siteName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            {sites.length === 0 && (
                                <TextField
                                    error={
                                        uploadFlow === "ADE" ||
                                        uploadFlow === "TS"
                                    }
                                    helperText={
                                        uploadFlow === "ADE" ||
                                        uploadFlow === "TS"
                                            ? t(
                                                  "views.home.components.tax_submission_upload_form.errors.no_site",
                                                  {
                                                      defaultValue:
                                                          "Per l'upload della fornitura è necessario configurare una sede",
                                                  },
                                              )
                                            : undefined
                                    }
                                    label={t(
                                        "views.home.components.tax_submission_upload_form.site",
                                        { defaultValue: "Sede" },
                                    )}
                                    readOnly={true}
                                    value={t(
                                        "views.home.components.tax_submission_upload_form.no_site",
                                        {
                                            defaultValue:
                                                "Nessuna sede configurata",
                                        },
                                    )}
                                />
                            )}

                            {/* Delegated */}
                            {uploadFlow === "ADE" && delegated.length > 0 && (
                                <TextField
                                    label={t(
                                        "views.home.components.tax_submission_upload_form.delegated",
                                        { defaultValue: "Incaricato" },
                                    )}
                                    name="delegatedTaxId"
                                    onChange={handleFormChange}
                                    readOnly={
                                        isFormLoading || delegated.length === 1
                                    }
                                    select
                                    value={formModel.delegatedTaxId}
                                >
                                    {[
                                        ...delegated.filter(
                                            (item) => item.recommended,
                                        ),
                                        ...delegated.filter(
                                            (item) => !item.recommended,
                                        ),
                                        { taxId: "", name: "" },
                                    ]?.map((delegated) => (
                                        <MenuItem
                                            key={delegated.taxId}
                                            value={delegated.taxId}
                                        >
                                            {delegated.name || delegated.taxId}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            {uploadFlow === "ADE" && delegated.length === 0 && (
                                <TextField
                                    error={!isSelectedDelegatedValid}
                                    helperText={
                                        !isSelectedDelegatedValid
                                            ? t(
                                                  "views.home.components.tax_submission_upload_form.errors.no_delegated",
                                                  {
                                                      defaultValue:
                                                          "Per l'upload della fornitura è necessario configurare un incaricato",
                                                  },
                                              )
                                            : undefined
                                    }
                                    label={t(
                                        "views.home.components.tax_submission_upload_form.delegated",
                                        { defaultValue: "Incaricato" },
                                    )}
                                    readOnly={true}
                                    value={t(
                                        "views.home.components.tax_submission_upload_form.no_delegated",
                                        {
                                            defaultValue:
                                                "Nessun incaricato configurato",
                                        },
                                    )}
                                />
                            )}
                        </Stack>
                    </LoadingWrapper>
                </Box>
            )}
            <Box
                sx={{
                    ml: -4,
                    //  "div:nth-of-type(2)": { marginRight: 1 }
                }}
            >
                <VaporToolbar
                    contentRight={[
                        <Button
                            onClick={onClickCancelButton}
                            variant="outlined"
                        >
                            {t(
                                "views.home.components.tax_submission_upload_form.actions.cancel",
                                { defaultValue: "Annulla" },
                            )}
                        </Button>,
                        <Button
                            disabled={
                                !canUploadTaxSubmission ||
                                loadingUploadTaxSubmission
                            }
                            type="submit"
                            variant="contained"
                        >
                            {t(
                                "views.home.components.tax_submission_upload_form.actions.upload",
                                { defaultValue: "Invia" },
                            )}
                            {loadingUploadTaxSubmission && (
                                <CircularProgress
                                    color="inherit"
                                    sx={{ ml: 1 }}
                                    size={16}
                                />
                            )}
                        </Button>,
                    ]}
                    size="medium"
                    variant="regular"
                />
            </Box>
        </form>
    );
};
