import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { Theme } from "@mui/system";
import { useTranslation } from "@onefront/react-sdk";
import {
    DateRange,
    LoadingWrapper,
    VaporPageStickable,
} from "@vapor/react-contrib-tax-compliance";
import { VaporPageStickableDrawerProps } from "@vapor/react-contrib-tax-compliance/VaporPageStickable";
import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { ArrowLeft, VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Stack,
    useMediaQuery,
} from "@vapor/react-material";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useDebouncedCallback } from "use-debounce";
import { useTaxSubmissionContext } from "../../../core/contexts/TaxSubmissionContext";
import { useUserContext } from "../../../core/contexts/UserContext";
import { PaginatedTaxSubmissionsDTO } from "../../../core/dtos/PaginatedTaxSubmissionsDTO";
import { TaxSubmissionDTO } from "../../../core/dtos/TaxSubmissionDTO";
import { GovernmentAgencyType } from "../../../core/dtos/TaxSubmissionDetailDTO";
import { TaxSubmissionStatus } from "../../../core/dtos/TaxSubmissionStatus";
import { useErrorNotification } from "../../../core/hooks/useErrorNotification";
import { useSuccessNotification } from "../../../core/hooks/useSuccessNotification";
import { useWarningNotification } from "../../../core/hooks/useWarningNotification";
import { useGetTaxSubmissionDetail } from "../../../core/usecases/use-get-tax-submission-detail/useGetTaxSubmissionDetail";
import {
    TaxSubmissionFilterSearch,
    useGetTaxSubmissions,
} from "../../../core/usecases/use-get-tax-submissions/useGetTaxSubmissions";
import { useSendTaxSubmission } from "../../../core/usecases/use-send-tax-submission/useSendTaxSubmission";
import { downloadFile } from "../../../core/utils/downloadFile";
import { TaxSubmissionDetailSummary } from "./components/tax-submission-detail-summary/TaxSubmissionDetailSummary";
import { TaxSubmissionDetail } from "./components/tax-submission-detail/TaxSubmissionDetail";
import { TaxSubmissionOutcomePreview } from "./components/tax-submission-outcome-preview/TaxSubmissionOutcomePreview";
import { TaxSubmissionUpload } from "./components/tax-submission-upload/TaxSubmissionUpload";
import { TaxSubmissionsTableHeader } from "./components/tax-submissions-table-header/TaxSubmissionsTableHeader";
import { TaxSubmissionsTable } from "./components/tax-submissions-table/TaxSubmissionsTable";
import { REACT_APP_ENV, APP_VERSION } from "../../../config";

const defaultPaginatedTaxSubmissions: PaginatedTaxSubmissionsDTO = {
    data: [],
    totalCount: 0,
};

export const Home = () => {
    const { showTextMessage: showWarningMessage } = useWarningNotification();
    const { t } = useTranslation("government-agency-hub-app");
    const location = useLocation();
    const devOrTestEnv =
        REACT_APP_ENV === "development" || REACT_APP_ENV === "test";

    const isXlScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up(theme.breakpoints.values.xl),
    );

    const { updateMap } = useTaxSubmissionContext();
    const { showTextMessage: showErrorTextMessage } = useErrorNotification();
    const { showTextMessage } = useSuccessNotification();
    const { subscriptions } = useUserContext();

    // states
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    // states -> checkedNotSentTaxSubmissions
    const [checkedNotSentTaxSubmissions, setCheckedNotSentTaxSubmissions] =
        useState<TaxSubmissionDTO[]>([]);

    // states -> drawer
    const [drawerContent, setDrawerContent] =
        useState<VaporPageStickableDrawerProps>();

    // states -> expiring filter
    const [expiringFilterEnabled, setExpiringFilterEnabled] = useState(false);

    // states -> header right width used to create a fake header left spacer in order to center title and subtitle
    const headerRightRef = useRef(null);
    const [headerRightWidth, setHeaderRightWidth] = useState(0);

    // states -> preview outcome
    const [previewOutcomeParams, setPreviewOutcomeParams] = useState<
        | {
              availableOutcomeTypes: string[];
              governmentAgencyType: GovernmentAgencyType;
              taxSubmissionId: string;
              selectedOutcomeType: string;
              taxPayer?: string;
          }
        | undefined
    >();

    // states -> sogei validation content
    const [previewSogeiOutcomeParams, setPreviewSogeiOutcomeParams] = useState<{
        content: string;
        loading: boolean;
    }>({ content: "", loading: false });

    // states -> selectedTaxSubmission
    const [selectedTaxSubmission, setSelectedTaxSubmission] = useState<{
        id: string;
        revoke: boolean;
    }>();

    // states -> upload taxSubmission
    const [showUploadTaxSubmission, setShowUploadTaxSubmission] =
        useState(false);

    // functions -> initialize filter search
    const initializeTaxSubmissionFilterSearch = useCallback(() => {
        const searchParams = new URLSearchParams(location.search);
        const params: { [key: string]: string } = {};
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }

        let deadlineFromLocation;
        let deadlineToLocation;
        let statusesLocation: TaxSubmissionStatus[] | undefined;

        if (params.dateFrom) {
            deadlineFromLocation = dayjs(params.dateFrom, "DD-MM-YYYY");
        }
        if (params.dateTo) {
            deadlineToLocation = dayjs(params.dateTo, "DD-MM-YYYY");
        }
        if (
            params.status &&
            Object.values(TaxSubmissionStatus).includes(
                params.status as TaxSubmissionStatus,
            )
        ) {
            statusesLocation = [params.status as TaxSubmissionStatus];
        }

        return {
            deadlineFrom: (deadlineFromLocation || dayjs().subtract(1, "month"))
                .startOf("day")
                .toDate(),
            deadlineTo: (deadlineToLocation || dayjs().add(1, "month"))
                .endOf("day")
                .toDate(),
            statuses: statusesLocation || [],
        };
    }, [location.search]);

    // states -> tax submission filter search
    const [taxSubmissionFilterSearch, setTaxSubmissionFilterSearch] =
        useState<TaxSubmissionFilterSearch>(() => {
            return initializeTaxSubmissionFilterSearch();
        });

    const [
        previousTaxSubmissionFilterSearch,
        setPreviousTaxSubmissionFilterSearch,
    ] = useState<TaxSubmissionFilterSearch>(taxSubmissionFilterSearch);

    // use cases
    const {
        fetch: fetchGetTaxSubmissions,
        loading: loadingGetTaxSubmissions,
        data: dataGetTaxSubmissions,
    } = useGetTaxSubmissions();

    const {
        fetch: fetchGetTaxSubmissionDetail,
        loading: loadingGetTaxSubmissionDetail,
        data: dataGetTaxSubmissionDetail,
    } = useGetTaxSubmissionDetail({
        id: selectedTaxSubmission?.id || "",
    });

    const { loading: loadingSendTaxSubmission, send: sendTaxSubmission } =
        useSendTaxSubmission();

    // effects
    useEffect(() => {
        if (selectedTaxSubmission) {
            fetchGetTaxSubmissionDetail();
        }
    }, [fetchGetTaxSubmissionDetail, selectedTaxSubmission]);

    const debouncedGetTaxSubmissions = useDebouncedCallback(
        (cb: () => void) => {
            cb();
        },
        500,
    );

    useEffect(() => {
        debouncedGetTaxSubmissions(() => {
            fetchGetTaxSubmissions({
                params: {
                    filter: taxSubmissionFilterSearch,
                    page: page,
                    pageSize: pageSize,
                },
            });
        });
    }, [
        debouncedGetTaxSubmissions,
        fetchGetTaxSubmissions,
        page,
        pageSize,
        taxSubmissionFilterSearch,
    ]);

    useEffect(() => {
        (async function () {
            if (dataGetTaxSubmissions) {
                for (const taxSubmission of dataGetTaxSubmissions.data) {
                    await updateMap(taxSubmission.ownerApp.urn);
                }
            }
        })();
    }, [dataGetTaxSubmissions, updateMap]);

    useEffect(() => {
        if (selectedTaxSubmission) {
            setDrawerContent({
                content: (
                    <Stack direction="column" spacing={4}>
                        <LoadingWrapper
                            loading={loadingGetTaxSubmissionDetail}
                            variant="skeleton-list"
                            skeletonProps={{ rowsNumber: 10, title: false }}
                        >
                            {dataGetTaxSubmissionDetail && (
                                <>
                                    <TaxSubmissionDetailSummary
                                        taxSubmissionDetail={
                                            dataGetTaxSubmissionDetail
                                        }
                                        fetchTaxSubmissions={() => {
                                            setSelectedTaxSubmission(undefined);
                                            setDrawerContent(undefined);
                                            fetchGetTaxSubmissions({
                                                params: {
                                                    filter: taxSubmissionFilterSearch,
                                                    page: page,
                                                    pageSize: pageSize,
                                                },
                                            });
                                        }}
                                    />
                                    <TaxSubmissionDetail
                                        taxSubmissionDetail={
                                            dataGetTaxSubmissionDetail
                                        }
                                        loading={loadingGetTaxSubmissionDetail}
                                        onTaxSubmissionUpdate={() => {
                                            setSelectedTaxSubmission(undefined);
                                            setDrawerContent(undefined);
                                            fetchGetTaxSubmissions({
                                                params: {
                                                    filter: taxSubmissionFilterSearch,
                                                    page: page,
                                                    pageSize: pageSize,
                                                },
                                            });
                                        }}
                                        onClickPreviewOutcome={(
                                            availablesOutcomeTypes: string[],
                                            governmentAgencyType: GovernmentAgencyType,
                                            taxSubmissionId: string,
                                            selectedOutcomeType: string,
                                            taxPayer?: string,
                                        ) => {
                                            setPreviewOutcomeParams({
                                                availableOutcomeTypes:
                                                    availablesOutcomeTypes,
                                                governmentAgencyType:
                                                    governmentAgencyType,
                                                taxSubmissionId:
                                                    taxSubmissionId,
                                                selectedOutcomeType:
                                                    selectedOutcomeType,
                                                taxPayer: taxPayer,
                                            });
                                        }}
                                        revokeTaxPayers={
                                            selectedTaxSubmission.revoke
                                        }
                                    />
                                </>
                            )}
                        </LoadingWrapper>
                    </Stack>
                ),
                headerTitle: dataGetTaxSubmissionDetail?.fulfilmentSchema?.name,
                onClickCloseButton: () => {
                    setDrawerContent(undefined);
                    setSelectedTaxSubmission(undefined);
                },
                open: !!selectedTaxSubmission,
                width: isXlScreen ? "45%" : "65%",
            });
        }
    }, [
        loadingGetTaxSubmissionDetail,
        dataGetTaxSubmissionDetail,
        selectedTaxSubmission,
        isXlScreen,
        fetchGetTaxSubmissions,
        taxSubmissionFilterSearch,
        page,
        pageSize,
    ]);

    useEffect(() => {
        if (showUploadTaxSubmission) {
            setDrawerContent({
                content: (
                    <TaxSubmissionUpload
                        onClickCancelButton={() => {
                            setShowUploadTaxSubmission(false);
                            setDrawerContent(undefined);
                        }}
                        onUploadTaxSubmission={() => {
                            setShowUploadTaxSubmission(false);
                            setDrawerContent(undefined);
                            fetchGetTaxSubmissions({
                                params: {
                                    filter: taxSubmissionFilterSearch,
                                    page: page,
                                    pageSize: pageSize,
                                },
                            });
                        }}
                    />
                ),
                headerTitle: t("views.home.actions.upload_taxSubmission", {
                    defaultValue: "Carica fornitura telematica",
                }),
                onClickCloseButton: () => {
                    setDrawerContent(undefined);
                    setShowUploadTaxSubmission(false);
                },
                open: showUploadTaxSubmission,
                width: isXlScreen ? "60%" : "70%",
            });
        }
    }, [
        fetchGetTaxSubmissions,
        isXlScreen,
        page,
        pageSize,
        showUploadTaxSubmission,
        t,
        taxSubmissionFilterSearch,
    ]);

    useEffect(() => {
        if (
            previewSogeiOutcomeParams.loading ||
            previewSogeiOutcomeParams.content
        ) {
            setDrawerContent({
                content: (
                    <LoadingWrapper
                        loading={previewSogeiOutcomeParams.loading}
                        spinnerProps={{
                            loadingText: t(
                                "components.loading_wrapper.loading_message",
                                { defaultValue: "Attendere prego" },
                            ),
                        }}
                    >
                        <SyntaxHighlighter language="text">
                            {previewSogeiOutcomeParams.content}
                        </SyntaxHighlighter>
                    </LoadingWrapper>
                ),
                headerTitle: t(
                    "views.home.components.tax_submission_preview_sogei.validation",
                    { defaultValue: "Esito Controllo Sogei" },
                ),
                onClickCloseButton: () => {
                    setDrawerContent(undefined);
                    setPreviewSogeiOutcomeParams({
                        content: "",
                        loading: false,
                    });
                },
                open:
                    previewSogeiOutcomeParams.loading ||
                    !!previewSogeiOutcomeParams.content,
                width: isXlScreen ? "45%" : "65%",
                footer: (
                    <Box
                        sx={{
                            ml: -4,
                            //  "div:nth-of-type(2)": { marginRight: 1 }
                        }}
                    >
                        <VaporToolbar
                            contentRight={[
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={() =>
                                        downloadFile(
                                            Buffer.from(
                                                previewSogeiOutcomeParams.content,
                                                "utf8",
                                            ),
                                            "esito_sogei",
                                            "application/text",
                                        )
                                    }
                                >
                                    <VaporIcon
                                        icon={faDownload}
                                        color="inverse"
                                    />
                                    <Typography style={{ marginLeft: "10px" }}>
                                        {t(
                                            "views.home.components.tax_submission_download_sogei_error.download_sogei_error",
                                            { defaultValue: "Scarica" },
                                        )}
                                    </Typography>
                                </Button>,
                            ]}
                            size="large"
                            variant="regular"
                        />
                    </Box>
                ),
            });
        }
    }, [isXlScreen, previewSogeiOutcomeParams, t]);

    useEffect(() => {
        if (previewOutcomeParams) {
            setDrawerContent((prevDrawerContent) => {
                return {
                    content: (
                        <>
                            <TaxSubmissionOutcomePreview
                                availableOutcomeTypes={
                                    previewOutcomeParams.availableOutcomeTypes
                                }
                                governmentAgencyType={
                                    previewOutcomeParams.governmentAgencyType
                                }
                                taxSubmissionId={
                                    previewOutcomeParams.taxSubmissionId
                                }
                                selectedOutcomeType={
                                    previewOutcomeParams.selectedOutcomeType
                                }
                                taxPayer={previewOutcomeParams.taxPayer}
                            />
                        </>
                    ),
                    headerLeft: [
                        <IconButton
                            onClick={() => {
                                setDrawerContent(prevDrawerContent);
                            }}
                            sx={{ padding: 0 }}
                        >
                            <ArrowLeft color="interactive" />
                        </IconButton>,
                    ],
                    headerTitle: t(
                        "views.home.components.tax_submission_preview_sogei.outcome",
                        { defaultValue: "Anteprima " },
                    ),
                    onClickCloseButton: () => {
                        setDrawerContent(undefined);
                        setPreviewOutcomeParams(undefined);
                        setSelectedTaxSubmission(undefined);
                    },
                    open: !!previewOutcomeParams,
                    width: isXlScreen ? "45%" : "65%",
                };
            });
        }
    }, [isXlScreen, previewOutcomeParams, t]);

    useEffect(() => {
        if (expiringFilterEnabled) {
            setTaxSubmissionFilterSearch((taxSubmissionFilterSearch) => {
                setPreviousTaxSubmissionFilterSearch(taxSubmissionFilterSearch);
                return {
                    ...taxSubmissionFilterSearch,
                    deadlineFrom: dayjs().startOf("day").toDate(),
                    deadlineTo: dayjs().add(7, "day").endOf("day").toDate(),
                    statuses: [],
                };
            });
        }
    }, [expiringFilterEnabled]);

    useEffect(() => {
        if (!expiringFilterEnabled) {
            setTaxSubmissionFilterSearch(previousTaxSubmissionFilterSearch);
        }
    }, [expiringFilterEnabled, previousTaxSubmissionFilterSearch]);

    useEffect(() => {
        const updateWidth = () => {
            if (headerRightRef.current) {
                setHeaderRightWidth(
                    (headerRightRef.current as any).offsetWidth,
                );
            }
        };

        updateWidth();
        window.addEventListener("resize", updateWidth);

        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);

    // functions
    const onClickSendTaxSubmissions = async () => {
        const { succeded, failed } = await sendTaxSubmission(
            checkedNotSentTaxSubmissions,
        );

        succeded.forEach((taxSubmission) => {
            const name =
                dataGetTaxSubmissions?.data.find(
                    (data) => data.id === taxSubmission.id,
                )?.fileName ?? taxSubmission.id;

            showTextMessage(
                t("views.home.notifications.send_taxSubmission_succeded", {
                    defaultValue:
                        "La fornitura {{name}} è stata presa in carico",
                    name: name,
                }),
            );
        });

        failed.forEach((taxSubmission) => {
            const name =
                dataGetTaxSubmissions?.data.find(
                    (data) => data.id === taxSubmission.id,
                )?.fileName ?? taxSubmission.id;

            showErrorTextMessage(
                t("views.home.notifications.send_taxSubmission_failed", {
                    defaultValue:
                        "La fornitura {{name}} non è stata presa in carico: {{error}}",
                    name: name,
                    error: taxSubmission.error,
                }),
            );
        });

        fetchGetTaxSubmissions({
            params: {
                filter: taxSubmissionFilterSearch,
                page: page,
                pageSize: pageSize,
            },
        });
    };

    const onClickExpiringChip = () => {
        setExpiringFilterEnabled((prevValue) => !prevValue);
    };

    const handleDateRangeValidation = () => {
        showWarningMessage(
            t("views.home.date_range.warning", {
                defaultValue: "Puoi selezionare un massimo di 365 giorni",
            }),
        );
    };

    const resolveSubtitle = () => {
        let subtitle: string = t("views.home.header.subtitle", {
            defaultValue: "Panoramica",
        });
        if (devOrTestEnv) {
            subtitle += `${"\n"}${REACT_APP_ENV} ${APP_VERSION}`;
        }
        return subtitle;
    };

    return (
        <VaporPageStickable
            drawer={drawerContent}
            headerLeft={
                // fake spacer to center title and subtitle
                <Box visibility="hidden" width={headerRightWidth}></Box>
            }
            headerBottom={
                <Stack
                    direction="row"
                    spacing={10}
                    padding={2}
                    justifyContent="space-between"
                    alignContent="baseline"
                >
                    <Box flexGrow={2}>
                        <TaxSubmissionsTableHeader
                            filters={taxSubmissionFilterSearch}
                            uploadDisabled={
                                loadingSendTaxSubmission ||
                                subscriptions.every((sub) => sub.isReadOnly)
                            }
                            onUpload={setShowUploadTaxSubmission}
                            onFilterChange={(filters) => {
                                setTaxSubmissionFilterSearch(filters);
                            }}
                            onClickExpiringChip={onClickExpiringChip}
                        />
                    </Box>
                </Stack>
            }
            headerBottomDivider
            headerRight={
                <Stack
                    alignItems="center"
                    direction="row"
                    id="header-right"
                    spacing={2}
                    mr={2}
                    ref={headerRightRef}
                >
                    <DateRange
                        currentMonthBtnLabel={t(
                            "views.home.date_range.current_month",
                            { defaultValue: "Mese corrente" },
                        )}
                        disableValidationAlert
                        endDate={taxSubmissionFilterSearch.deadlineTo}
                        handleCustomValidation={handleDateRangeValidation}
                        onChangeDates={(startDate, endDate) => {
                            setTaxSubmissionFilterSearch((prevValue) => {
                                return {
                                    ...prevValue,
                                    deadlineFrom: startDate,
                                    deadlineTo: endDate,
                                };
                            });
                        }}
                        showCurrentMonthBtn
                        startDate={taxSubmissionFilterSearch.deadlineFrom}
                    />
                </Stack>
            }
            stickyHeader
            sxContent={{ padding: 2 }}
            title={t("views.home.header.title", {
                defaultValue: "Telematici",
            })}
            subtitle={resolveSubtitle()}
            sxHeader={{ whiteSpace: "pre-line" }}
        >
            <Stack spacing={2}>
                <LoadingWrapper
                    loading={loadingGetTaxSubmissions}
                    variant="skeleton-list"
                    skeletonProps={{ rowsNumber: 10 }}
                >
                    <TaxSubmissionsTable
                        taxSubmissions={
                            dataGetTaxSubmissions?.data ??
                            defaultPaginatedTaxSubmissions.data
                        }
                        loading={loadingGetTaxSubmissions}
                        onTaxSubmissionsFetch={() =>
                            fetchGetTaxSubmissions({
                                params: {
                                    filter: taxSubmissionFilterSearch,
                                    page: page,
                                    pageSize: pageSize,
                                },
                            })
                        }
                        onChangePaginationModel={(
                            page: number,
                            pageSize: number,
                        ) => {
                            setPage(page);
                            setPageSize(pageSize);
                        }}
                        onCheckNotSentTaxSubmissions={(
                            taxSubmissions: TaxSubmissionDTO[],
                        ) => {
                            setCheckedNotSentTaxSubmissions(taxSubmissions);
                        }}
                        onClickTaxSubmission={(taxSubmissionId) =>
                            setSelectedTaxSubmission({
                                id: taxSubmissionId,
                                revoke: false,
                            })
                        }
                        onClickPreviewSogeiOutcome={(content, loading) => {
                            setPreviewSogeiOutcomeParams({
                                content: content,
                                loading: loading,
                            });
                        }}
                        onClickRevokeTaxSubmission={(taxSubmissionId) =>
                            setSelectedTaxSubmission({
                                id: taxSubmissionId,
                                revoke: true,
                            })
                        }
                        page={page}
                        pageSize={pageSize}
                        rowCount={
                            dataGetTaxSubmissions?.totalCount ??
                            defaultPaginatedTaxSubmissions.totalCount
                        }
                    />
                </LoadingWrapper>
                {checkedNotSentTaxSubmissions.length > 0 && (
                    <Box sx={{ "& div": { left: 0 } }}>
                        <VaporToolbar
                            contentRight={[
                                <Button
                                    disabled={loadingSendTaxSubmission}
                                    onClick={onClickSendTaxSubmissions}
                                    variant="contained"
                                >
                                    {t(
                                        "views.home.actions.send_taxSubmission",
                                        {
                                            defaultValue: "Invia",
                                        },
                                    )}
                                    {loadingSendTaxSubmission && (
                                        <CircularProgress
                                            color="inherit"
                                            sx={{ ml: 1 }}
                                            size={16}
                                        />
                                    )}
                                </Button>,
                            ]}
                            size="medium"
                            variant="regular"
                        />
                    </Box>
                )}
            </Stack>
        </VaporPageStickable>
    );
};
