import {
    faArrowUpRightFromSquare,
    faXmark,
} from "@fortawesome/pro-light-svg-icons";
import {
    faCircleExclamation,
    faCircleMinus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Link,
    Stack,
    useTheme,
} from "@vapor/react-material";
import dayjs from "dayjs";
import { ReactElement, useCallback, useState } from "react";
import { v4 } from "uuid";
import {
    ExpiringDelegated,
    ExpiringIntermediary,
    ExpiringSecurityEnvironment,
} from "../../../../../core/dtos/ExpiringIdentitiesDTO";

type Variant =
    | "expiringCredentials"
    | "expiredCredentials"
    | "expiringSecurityEnvironments"
    | "expiredSecurityEnvironments";

const DIALOG_TEXT: Record<Variant, { key: string; default: string }> = {
    expiringCredentials: {
        key: "views.home.components.tax_submissions_table_header.expiringIdentities.dialog.credentials.expiring",
        default: "Credenziali in scadenza per:",
    },
    expiredCredentials: {
        key: "views.home.components.tax_submissions_table_header.expiringIdentities.dialog.credentials.expired",
        default: "Credenziali scadute per:",
    },
    expiringSecurityEnvironments: {
        key: "views.home.components.tax_submissions_table_header.expiringIdentities.dialog.securityEnvironment.expiring",
        default: "Ambiente di sicurezza in scadenza per:",
    },
    expiredSecurityEnvironments: {
        key: "views.home.components.tax_submissions_table_header.expiringIdentities.dialog.securityEnvironment.expired",
        default: "Ambiente di sicurezza scaduto per:",
    },
};

const getIdentityText = (
    identity:
        | ExpiringIntermediary
        | ExpiringDelegated
        | ExpiringSecurityEnvironment,
) => {
    if ("taxId" in identity) {
        return `${identity.taxId} - ${dayjs(identity.expirationDate).format(
            "DD/MM/YYYY",
        )}`;
    }
    return `${identity.intermediaryTaxId} - ${dayjs(
        identity.expirationDate,
    ).format("DD/MM/YYYY")}`;
};

export interface WarningNotificationContentProps {
    children: ReactElement;
    variant: Variant;
    identities:
        | ExpiringIntermediary[]
        | ExpiringDelegated[]
        | ExpiringSecurityEnvironment[];
    onDismiss?: () => void;
}

export const WarningNotificationContent: React.FC<
    WarningNotificationContentProps
> = ({ variant, children, identities, onDismiss }): ReactElement => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const handleDialogOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleDialogClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleOnDismiss = useCallback(() => {
        if (onDismiss) onDismiss();
    }, [onDismiss]);

    return (
        <>
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>
                    <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Typography
                            color={theme.palette.primary.textTitleColor}
                            variant="bodyLarge"
                        >
                            {t(
                                "views.home.components.tax_submissions_table_header.expiringIdentities.dialogTitle",
                                { defaultValue: "Attenzione" },
                            )}
                        </Typography>
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={handleDialogClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Stack>
                </DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent sx={{ width: "600px" }}>
                    <Stack flexDirection="row" gap={2}>
                        {variant === "expiredCredentials" ||
                        variant === "expiredSecurityEnvironments" ? (
                            <FontAwesomeIcon
                                icon={faCircleMinus}
                                color={theme.palette.primary.accentRustyRed}
                                size="xl"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faCircleExclamation}
                                color={theme.palette.primary.baseWarningColor}
                                size="xl"
                            />
                        )}
                        <Stack flexDirection="column" gap={1}>
                            <Typography>
                                {t(DIALOG_TEXT[variant].key, {
                                    defaultValue: DIALOG_TEXT[variant].default,
                                })}
                            </Typography>
                            {identities.map((identity) => (
                                <Typography key={v4()} variant="bodyLarge700">
                                    {getIdentityText(identity)}
                                </Typography>
                            ))}
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleDialogClose}>
                        {t(
                            "views.home.components.tax_submissions_table_header.expiringIdentities.dialog.back",
                            { defaultValue: "Annulla" },
                        )}
                    </Button>
                    <Link
                        href="https://iampe.agenziaentrate.gov.it/sam/UI/Login?realm=/agenziaentrate"
                        target="_blank"
                        rel="noopener"
                        underline="none"
                        sx={{
                            display: "flex",
                            alignContent: "center",
                            height: "40px",
                            width: "fit-content",
                            border: `1px solid ${theme.palette.primary.cyanProgress}`,
                            background:
                                "linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%)",
                            py: 0.5,
                            px: 1,
                        }}
                    >
                        <Stack flexDirection="row" gap={1} alignItems="center">
                            <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                color="white"
                            />
                            <Typography color="white">
                                {t(
                                    "views.home.components.tax_submissions_table_header.expiringIdentities.dialog.entratel",
                                    { defaultValue: "Vai a entratel" },
                                )}
                            </Typography>
                        </Stack>
                    </Link>
                </DialogActions>
            </Dialog>
            <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                {children}
                <Stack flexDirection="row" alignItems="center" gap={2}>
                    <Button variant="outlined" onClick={handleDialogOpen}>
                        {t(
                            "views.home.components.tax_submissions_table_header.expiringIdentities.show",
                            { defaultValue: "Mostra" },
                        )}
                    </Button>
                    <Button onClick={handleOnDismiss}>
                        {t(
                            "views.home.components.tax_submissions_table_header.expiringIdentities.ignore",
                            { defaultValue: "Ignora" },
                        )}
                    </Button>
                </Stack>
            </Stack>
        </>
    );
};
