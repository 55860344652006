import { useTranslation } from "@onefront/react-sdk";
import { Tag } from "@vapor/react-custom";
import { useTheme } from "@vapor/react-material";
import { TaxSubmissionStatus } from "../../../core/dtos/TaxSubmissionStatus";
import { getColorByTaxSubmissionStatus } from "../../../core/utils/getColorByTaxSubmissionStatus";

interface TaxSubmissionStatusChipProps {
    plural?: boolean;
    status: TaxSubmissionStatus;
    variant?: "ghost" | "default" | "standard";
}
export const TaxSubmissionStatusChip: React.FC<
    TaxSubmissionStatusChipProps
> = ({ plural = false, status, variant = "standard" }): JSX.Element => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();

    return (
        <Tag
            label={t(
                `components.tax_submission_status_chip.status.${
                    plural ? "plural." : ""
                }${status?.toLowerCase() ?? "not_available"}`,
                { defaultValue: "Stato non disponibile" },
            )}
            type={getColorByTaxSubmissionStatus(status, theme).vaporColor}
            variant={variant}
        ></Tag>
    );
};
