import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDTO } from "../../dtos/BaseUseCaseResponseDTO";
import { DelegatedDTO } from "../../dtos/DelegatedDTO";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

interface UseGetDelegatedProps {
    intermediaryTaxId: string;
}

export function useGetDelegated(
    input: UseGetDelegatedProps,
): BaseUseCaseResponseDTO<DelegatedDTO[]> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, error } = useGetWithErrorHandler<DelegatedDTO[]>(
        `rnd:tax:government-agency-hub:bff/api/v1/tenants/${tenant?.id}/intermediaries/${input.intermediaryTaxId}/delegated`,
        {
            ...useGetAxiosRequestConfigParams("use-get-delegated"),
        },
    );

    return {
        error: error,
        fetch: fetch,
        loading: loading,
    };
}
