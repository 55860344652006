import { useRequest } from "@onefront/react-sdk";
import { useCallback, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";

export function useGetRegistryApiServices() {
    const [loading, setLoading] = useState(false);
    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const config = useGetAxiosRequestConfigParams(
        "use-get-registry-api-services",
    );

    const fetchData = useCallback(
        async (ownerAppUrn: string) => {
            setLoading(true);
            try {
                const result = await useRequestRef.current.fetch({
                    ...config,
                    url: `registryApi/services/${ownerAppUrn}`,
                    lazy: true,
                    method: "get",
                    stateless: true,
                });
                if (result.status === 200) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            } finally {
                setLoading(false);
            }
        },
        [config],
    );

    return {
        loading: loading,
        checkUrnValidity: fetchData,
    };
}
