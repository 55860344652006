import { useTranslation } from "@onefront/react-sdk";
import { VaporUploader } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Divider, Stack } from "@vapor/react-material";

import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import { useEffect, useState } from "react";
import { useUserContext } from "../../../../../core/contexts/UserContext";
import { Intermediary } from "../../../../../core/dtos/ParsedTaxSubmissionDTO";
import { useParseTaxSubmission } from "../../../../../core/usecases/use-parse-tax-submission/useParseTaxSubmission";
import { TaxSubmissionUploadForm } from "../tax-submission-upload-form/TaxSubmissionUploadForm";

enum UploadError {
    FILE_TOO_BIG,
    MULTIPLE_FILES,
    NONE,
}

const FILE_SIZE_LIMIT = 1024 * 1024 * 5; // 5mb

interface TaxSubmissionUploadProps {
    onClickCancelButton: () => unknown;
    onUploadTaxSubmission: () => unknown;
}

export const TaxSubmissionUpload: React.FC<TaxSubmissionUploadProps> = ({
    onClickCancelButton,
    onUploadTaxSubmission,
}): JSX.Element => {
    const { t } = useTranslation("government-agency-hub-app");
    const { subscriptions } = useUserContext();

    // states -> files
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [uploadedTaxSubmissionFile, setUploadedTaxSubmissionFile] = useState<{
        file: File;
        content: string;
    }>();
    const [uploadError, setUploadError] = useState<UploadError>(
        UploadError.NONE,
    );

    const [fileType, setFileType] = useState<string | undefined>();

    // states -> upload form
    const [fulfilments, setFulfilments] = useState<
        {
            accrualYear: number;
            levyId: string;
            name: string;
        }[]
    >([]);

    const [intermediaries, setIntermediaries] = useState<Intermediary[]>();

    const [isTaxSubmissionUploading, setIsTaxSubmissionUploading] =
        useState(false);

    const [uploadFlow, setUploadFlow] = useState<
        "ADE" | "SDI" | "TS" | undefined
    >();

    // states -> check
    const [isAgencySubscriptionReadOnly, setIsAgencySubscriptionReadonly] =
        useState(true);

    // use cases
    const { error, loading, parse } = useParseTaxSubmission();

    // use effects
    useEffect(() => {
        async function _setTaxSubmissionFile(file: File) {
            setUploadedTaxSubmissionFile({
                file: file,
                content: await toBase64(file),
            });
        }

        if (uploadedFiles && uploadedFiles.length === 1) {
            _setTaxSubmissionFile(uploadedFiles[0]);
        }

        return () => {
            setUploadedTaxSubmissionFile(undefined);
        };
    }, [uploadedFiles]);

    useEffect(() => {
        const parseFile = async () => {
            if (uploadedTaxSubmissionFile) {
                const data = await parse(
                    uploadedTaxSubmissionFile?.content || "",
                );
                if (data) {
                    setFileType(data.fileType);
                    setFulfilments(data.fulfilments);
                    setIntermediaries(data.intermediaries);
                    setUploadFlow(data.flow);
                }
            }
        };

        parseFile();

        return () => {
            setFileType(undefined);
            setFulfilments([]);
            setIntermediaries(undefined);
            setUploadFlow(undefined);
        };
    }, [parse, uploadedTaxSubmissionFile]);

    useEffect(() => {
        if (error) {
            setUploadedFiles([]);
        }
    }, [error]);

    useEffect(() => {
        if (uploadFlow) {
            setIsAgencySubscriptionReadonly(
                !subscriptions.some(
                    (sub) =>
                        sub.isActive &&
                        !sub.isReadOnly &&
                        sub.serviceId === `TEL-${uploadFlow}`,
                ),
            );
        }
        return () => {
            setIsAgencySubscriptionReadonly(true);
        };
    }, [subscriptions, uploadFlow]);

    // functions
    const toBase64 = (file: File) => {
        setIsTaxSubmissionUploading(true);
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setIsTaxSubmissionUploading(false);
                // remove data-url declaration
                // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL

                resolve((reader.result as string).split(",")[1]);
            };
            reader.onerror = reject;
        });
    };

    const onFileUpload = (filesItems: File[]) => {
        if (filesItems.length > 1) {
            setUploadError(UploadError.MULTIPLE_FILES);
        } else if (filesItems[0].size > FILE_SIZE_LIMIT) {
            setUploadError(UploadError.FILE_TOO_BIG);
        } else {
            setUploadError(UploadError.NONE);
            setUploadedFiles(filesItems);
        }
    };

    const onRemoveFile = () => {
        setUploadedFiles([]);
        setUploadError(UploadError.NONE);
    };

    return (
        <Stack spacing={3}>
            <Typography variant="titleMedium">
                {t("views.home.components.tax_submission_upload.upload_file", {
                    defaultValue: "Carica file",
                })}
            </Typography>
            <LoadingWrapper
                loading={isTaxSubmissionUploading || loading}
                spinnerProps={{ loadingText: "" }}
                variant={loading ? "spinner" : "backdrop"}
            >
                <VaporUploader
                    buttonLabel={t(
                        "views.home.components.tax_submission_upload.uploader.button_label",
                        { defaultValue: "Carica file" },
                    )}
                    buttonVariant="outlined"
                    description={t(
                        "views.home.components.tax_submission_upload.uploader.description",
                        {
                            defaultValue: "Dimensione massima: 5MB",
                        },
                    )}
                    draggable
                    draggableText={t(
                        "views.home.components.tax_submission_upload.uploader.draggable_text",
                        { defaultValue: "Trascina il file qui o" },
                    )}
                    dropText={t(
                        "views.home.components.tax_submission_upload.uploader.drop_text",
                        { defaultValue: "Rilascia il file qui" },
                    )}
                    error={
                        uploadError !== UploadError.NONE ||
                        (isAgencySubscriptionReadOnly && !!uploadFlow)
                    }
                    errorMessage={
                        uploadError !== UploadError.NONE
                            ? t(
                                  `views.home.components.tax_submission_upload.uploader.errors.${
                                      UploadError[uploadError]?.toLowerCase() ??
                                      "generic_error"
                                  }`,
                                  { defaultValue: "Errore" },
                              )
                            : isAgencySubscriptionReadOnly && !!uploadFlow
                              ? t(
                                    "views.home.components.tax_submission_upload_form.errors.is_agency_subscription_readonly",
                                    {
                                        defaultValue:
                                            "L'utenza corrente non ha i permessi per inviare la fornitura all'agenzia di riferimento",
                                    },
                                )
                              : undefined
                    }
                    files={uploadedFiles as any}
                    inputFileProps={{
                        multiple: false,
                    }}
                    onFileUpload={onFileUpload}
                    onRemove={onRemoveFile}
                />
            </LoadingWrapper>
            {!isAgencySubscriptionReadOnly &&
                uploadedTaxSubmissionFile &&
                fileType &&
                intermediaries &&
                uploadFlow && (
                    <>
                        <Divider light />
                        <TaxSubmissionUploadForm
                            fileContent={uploadedTaxSubmissionFile.content}
                            fileName={uploadedTaxSubmissionFile.file.name}
                            fileType={fileType}
                            fulfilments={fulfilments}
                            intermediaries={intermediaries}
                            isTaxSubmissionUploading={(isUploading) =>
                                setIsTaxSubmissionUploading(isUploading)
                            }
                            onClickCancelButton={onClickCancelButton}
                            onUploadTaxSubmission={onUploadTaxSubmission}
                            uploadFlow={uploadFlow}
                        />
                    </>
                )}
        </Stack>
    );
};
