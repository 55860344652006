export const downloadFile = async (
    resource: Uint8Array,
    filename: string,
    applicationType: string,
) => {
    if (!!resource) {
        const blob = new Blob([resource], { type: applicationType });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
};
