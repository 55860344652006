import { faMagnifyingGlassArrowRight } from "@fortawesome/pro-light-svg-icons";
import { IconDefinition, faDownload } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { EllipsisVertical, Eye, VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Button,
    Menu,
    MenuItem,
    Stack,
    useTheme,
} from "@vapor/react-material";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTaxSubmissionContext } from "../../../../../core/contexts/TaxSubmissionContext";
import {
    GovernmentAgencyType,
    TaxSubmissionDetailDTO,
} from "../../../../../core/dtos/TaxSubmissionDetailDTO";
import { useDownloadTaxSubmissionOutcome } from "../../../../../core/usecases/use-download-tax-submission-outcome/useDownloadTaxSubmissionOutcome";
import { useDownloadTaxSubmissionPdfOutcome } from "../../../../../core/usecases/use-download-tax-submission-pdf-outcome/useDownloadTaxSubmissionPdfOutcome";
import { downloadFile } from "../../../../../core/utils/downloadFile";
import {
    getSummaryTranslation,
    groupOutcomeTypes,
} from "../../../../utils/outcomesTypes";
import { createNavigationUrl, processUrn } from "../../../../utils/resolveUrn";

interface TaxSubmissionDetailOutcomeActionsProps {
    taxSubmissionDetail?: TaxSubmissionDetailDTO;
    onClickPreviewOutcome: (
        availableOutcomeTypes: string[],
        governmentAgencyType: GovernmentAgencyType,
        taxSubmissionId: string,
        selectedOutcomeType: string,
    ) => void;
}

export const TaxSubmissionDetailOutcomeActions: React.FC<
    TaxSubmissionDetailOutcomeActionsProps
> = ({ taxSubmissionDetail, onClickPreviewOutcome }) => {
    const { t } = useTranslation("government-agency-hub-app");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();
    const navigate = useNavigate();

    const { isUrnValid } = useTaxSubmissionContext();

    // use cases
    const { download: downloadPdfOutcome } =
        useDownloadTaxSubmissionPdfOutcome();
    const { download: downloadOutcome } = useDownloadTaxSubmissionOutcome();

    const getGoToProcedureButton = useCallback(
        (ownerAppUrn: string, taxSubmissionPath?: string) => {
            const icon: IconDefinition = faMagnifyingGlassArrowRight;
            const label: string = t(
                "views.home.components.tax_submissions_table.actions.go_to_procedure",
                {
                    defaultValue: "Vai alla procedura",
                },
            );
            const onClick = (
                _: React.MouseEvent<HTMLLIElement, MouseEvent>,
            ) => {
                if (taxSubmissionPath) {
                    navigate(createNavigationUrl(taxSubmissionPath));
                } else {
                    navigate(createNavigationUrl(processUrn(ownerAppUrn)));
                }
            };
            return {
                icon: icon,
                label: label,
                onClick: onClick,
            };
        },
        [navigate, t],
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickDownloadPdfOutcome = useCallback(
        async (
            taxSubmissionId: string,
            outcomeTypes: string[],
            outcomeGroupType: string,
        ) => {
            const resourceData = await downloadPdfOutcome(
                taxSubmissionId,
                outcomeTypes,
            );

            if (!resourceData) {
                console.error("could not download outcome pdf document");
                return;
            }

            const filename: string = "Riepilogativo " + outcomeGroupType;
            downloadFile(resourceData.content, filename, resourceData.type);
            handleClose();
        },
        [downloadPdfOutcome],
    );

    const onClickDownloadOutcome = useCallback(
        async (taxSubmissionId: string, outcomeType: string) => {
            const resourceData = await downloadOutcome(
                taxSubmissionId,
                outcomeType,
            );

            if (!resourceData) {
                console.error("could not download outcome document");
                return;
            }

            const filename: string = "Riepilogativo " + outcomeType;
            downloadFile(resourceData.content, filename, resourceData.type);
            handleClose();
        },
        [downloadOutcome],
    );

    // functions
    const getDownloadButtons = useCallback(
        (outcomeTypes?: string[]) => {
            if (
                !!taxSubmissionDetail &&
                !!taxSubmissionDetail.outcomeTypes &&
                !!outcomeTypes
            ) {
                const icon: IconDefinition = faDownload;

                if (
                    taxSubmissionDetail.governmentAgency.type ===
                    GovernmentAgencyType.ade
                ) {
                    const groupedOutcomeTypes = groupOutcomeTypes(outcomeTypes);
                    return groupedOutcomeTypes.map((outcomeGroupType) => {
                        const extendedOutcomeTypes = outcomeTypes.filter(
                            (outcomeType) =>
                                outcomeType.includes(outcomeGroupType),
                        );
                        let onClick = (
                            _: React.MouseEvent<HTMLLIElement, MouseEvent>,
                        ) => {
                            onClickDownloadPdfOutcome(
                                taxSubmissionDetail.id,
                                extendedOutcomeTypes,
                                outcomeGroupType,
                            );
                        };

                        return {
                            icon: icon,
                            label: getSummaryTranslation(outcomeGroupType, t),
                            onClick: onClick,
                        };
                    });
                }

                return outcomeTypes.map((outcomeType) => {
                    let onClick = (
                        _: React.MouseEvent<HTMLLIElement, MouseEvent>,
                    ) => {
                        onClickDownloadOutcome(
                            taxSubmissionDetail.id,
                            outcomeType,
                        );
                    };

                    return {
                        icon: icon,
                        label: getSummaryTranslation(outcomeType, t),
                        onClick: onClick,
                    };
                });
            } else {
                return [];
            }
        },
        [
            onClickDownloadOutcome,
            onClickDownloadPdfOutcome,
            t,
            taxSubmissionDetail,
        ],
    );

    const options = useMemo(() => {
        const options = [];
        if (taxSubmissionDetail) {
            options.push(
                ...getDownloadButtons(taxSubmissionDetail?.outcomeTypes),
            );
            if (isUrnValid(taxSubmissionDetail.ownerApp.urn)) {
                options.push(
                    getGoToProcedureButton(
                        taxSubmissionDetail?.ownerApp.urn,
                        taxSubmissionDetail.ownerApp.taxSubmissionPath,
                    ),
                );
            }
        }
        return options;
    }, [
        getDownloadButtons,
        getGoToProcedureButton,
        isUrnValid,
        taxSubmissionDetail,
    ]);

    return (
        <Stack alignItems="stretch" direction="row" spacing={1}>
            <Button onClick={handleClick} variant="outlined">
                <EllipsisVertical />
                <Typography marginLeft={2}>
                    {t(
                        "views.home.components.tax_submission_detail_outcome_actions.other_actions",
                        {
                            defaultValue: "Altre azioni",
                        },
                    )}
                </Typography>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={option?.onClick}>
                        <VaporIcon icon={option?.icon} color="interactive" />
                        <Box sx={{ mr: 1 }}></Box>
                        <Typography
                            color={theme.palette.primary.interactiveDefault}
                        >
                            {option?.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
            {taxSubmissionDetail &&
                taxSubmissionDetail.outcomeTypes &&
                taxSubmissionDetail.outcomeTypes.length > 0 && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                            onClickPreviewOutcome(
                                taxSubmissionDetail.outcomeTypes,
                                taxSubmissionDetail.governmentAgency.type,
                                taxSubmissionDetail.id,
                                taxSubmissionDetail.outcomeTypes[0],
                            )
                        }
                    >
                        <Eye />
                        <Typography sx={{ ml: 1 }}>
                            {t(
                                "views.home.components.tax_submission_detail_outcome_actions.preview_summary",
                                {
                                    defaultValue: "Visualizza riepilogo",
                                },
                            )}
                        </Typography>
                    </Button>
                )}
        </Stack>
    );
};
