import { useTranslation } from "@onefront/react-sdk";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { InfoSquare } from "@vapor/react-icons";
import {
    Box,
    Card,
    CardContent,
    Stack,
    Tooltip,
    useTheme,
} from "@vapor/react-material";
import { ReactElement, useMemo, useState } from "react";
import { TaxSubmissionStatus } from "../../../../../core/dtos/TaxSubmissionStatus";
import { getColorByTaxSubmissionStatus } from "../../../../../core/utils/getColorByTaxSubmissionStatus";
import { TaxSubmissionStatusAlert } from "../../../../components/tax-submission-status-alert/TaxSubmissionStatusAlert";
import { TaxSubmissionStatusChip } from "../../../../components/tax-submission-status-chip/TaxSubmissionStatusChip";

interface TaxSubmissionsTableHeaderKPICardProps {
    taxSubmissionStatus: TaxSubmissionStatus;
    isSelected: boolean;
    loading: boolean;
    numberOfTaxSubmissions: number;
    onClickKPI: (taxSubmissionStatus: TaxSubmissionStatus) => unknown;
}

export const TaxSubmissionsTableHeaderKPICard = ({
    taxSubmissionStatus,
    isSelected = false,
    loading,
    numberOfTaxSubmissions,
    onClickKPI,
}: TaxSubmissionsTableHeaderKPICardProps): ReactElement => {
    const { t } = useTranslation("government-agency-hub-app");
    const theme = useTheme();

    const [cardOverflow, setCardOverflow] = useState({
        isOverflown: false,
        overflow: 0,
    });

    const borderColor = useMemo(
        () =>
            getColorByTaxSubmissionStatus(taxSubmissionStatus, theme)
                .themeColor,
        [taxSubmissionStatus, theme],
    );

    return (
        <Card
            elevation={isSelected ? 4 : 0}
            onClick={() => !loading && onClickKPI(taxSubmissionStatus)}
            sx={{
                ":hover": {
                    boxShadow: 4,
                },
                border: isSelected
                    ? `1px solid ${theme.palette.primary.interactiveDefault}`
                    : `1px solid ${theme.palette.primary.gainsboro}`,
                borderLeft: `4px solid ${borderColor}`,
                cursor: "pointer",
                width: "100%",
            }}
            style={{
                backgroundColor: isSelected
                    ? theme.palette.primary.whiteSmoke
                    : undefined,
            }}
        >
            <CardContent
                sx={{
                    paddingTop: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingBottom: 1,
                    ":last-child": {
                        paddingBottom: 1,
                    },
                }}
            >
                <LoadingWrapper
                    loading={loading}
                    variant="backdrop"
                    skeletonProps={{ rowsNumber: 1 }}
                >
                    <Stack display="flex" direction="column" useFlexGap gap={1}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Typography
                                color={theme.palette.primary.textTitleColor}
                                variant="titleMedium"
                            >
                                {numberOfTaxSubmissions}
                            </Typography>
                            <Tooltip
                                title={
                                    <Stack spacing={2}>
                                        <Typography
                                            color={
                                                theme.palette.primary
                                                    .surfaceDefault
                                            }
                                            variant="caption"
                                        >
                                            {t(
                                                `views.home.components.tax_submissions_table_header_kpi_card.${taxSubmissionStatus.toLowerCase()}.tooltip.title`,
                                                {
                                                    defaultValue: "Titolo",
                                                    status: taxSubmissionStatus,
                                                },
                                            )}
                                        </Typography>
                                        <Typography
                                            color={
                                                theme.palette.primary
                                                    .surfaceDefault
                                            }
                                            variant="captionSmall"
                                        >
                                            {t(
                                                `views.home.components.tax_submissions_table_header_kpi_card.${taxSubmissionStatus.toLowerCase()}.tooltip.text`,
                                                {
                                                    defaultValue: "Testo",
                                                    status: taxSubmissionStatus,
                                                },
                                            )}
                                        </Typography>
                                    </Stack>
                                }
                                placement="top"
                            >
                                <Box>
                                    <InfoSquare
                                        color={
                                            theme.palette.primary.textTitleColor
                                        }
                                    />
                                </Box>
                            </Tooltip>
                        </Stack>
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="start"
                            onMouseEnter={(element) => {
                                if (element.target instanceof HTMLElement) {
                                    const target =
                                        element.target as HTMLElement;

                                    const overflow =
                                        target.scrollWidth - target.clientWidth;

                                    setCardOverflow({
                                        isOverflown: overflow > 0,
                                        overflow: overflow,
                                    });
                                }
                            }}
                            spacing={1}
                            sx={{
                                "&:hover": {
                                    marginLeft: cardOverflow.isOverflown
                                        ? `${-(cardOverflow.overflow + 8)}px`
                                        : undefined,
                                },
                                clipPath: "margin-box",
                                transition: "margin 1s ease-in-out",
                            }}
                        >
                            <TaxSubmissionStatusChip
                                plural
                                status={taxSubmissionStatus}
                            />
                            <TaxSubmissionStatusAlert
                                status={taxSubmissionStatus}
                            />
                        </Stack>
                    </Stack>
                </LoadingWrapper>
            </CardContent>
        </Card>
    );
};
