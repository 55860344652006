import { TaxSubmissionProviderFactory } from "../../../core/contexts/TaxSubmissionContext";
import { ViewWrapper } from "../ViewWrapper";
import { Home } from "./Home";

export const homeView = () => [
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            path: "",
            element: (
                <ViewWrapper>
                    <TaxSubmissionProviderFactory>
                        <Home />
                    </TaxSubmissionProviderFactory>
                </ViewWrapper>
            ),
        },
    },
];
